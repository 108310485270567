<script setup>
import useCartSummary from '@/components/carts/summary/useCartSummary'

const { tiersAmountInEuros } = useCartSummary()
</script>

<template>
  <div class="cart-summary-tiers">
    <p class="cart-summary-tiers__item">
      <span>{{ $t('campaign.step.summary.payment') }}</span>
      <span class="cart-summary-tiers__item__price">
        {{ $t('campaign.currencyUnit', [tiersAmountInEuros]) }}
      </span>
    </p>
  </div>
</template>

<style lang="scss">
.cart-summary-tiers {
  margin: 0 $ha-spacing-large;
  padding: $ha-spacing-medium 0 $ha-spacing-large;
  border-bottom: $ha-border-width-large dashed set-alpha(border, 0.5);

  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    color: var(--ha-color-text-light);
    font-size: $ha-font-size-large;

    &__price {
      font-weight: $ha-font-weight-semibold;
    }
  }
}
</style>
