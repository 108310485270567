<script setup>
import { computed } from 'vue'
import { useContext, useStore } from '@nuxtjs/composition-api'
import useFormType from '@/composables/useFormType'
import useStoreData from '@/composables/useStoreData'
import PriceListItem from '@/components/carts/summary/partials/PriceListItem.vue'
import useModalities from '@/composables/useModalities'

const store = useStore()
const { organization, storeRouteParams } = useStoreData()
const { isCrowdfunding, isSale } = useFormType()
const { i18n } = useContext()

defineProps({
  title: {
    type: String,
    default: null
  },
  // tickets or added donations
  cartElements: {
    type: Array,
    default: () => []
  }
})

const { isPaymentTermsEnabled } = useModalities()

const cart = computed(() => store.getters['carts/getCart'](storeRouteParams.value))
const tierItems = computed(() => cart.value?.tierList || [])
const hasFixedPrice = cartItem => {
  return !cartItem.customAmount
}

const getPaymentFrequency = cartItem => {
  if (cartItem?.paymentTerms?.length === 1 || isPaymentTermsEnabled.value) return ''

  const tier = tierItems.value.find(ti => ti.id === cartItem?.tierId)
  const frequency = i18n.t(
    `campaign.step.summary.frequency.${tier.paymentFrequency.toLowerCase()}`,
    [cartItem.paymentTerms.length]
  )
  return ` · ${frequency}`
}

const getTierLabel = cartItem => {
  const tier = tierItems.value.find(ti => ti.id === cartItem?.tierId)

  if (tier?.label) return tier.label

  return i18n.t(
    `campaign.step.summary.${
      isCrowdfunding.value ? 'donationCrowd' : cartItem?.tierType.toLowerCase()
    }`,
    [organization.value.name]
  )
}

const getSaleTierName = cartItem => {
  return hasFixedPrice(cartItem)
    ? i18n.t('campaign.step.summary.priceFixed')
    : i18n.t('campaign.step.summary.priceFree') + getPaymentFrequency(cartItem)
}

const getTierName = cartItem => {
  if (isSale.value) return getSaleTierName(cartItem)
  const label = getTierLabel(cartItem)
  const frequency = getPaymentFrequency(cartItem)

  return `${label} ${frequency}`
}
</script>

<template>
  <div>
    <h3 v-if="title" class="CardSummary--Title">
      {{ title }}
    </h3>
    <PriceListItem
      v-for="(cartItem, i) in cartElements"
      :key="`cart-summary-${i}`"
      :cart-item="cartItem"
      :tier-name="getTierName(cartItem)"
      :index="i"
    />
  </div>
</template>
