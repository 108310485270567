import { render, staticRenderFns } from "./CartsStep.vue?vue&type=template&id=8f4e6e02"
import script from "./CartsStep.vue?vue&type=script&setup=true&lang=ts"
export * from "./CartsStep.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CartsStep.vue?vue&type=style&index=0&id=8f4e6e02&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports