<script setup>
import { computed } from 'vue'
import useCartSummary from '@/components/carts/summary/useCartSummary'

const { tiersVatAmount, tiersVatAmountInEuros, totalAmountInEuros } = useCartSummary()

const hasTva = computed(() => !!tiersVatAmount.value)
</script>

<template>
  <div class="total-amount">
    <p class="total-amount__total">
      <span>{{ $t('campaign.amountTtc') }}</span>
      <span class="total-amount__total__price">{{
        $t('campaign.currencyUnit', [totalAmountInEuros])
      }}</span>
    </p>
    <p v-if="hasTva" class="total-amount__tva">
      <span>{{ $t('campaign.amountTva') }}</span>
      <span class="total-amount__tva__price">{{
        $t('campaign.currencyUnit', [tiersVatAmountInEuros])
      }}</span>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.total-amount {
  padding: $ha-spacing-small $ha-spacing-large;
  background-color: var(--ha-color-primary);

  &__total,
  &__tva {
    margin: 0;
    color: var(--ha-color-white);
    text-align: right;

    &__price {
      margin-left: $ha-spacing-tiny;

      @include mediaQuery(600) {
        margin-left: $ha-spacing-small;
      }
    }
  }

  &__total {
    font-weight: $ha-font-weight-bold;
    font-size: $ha-font-size-large;

    @include mediaQuery(600) {
      font-size: $ha-font-size-jumbo;
    }
  }
}
</style>
