import { computed } from 'vue'
import useStoreData from '@/composables/useStoreData'
import { CollectionOf } from '../../types/common.interface'
import { useStore } from '@nuxtjs/composition-api'
import { getRemainingStock } from './extraOptions.helpers'
import { CartsGetters } from '../carts/carts.interface'

export default () => {

  const { tiers, storeRouteParams } = useStoreData()

  const store = useStore()

  const selectedExtraOptionByCartItem = computed<CollectionOf<number[]>>(() => {
    return store.getters[`carts/${CartsGetters.SELECTED_EXTRA_OPTIONS_BY_CART_ITEM}`](storeRouteParams.value)
      || {}
  })

  const selectedExtraOptionIds = computed<number[]>(() => store.getters[`carts/${CartsGetters.SELECTED_EXTRA_OPTIONS}`](storeRouteParams.value) || {})

  const getRemainingNumber = (tierId: number, extraOptionId: number) => {
    /* Need to get remaining number from tier's data to be up-to-date */
    const tier = tiers.value.find(item => item.id === tierId)
    const extraOption = tier?.extraOptions?.find(item => item.id === extraOptionId)
    
    if (extraOption) {
      return getRemainingStock(extraOption, selectedExtraOptionIds.value)
    }
  }

  const isSelected = (cartItemId: number, extraOptionId: number) => {
    return selectedExtraOptionByCartItem.value?.[cartItemId]?.includes(extraOptionId)
  }

  return {
    selectedExtraOptionByCartItem,
    getRemainingNumber,
    isSelected
  }
}
