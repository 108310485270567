<template>
  <ValidationObserver :ref="'donationForm'" class="DonationContainer" tag="div">
    <DonationHeader />
    <FormBlockedCollectBanner v-if="!canCollect" :is-widget="isWidget" />
    <div v-else class="DonationPage">
      <section class="DonationPage__Block">
        <SelectDonation
          :fiscal-eligibility="fiscalEligibility"
          @update-selected-tier="selectedTier = $event"
          @update-payment-day="paymentDay = $event"
        />
        <FiscalReduction v-if="fiscalEligibility" :selected-tier="selectedTier" />
      </section>
      <section class="DonationPage__Block DonationPage__Block--MiddleBlock">
        <PersonalInformations :selected-tier="selectedTier" />
      </section>
      <section class="DonationPage__Block">
        <DonationSummary :selected-tier="selectedTier" @set-tip="tipData = $event" />
        <HaButton
          class="DonationPage--Submit"
          fluid
          :disabled="!(!isLoading & hasPriceSelected)"
          :loading="isLoading"
          data-testid="button-validate-payment"
          @click="submitDonation"
        >
          {{ $t('campaign.validateAndPay') }}
        </HaButton>
        <CardSecurity />
      </section>
    </div>
    <FormPresentation />
    <ActionReport />
  </ValidationObserver>
</template>

<script>
/*
In order to update the vente's metadata we have to use defineComponent and empty head
Source : https://composition-api.nuxtjs.org/packages/usemeta/
@TODO : When the migration to nuxt 3 will be done it will be necessary to remove the defineComponent
and use the useHead composable : https://nuxt.com/docs/getting-started/seo-meta#usehead
*/
import { defineComponent } from '@nuxtjs/composition-api'
import { HaButton } from '@ha/components'
import { haMoment } from '@ha/helpers'
import { ValidationObserver } from 'vee-validate'
import DonationHeader from '@/components/forms/donations/partials/DonationHeader.vue'
import FormPresentation from '@/components/forms/informations/FormPresentation.vue'
import CardSecurity from '@/components/globals/informations/CardSecurity.vue'
import SelectDonation from '@/components/forms/donations/partials/SelectDonation.vue'
import FiscalReduction from '@/components/forms/donations/partials/FiscalReduction.vue'
import PersonalInformations from '@/components/forms/donations/partials/PersonalInformations.vue'
import DonationSummary from '@/components/forms/donations/partials/DonationSummary.vue'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import ActionReport from '@/components/reports/ActionReport.vue'
import useStoreData from '@/composables/useStoreData'
import useMetaData from '@/composables/useMetaData'
import { routeCodes } from '@/helpers/errorLogs'
import useNotifications from '@/composables/useNotifications'
import FormBlockedCollectBanner from '@/components/forms/informations/FormBlockedCollectBanner.vue'
import useTracking from '@/composables/useTracking'
import { scrollCenterToErrorElement } from '@/helpers/formUtils'

export default defineComponent({
  name: 'DonationStep',
  components: {
    FormBlockedCollectBanner,
    ActionReport,
    HaButton,
    DonationHeader,
    CardSecurity,
    SelectDonation,
    PersonalInformations,
    ValidationObserver,
    DonationSummary,
    FiscalReduction,
    FormPresentation
  },
  inject: ['$notifications', 'isWidget'],
  setup() {
    const { personalInformations, customFields, updateCustomFields } =
      usePayerPersonalInformations()
    const { form, organization, canCollect } = useStoreData()
    const { setMetaData } = useMetaData()
    const { onError } = useNotifications()
    const { trackingMethods } = useTracking()

    setMetaData()

    return {
      canCollect,
      personalInformations,
      customFields,
      form,
      organization,
      updateCustomFields,
      onError,
      trackingMethods,
      scrollCenterToErrorElement
    }
  },
  data() {
    return {
      isLoading: false,
      tipData: null,
      selectedTier: null,
      paymentDay: null,
      cartId: null,
      redirectUrl: null
    }
  },
  head: {},
  computed: {
    fiscalEligibility() {
      return (
        (this.organization?.fiscalReceiptEligibility && this.selectedTier?.isEligibleTaxReceipt) ||
        false
      )
    },
    hasPriceSelected() {
      return !!this.selectedTier?.price
    }
  },
  async mounted() {
    this.$gtm.push(this.$store.getters['carts/getGtmData'](1, this.metadata))
    if (this.$config.NUXT_ENV_ONE_PAGE_RECAPTCHA_ENABLED) {
      await this.$recaptcha.init()
    }
  },
  methods: {
    async submitDonation() {
      try {
        this.isLoading = true
        const validateAll = await this.$refs.donationForm.validate()

        let token = true
        if (this.$config.NUXT_ENV_ONE_PAGE_RECAPTCHA_ENABLED) {
          token = await this.$recaptcha.execute('submit_donation')
        }

        if (!validateAll && this.$device.isMobileOrTablet) {
          this.scrollCenterToErrorElement(this.$refs.donationForm)
        }

        if (validateAll && token) {
          await this.createCart()
          /* Upload file after cart creation because it needs cartId */
          await this.uploadFiles()
          /* Update cart to adding uploaded files and custom fields */
          await this.updateCartWithCustomFields()
          this.redirectToWaitingPage()

          this.trackingMethods.onFormSubmission(this.tipData)
        }
      } catch (error) {
        // debug
        console.error(error)

        const status =
          error?.statusCode?.toString() || error?.response?.status.toString() || 'uncaught'

        const toast = {
          title: this.$t(`error.code.${status}.title`),
          body:
            error.message ||
            `${this.$t(`error.code.${status}.message`)} - ${status} ${routeCodes[error.routeCode]}`
        }

        this.onError(error, toast)
      } finally {
        this.isLoading = false
      }
    },
    redirectToWaitingPage() {
      return this.$router.push({
        name: 'PaymentRedirectForm',
        params: {
          url: this.redirectUrl,
          organizationSlug: this.metadata.organization,
          campaignSlug: this.metadata.slug,
          formType: this.metadata.url_type
        }
      })
    },
    createCart() {
      const items = this.mapDataToItemsPayload()
      return this.$store.dispatch('carts/postCart', [items, this.metadata]).then(resp => {
        this.cartId = resp?.id
      })
    },
    async uploadFiles() {
      const filesToUpload = this.selectedTier.customFields?.filter(
        item => item.type === 'File' && this.customFields[item.id]
      )
      if (!filesToUpload?.length) return
      /* Upload each file from customFields */
      const promises = filesToUpload.map(file => {
        return this.$store
          .dispatch('carts/uploadFile', [this.cartId, this.customFields[file.id]])
          .then(respFileName => {
            this.updateCustomFields({ [file.id]: respFileName })
          })
          .catch(err => {
            throw err
          })
      })
      await Promise.all(promises)
    },
    updateCartWithCustomFields() {
      const payload = this.mapDataToPayloadCartRequest(true)
      return this.$store
        .dispatch('carts/putCartOnePage', {
          payload,
          cartId: this.cartId
        })
        .then(resp => {
          this.redirectUrl = resp?.redirectUrl
        })
        .catch(error => {
          throw error
        })
    },
    mapDataToPayloadCartRequest(withCustomFields = false) {
      const payer = this.mapDataToPayerPayload()
      const items = this.mapDataToItemsPayload(withCustomFields)
      const payload = {
        payer,
        tipData: this.tipData,
        formSlug: this.metadata.slug,
        organizationSlug: this.metadata.organization,
        formType: this.metadata.type,
        items
      }
      return payload
    },
    mapDataToItemsPayload(withCustomFields = false) {
      const customFields =
        this.selectedTier.customFields?.map(item => ({
          ...item,
          value: this.customFields[item.id]
        })) || []
      const items = [
        {
          extraOptions: [],
          customFields: withCustomFields ? customFields : [],
          tierId: this.selectedTier?.id,
          tierType: this.selectedTier?.tierType,
          customAmount: this.selectedTier?.price || 0,
          paymentFrequency: this.selectedTier?.paymentFrequency,
          price: this.selectedTier?.price,
          isEligibleTaxReceipt: this.selectedTier?.isEligibleTaxReceipt,
          vatRate: this.selectedTier?.vatRate
        }
      ]
      /* Add selected paymentDay to payload data */
      if (this.selectedTier?.tierType === 'MonthlyDonation') {
        items[0].dayOfLevy = !Number.isNaN(parseInt(this.paymentDay, 10))
          ? parseInt(this.paymentDay, 10)
          : Math.min(haMoment().day(), 28)
      }
      return items
    },
    mapDataToPayerPayload() {
      const payer = {
        firstname: this.personalInformations?.firstName,
        lastname: this.personalInformations?.lastName,
        email: this.personalInformations?.email,
        consent: true,
        dateOfBirth: haMoment(this.personalInformations?.birthDate, 'DD/MM/YYYY').format(),
        place: {
          country: this.personalInformations?.country,
          city: this.personalInformations?.city,
          zipCode: this.personalInformations?.zipCode,
          address: this.personalInformations?.address
        }
      }
      if (this.personalInformations?.isCompany) {
        payer.companyName = this.personalInformations?.companyName
        payer.companyLegalStatus = this.personalInformations?.companyLegalStatus
        payer.companySiren = this.personalInformations?.companySiren
      }
      return payer
    }
  }
})
</script>

<style lang="scss">
.DonationContainer {
  position: relative;
  font-size: $ha-font-size-small;

  .DonationPage {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mediaQuery(900) {
      flex-direction: row;
    }

    &__Block {
      flex: 1;

      &--MiddleBlock {
        margin-right: 0;
        margin-left: 0;

        @include mediaQuery(900) {
          margin-right: $ha-spacing-large;
          margin-left: $ha-spacing-large;
        }
      }
    }

    .Block {
      min-height: $ha-unit * 40;
      margin-top: $ha-spacing-large;
      overflow: hidden;
      background-color: var(--ha-color-white);
      background-clip: padding-box;
      border-radius: $ha-radius-large;
      box-shadow: $ha-box-shadow-close;

      &--Title {
        padding: $ha-spacing-large;
        color: var(--ha-color-text-disabled);
        font-weight: $ha-weight-semibold;
        font-size: $ha-font-size-large;
        text-align: center;
        background: var(--ha-color-background-disabled);

        &-Active {
          color: var(--ha-color-white);
          background: var(--ha-color-primary);
        }
      }

      &--Content {
        padding: $ha-spacing-large;
      }
    }

    &--Submit {
      margin-top: $ha-spacing-large;
    }

    .HaTile {
      min-height: $ha-unit * 40;
      margin-top: $ha-spacing-large;
    }
  }
}
</style>
