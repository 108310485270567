<template>
  <Card v-if="allTags && allTags.length" class="CardSeoTags">
    <span slot="title">{{ $t('card.seo') }}</span>
    <div slot="content" class="CardSeoTags--Content">
      <SeoTag v-for="(tag, index) in allTags" :key="index" :tag="tag" class="CardSeoTags--Tag" />
    </div>
  </Card>
</template>

<script>
import Card from '@/components/globals/cards/Card.vue'
import SeoTag from '@/components/forms/informations/partials/SeoTag.vue'
import { getAllTags } from '@/helpers/seoTags'

export default {
  name: 'CardSeoTags',
  components: { Card, SeoTag },
  computed: {
    seoTags() {
      return this.$store.getters['forms/getSeoTags']
    },
    internalTags() {
      return this.seoTags?.internalTags
    },
    city() {
      return this.seoTags?.city
    },
    departement() {
      return this.seoTags?.departement
    },
    region() {
      return this.seoTags?.region
    },
    activityType() {
      return this.seoTags?.activityType
    },
    publicTags() {
      return this.seoTags?.publicTags
    },
    allTags() {
      const seoTags = {
        categories: this.publicTags,
        activities: this.activityType,
        evenements: this.internalTags,
        region: this.region,
        departement: this.departement,
        city: this.city
      }
      const { allTags } = getAllTags(seoTags)
      return allTags
    },
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    }
  }
}
</script>

<style lang="scss">
.CardSeoTags {
  &--Content {
    display: inline-flex;
    flex-wrap: wrap;
  }

  &--Tag {
    margin: 0 $ha-spacing-small $ha-spacing-tiny 0;
    padding: $ha-spacing-tiny $ha-spacing-small;
    text-decoration: none;
    border: solid $ha-border-width-regular var(--ha-color-border);
    border-radius: $ha-radius-round;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      background-color: hsla(
        var(--ha-color-text-h),
        var(--ha-color-text-s),
        var(--ha-color-text-l),
        0.05
      );
    }
  }
}
</style>
