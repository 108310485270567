<script setup>
import { computed } from 'vue'
import TiersItemDescription from '@/components/tiers/partials/description/TiersItemDescription.vue'
import TiersItemSelector from '@/components/tiers/partials/selector/TiersItemSelector.vue'
import TiersItemPrice from '@/components/tiers/partials/price/TiersItemPrice.vue'
import {
  isTierCustomAmount,
  isTierFree,
  isTierInstallment,
  isTierMonthly
} from '@/components/tiers/helpers/getTiersData.ts'

const props = defineProps({
  tier: {
    type: Object,
    required: true
  },
  isFetching: Boolean
})

/* @TODO: Emit update-selected-tiers will be remove with refacto of tiers step */
const emit = defineEmits(['update-selected-tiers'])

const dataTest = computed(() => {
  if (isTierCustomAmount(props.tier)) return 'row-pwyw'
  if (isTierInstallment(props.tier)) return 'row-installments-by-three'
  if (isTierMonthly(props.tier)) return 'row-monthly'
  if (isTierFree(props.tier)) return 'row-free'

  return 'row-unique'
})
</script>

<template>
  <div class="CampaignTier" :data-test="dataTest">
    <div class="CampaignTierWrapper">
      <figure v-if="props.tier.picture" class="CampaignTierImage">
        <img :src="props.tier.picture.publicUrl" alt="" loading="lazy" />
      </figure>
      <TiersItemDescription :tier="props.tier" />
      <TiersItemPrice :tier="props.tier" @update-selected-tiers="emit('update-selected-tiers')" />
      <TiersItemSelector
        :tier="props.tier"
        :is-fetching="props.isFetching"
        @update-selected-tiers="emit('update-selected-tiers')"
      />
    </div>
  </div>
</template>

<style lang="scss">
.CampaignTier {
  text-align: center;

  @include mediaQuery(900) {
    text-align: left;
  }

  + .CampaignTier {
    margin-top: $ha-spacing-large;
    padding-top: $ha-spacing-large;
    border-top: $ha-border-width-regular solid set-alpha(border, 0.5);
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    @include mediaQuery(900) {
      flex-direction: row;
      text-align: left;
    }
  }

  .CampaignTierImage {
    width: $ha-unit * 12; // 96px
    height: $ha-unit * 12; // 96px
    margin-bottom: $ha-spacing-medium;
    overflow: hidden;
    border-radius: $ha-radius-large;

    @include mediaQuery(900) {
      width: $ha-unit * 16; // 128px
      min-width: $ha-unit * 16; // 128px
      max-width: $ha-unit * 16; // 128px
      height: $ha-unit * 16; // 128px
      margin-right: $ha-spacing-large;
      margin-bottom: 0;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .CampaignTierPrice {
    align-items: center;
    justify-content: center;
    margin-top: $ha-spacing-small;
    margin-bottom: $ha-spacing-medium;
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-jumbo;
    line-height: $ha-line-height-small;
    text-align: center;

    @include mediaQuery(900) {
      flex: 0 0 ($ha-unit * 20); // 160px
      max-width: $ha-unit * 20; // 160px
      margin: 0;
      margin-right: $ha-spacing-medium;
    }

    &::first-letter {
      text-transform: capitalize;
    }

    input {
      font-weight: $ha-font-weight-semibold;
      font-size: $ha-font-size-jumbo;
      text-align: center;
    }

    &--ScheduledType,
    &--ScheduledTotal {
      display: block;
      margin-top: $ha-spacing-mini;
      font-weight: $ha-font-weight-semibold;
      text-align: center;
    }

    &--ScheduledType {
      color: var(--ha-color-text-light);
      font-size: $ha-font-size-regular;
    }

    &--ScheduledTotal {
      color: var(--ha-color-text-lightest);
      font-size: $ha-font-size-small;
    }

    &--Error {
      display: block;
      margin-top: $ha-spacing-tiny;
      color: var(--ha-color-danger);
      font-weight: $ha-font-weight-semibold;
      font-size: $ha-font-size-tiny;
      text-align: center;
    }
  }
}
</style>
