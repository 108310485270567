import { Form } from '@/components/forms/forms.interface'
import { Tier } from '../tiers.interface'
import { getRequiredExtraOptionsWithMax } from './index'

export const getMaxByCart = () => 99

export const getMaxByForm = (form: Form): number | undefined => {
  if (!form) throw new Error('Missing required params form')
  return form.remainingEntries
}

export const getMaxByTier = (tier: Tier): number | undefined => {
  if (!tier) throw new Error('Missing required params form')
  return tier?.remainingNumber
}

export const getMaxByUser = (tier: Tier): number | undefined => {
  if (!tier) throw new Error('Missing required params form')
  return tier?.maxPerUser
}

export const getMaxByExtraOption = (tier: Tier): number | undefined => {
  if (!tier) throw new Error('Missing required params form')
  const requiredExtraOptions = getRequiredExtraOptionsWithMax(tier)
  if (!requiredExtraOptions.length) return undefined
  return Math.min(...requiredExtraOptions.map(item => item.remainingNumber as number))
}
