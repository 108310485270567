<template>
  <div class="Card CardOrganization Card-Split">
    <FormContactAddress v-if="place && (place.name || place.address || place.city)" />
    <FormContactDetails v-if="contact && (contact.email || contact.phoneNumber)" />
  </div>
</template>

<script>
import { get } from 'lodash-es'

import FormContactAddress from '@/components/forms/informations/partials/FormContactAddress.vue'
import FormContactDetails from '@/components/forms/informations/partials/FormContactDetails.vue'

export default {
  name: 'CardOrganization',
  components: { FormContactAddress, FormContactDetails },

  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    place() {
      return get(this.form, 'place')
    },
    contact() {
      return get(this.form, 'contact')
    }
  }
}
</script>

<style lang="scss">
.CardOrganization--Title {
  display: block;
  margin-bottom: $ha-spacing-tiny;
  font-weight: $ha-font-weight-semibold;
}

.CardOrganization--Subtitle {
  display: block;
  margin: 0;
  color: var(--ha-color-text-lightest);
  font-size: $ha-font-size-small;
}
</style>
