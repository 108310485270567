<script setup>
import useSale from '@/components/forms/sales/useSale'
import SaleCard from '@/components/forms/sales/partials/SaleCard.vue'
import PayerPersonalInformations from '@/components/payers/PayerPersonalInformations.vue'

const { selectedTier } = useSale()
</script>

<template>
  <SaleCard :title="$t('campaign.myPersonalInformations')" icon="list">
    <PayerPersonalInformations
      :selected-tier="selectedTier"
      class="vente-personal-informations__content"
      isAddressNeeded
    />
  </SaleCard>
</template>
