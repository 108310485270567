<template>
  <div class="FiscalReduction Card">
    <h3>{{ $t('fiscality.title') }}</h3>
    <p>{{ $t('fiscality.intro', [organization.name]) }}</p>
    <div class="FiscalReduction--Tiles">
      <section v-if="!allowOnlyOrganism" class="FiscalReduction--Tile">
        <p>
          <strong>{{ $t('fiscality.userSubtitle') }}</strong>
        </p>
        <p>{{ taxSystemPersonal }}</p>
      </section>
      <section v-if="!allowOnlyIndividual" class="FiscalReduction--Tile">
        <p>
          <strong>{{ $t('fiscality.orgSubtitle') }}</strong>
        </p>
        <p>{{ taxSystemOrganization }}</p>
      </section>
    </div>
  </div>
</template>

<script>
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'

export default {
  name: 'FiscalReduction',
  setup() {
    const { allowOnlyOrganism, allowOnlyIndividual } = usePayerPersonalInformations()
    return { allowOnlyOrganism, allowOnlyIndividual }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.metadata.organization)
    },
    taxSystemPersonal() {
      return this.form?.taxSystem?.personal?.description
    },
    taxSystemOrganization() {
      return this.form?.taxSystem?.organization?.description
    }
  }
}
</script>

<style lang="scss">
.FiscalReduction {
  font-size: $ha-font-size-small;

  &--Tiles {
    display: flex;
    flex-direction: column;

    @include mediaQuery(900) {
      flex-direction: row;
    }
  }

  &--Tile {
    padding: $ha-spacing-large;
    background-color: var(--ha-color-background);
    border-radius: $ha-radius-large;

    + .FiscalReduction--Tile {
      margin-top: $ha-spacing-medium;

      @include mediaQuery(900) {
        margin-top: 0;
        margin-left: $ha-spacing-large;
      }
    }

    p {
      text-align: center;

      &:first-of-type {
        margin-bottom: $ha-spacing-mini;
        color: var(--ha-color-text);
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
</style>
