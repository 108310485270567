<template>
  <Card class="CardSecurity" :color="color">
    <span slot="title">{{ $t('card.security.title') }}</span>
    <template #content>
      <div :class="cardContentClasses">
        <p>{{ $t('card.security.text') }}</p>
        <div class="CardSecurity--Content--PaymentMethods">
          <img src="~assets/images/logos/logo-visa.svg" alt="VISA" loading="lazy" />
          <img src="~assets/images/logos/logo-mastercard.svg" alt="MasterCard" loading="lazy" />
          <img src="~assets/images/logos/logo-cb.svg" alt="Carte Bleue" loading="lazy" />
          <img src="~assets/images/logos/logo-pci.png" alt="PCI-DSS" loading="lazy" />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/globals/cards/Card.vue'
import useModalities from '@/composables/useModalities'

export default {
  name: 'CardSecurity',
  components: { Card },
  props: {
    color: {
      type: String,
      default: ''
    },
    horizontal: {
      type: Boolean
    }
  },
  computed: {
    cardContentClasses() {
      return ['CardSecurity--Content', { 'CardSecurity--Content-Flex': this.horizontal }]
    }
  }
}
</script>

<style lang="scss">
.CardSecurity {
  &--Content {
    &--PaymentMethods {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: $ha-spacing-medium;

      > img {
        width: $ha-unit * 5; // 40px
        max-width: $ha-unit * 5; // 40px
        height: auto;
        margin: $ha-spacing-small;
      }
    }

    &-Flex {
      @include mediaQuery(600) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p {
          margin-bottom: 0;
        }

        .CardSecurity--Content--PaymentMethods {
          min-width: ($ha-unit * 5 + $ha-spacing-small * 2) * 3; // 3 images
          margin-top: 0;
          margin-left: $ha-spacing-medium;
        }
      }
    }
  }
}
</style>
