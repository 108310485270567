<template>
  <div
    :loading="isLoading"
    :class="['price', { 'price-modalities-enabled': isModalitiesEnabled }]"
    data-testid="total-price"
  >
    <ha-overlay :loading="isLoading">
      <div class="priceWrapper">
        <section v-if="hasTerms" class="priceTotal">
          <span class="price--Label">
            <slot> {{ totalLabel }} </slot>
          </span>
          <div class="price--Amount" data-test="total-amount">{{ totalAmount }} €</div>
        </section>
        <!-- Total -->
        <section :class="['priceTotal', { 'priceTotal-modalities-enabled': isModalitiesEnabled }]">
          <span class="price--Label">
            <span>
              <slot> {{ label }} </slot>
            </span>
            <span v-if="isPaymentTermsEnabled && !isFirtStep && secondInstallmentRealAmountWithTip" class="ToPayToday">{{ $t(`total.type.${form.formType.toLowerCase()}.today`) }}</span>
            <span v-if="isPaymentTermsEnabled && !isFirtStep && !secondInstallmentRealAmountWithTip" class="price-modalities price-modalities--subtitle">{{
              $t(`total.type.${form.formType.toLowerCase()}.subtitle`)
            }}</span>
          </span>
          <div class="price--Amount" :data-test="hasTerms ? 'total-today' : 'total-amount'">
            {{ priceAmount }} €
          </div>
          <!-- <span v-if="isModalitiesEnabled && !isFirtStep" class="price-modalities">{{ $t(`total.noCV`) }}</span> -->
        </section>
        <!-- VAT -->
        <section v-if="vat > 0" class="priceVat">
          <span class="price--Label">
            {{ $t('total.amountWithVAT') }}
          </span>
          <span class="Price--Amount" data-testid="total-vat">{{ vatAmount }} €</span>
        </section>
        <template v-if="isPaymentTermsEnabled && !isFirtStep">
          <span v-if="secondInstallmentRealAmountWithTip">
            {{ $t(`total.type.${form.formType.toLowerCase()}.installmentText1`, [secondInstallmentRealAmountWithTip]) }}
          </span>
          <!-- <hr />
          <div>
            <span>{{ $t(`total.type.${form.formType.toLowerCase()}.installmentText2`, [priceWithoutTip, orgaName]) }}
            </span>
            <span v-if="hasTip">{{ $t(`total.type.${form.formType.toLowerCase()}.installmentText3`) }} </span>
            <span v-if="hasTip"> {{ contribution }} {{ $t(`total.type.${form.formType.toLowerCase()}.installmentText4`) }}</span>
          </div> -->
        </template>
      </div>
    </ha-overlay>
  </div>
</template>

<script>
import { HaOverlay } from '@ha/components'
import { functions } from '@ha/helpers'
import useStoreData from '@/composables/useStoreData'
import useCart from '@/composables/useCart'
import useModalities from '~/composables/useModalities'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import { useContext } from '@nuxtjs/composition-api';

export default {
  name: 'Price',
  components: { HaOverlay },
  props: {
    label: {
      type: String,
      required: true
    },
    price: {
      type: Number,
      default: 0
    },
    vat: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean
    },
    totalLabel: String,
    totalAmount: String,
    hasTerms: Boolean,
    isModalitiesEnabled: {
      type: Boolean,
      default: false
    },
    donation: {
      type: [Object, Number]
    },
  },
  setup() {
    const { form } = useStoreData()
    const { cartStore } = useCart()
    const { organization } = useStoreData()
    const { isPaymentTermsEnabled } = useModalities()
    const { tipSelected, tipSelectedInEuros } = useVoluntaryContribution()
    const { i18n } = useContext()

    return {
      form,
      cartStore,
      isPaymentTermsEnabled,
      organization,
      tipSelected,
      tipSelectedInEuros,
      i18n
    }
  },
  computed: {
    priceAmount() {
      return functions.convertToEuros(this.price)
    },
    vatAmount() {
      return functions.convertToEuros(this.vat)
    },
    secondInstallmentRealAmountWithTip() {
      if (this.cartStore?.paymentTerms?.[1]?.realAmountWithTip) {
        return functions.convertToEuros(this.cartStore.paymentTerms[1].realAmountWithTip)
      } else {
        return 0
      }
    },
    orgaName() {
      return this.organization.name
    },
    hasTip () {
      return Boolean(this.tipSelected)
    },
    contribution () {
      return this.tipSelectedInEuros
    },
    step() {
      return parseInt(this.$route.params.steps, 10) || 1
    },
    isFirtStep() {
      return this.step === 1
    },
    priceWithoutTip() {
      return functions.convertToEuros(this.cartStore.paymentTerms[0].realAmount)
    }
  }
}
</script>

<style lang="scss" scoped>
.price {
  &-modalities-enabled {
    overflow: hidden;
    border-bottom-right-radius: $ha-radius-large;
    border-bottom-left-radius: $ha-radius-large;
  }

  &Wrapper {
    width: 100%;
    padding: $ha-spacing-medium $ha-spacing-large;
    color: var(--ha-color-white);
    text-align: center;
    background-color: var(--ha-color-primary);

    @include mediaQuery(600) {
      text-align: right;
    }
  }

  &Total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: $ha-font-size-big;

    @include mediaQuery(600, 'max') {
      flex-direction: column;
    }

    .price--Label {
      display: flex;
      flex-direction: column;
      font-size: $ha-font-size-big * 1.2;

      span {
        width: fit-content;
      }

      .ToPayToday, .price-modalities--subtitle {
        margin: auto;
        font-weight: $ha-font-weight-regular;
        font-size: 0.6em;

        @include mediaQuery(600) {
          margin: 0;
        }
      }
    }

    .price--Amount {
      font-weight: $ha-font-weight-bold;
      font-size: $ha-font-size-jumbo;
      white-space: nowrap;
    }

    &-modalities-enabled {
      @include mediaQuery(600) {
        display: grid;
        grid-template-rows: 2;
        grid-template-columns: repeat(2, 1fr);

        .price--Label,
        .price-modalities--subtitle {
          text-align: start;
        }

        .price-modalities--subtitle {
          grid-row: 2;
          grid-column: 1 / 2;
        }
      }
    }
  }

  &-modalities {
    font-size: $ha-font-size-small;
  }

  &Vat {
    font-size: $ha-font-size-regular;
    opacity: 0.75;
  }

  &--Label {
    @include mediaQuery(600) {
      margin-right: $ha-spacing-medium;
    }
  }

  &--Amount {
    min-width: $ha-unit * 10; // 80px
  }

  .HaLoader {
    display: none;
  }

  hr {
    margin: 1.3em 0;
    border-top: 1px solid var(--ha-color-white);

    + div {
      display: block;
      font-size: 0.8em;
      text-align: center;
    }
  }
}

/**
  * PaymentForm specific
  */

.PaymentForm {
  .priceWrapper {
    border-radius: 0 0 $ha-radius-large $ha-radius-large;
  }
}
</style>
