<template>
  <client-only>
    <ul class="ShareLinks">
      <li class="ShareLinks--Link">
        <SocialMediaButton social-media="FACEBOOK" v-bind="buttonProps" />
      </li>
      <li class="ShareLinks--Link">
        <SocialMediaButton social-media="TWITTER" v-bind="buttonProps" />
      </li>
      <li class="ShareLinks--Link">
        <SocialMediaButton social-media="LINKEDIN" v-bind="buttonProps" />
      </li>
      <li v-if="withEmail" class="ShareLinks--Link">
        <SocialMediaButton social-media="EMAIL" v-bind="buttonProps" />
      </li>
      <li v-if="withUrlCopy" class="ShareLinks--Link" @click="copyUrl()">
        <SocialMediaButton social-media="COPY_URL" v-bind="buttonProps" />
      </li>
      <li v-if="$device.isMobileOrTablet">
        <SocialMediaButton social-media="MESSENGER" v-bind="buttonProps" />
      </li>
    </ul>
  </client-only>
</template>

<script>
import { get } from 'lodash-es'
import { haMoment } from '@ha/helpers'

import SocialMediaButton from '@/components/globals/share/SocialMediaButton.vue'

export default {
  name: 'ShareLinks',
  components: {
    SocialMediaButton
  },
  inject: ['$notifications'],
  props: {
    withEmail: {
      type: Boolean,
      default: false
    },
    withUrlCopy: {
      type: Boolean,
      default: false
    },
    /**
     * Most of times, we can easily fetch campaign infos from the store
     * but with "out of context" pages, we have to specify which form/org to
     * ex: confirmation, unpaid...
     */
    formContext: {
      type: Object,
      default: () => {
        return {}
      }
    },
    organizationContext: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata) || this.formContext
    },
    organization() {
      return (
        this.$store.getters['organizations/getOrganization'](this.metadata.organization) ||
        this.organizationContext
      )
    },
    buttonProps() {
      return {
        href: get(this.form, 'url'),
        formName: get(this.form, 'title'),
        formSlug: get(this.form, 'formSlug'),
        formType: get(this.form, 'formType'),
        formDate: get(this.form, 'startDate') ? haMoment(get(this.form, 'startDate')) : undefined,
        organization: get(this.organization, 'name'),
        sharingLocation: get(this.form, 'place')
      }
    }
  },
  methods: {
    copyUrl() {
      this.$notifications.push({
        type: 'success',
        title: this.$t('toast.copyUrl.title'),
        body: this.$t('toast.copyUrl.body'),
        timeout: 5000
      })
    }
  }
}
</script>

<style lang="scss">
.ShareLinks {
  display: inline-flex;
  flex-wrap: wrap;

  &--Link {
    margin: $ha-spacing-small $ha-spacing-small 0 0;
  }
}
</style>
