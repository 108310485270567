<template>
  <div v-if="sticky" class="NumberPart NumberPart-Sticky">
    <span v-if="data.display == 'before'" class="NumberPartLabel">
      <template v-if="isCrowdfunding && data.type === 'amountCollected'">
        <fa icon="coins" class="CardNumbers--Icon" />
        <span class="CardNumbers--KeyNumbers">
          {{ getFormattedValue(data) }}
        </span>
        {{
          $t(`campaign.amountCollectedCrowdSticky`, [
            convertToEuros(form.financialGoal)
          ])
        }}
      </template>
      <template v-else>
        {{ $tc(`campaign.${data.type}`, data.value) }}
      </template>
    </span>
    <span v-if="data.display == 'after'" class="NumberPartLabel">
      {{ $tc(`campaign.${data.type}`, data.value) }}
    </span>
  </div>
  <div v-else class="NumberPart">
    <fa
      v-if="!!data.icon"
      class="NumberPart--Icon"
      :icon="data.icon"
      aria-hidden="true"
    />
    <span v-if="data.display == 'before'" class="NumberPart--Label">
      {{ $tc(`campaign.${data.type}`, data.value) }}
    </span>
    <span class="NumberPart--Highlight">
      {{
        `${getFormattedValue(data)}${
          data.type === 'amountCollected' ? ' €' : ''
        }`
      }}
    </span>
    <span v-if="data.display == 'after'" class="NumberPart--Label">
      {{ toLower($tc(`campaign.${data.type}`, data.value)) }}
    </span>
  </div>
</template>

<script>
import { enum as enums, functions } from '@ha/helpers'
import { startsWith, toLower } from 'lodash-es'

export default {
  name: 'NumberPart',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sticky: {
      type: Boolean,
      required: false,
      default: false
    },
    isMain: Boolean
  },
  data() {
    return {
      toLower
    }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    formTypes() {
      return enums.FormType
    },
    isCrowdfunding() {
      return this.formTypes.CROWDFUNDING === this.form.formType
    },
    isSaleEndDate() {
      return startsWith(this.data.type, 'saleEndDate')
    }
  },
  methods: {
    convertToEuros(price) {
      return functions.convertToEuros(price)
    },
    getFormattedValue(data) {
      if (data.category == 'number') {
        return functions.formatThousands(data.value)
      } else {
        return data.value
      }
    }
  }
}
</script>
