<script setup>
import { computed, ref } from 'vue'
import { functions, haMoment } from '@ha/helpers'
import { useContext } from '@nuxtjs/composition-api'
import { HaButton } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import useCartSummary from '@/components/carts/summary/useCartSummary'

const { organization } = useStoreData()
const { $device, i18n } = useContext()
const { totalAmountInEuros, tiersAmount, totalAmount, dayOfLevy, tipSelected } = useCartSummary()

const scheduleIterations = 12

const showSchedule = ref(!$device.isMobile)
const buttonScheduleLabel = computed(() =>
  showSchedule.value
    ? i18n.t('campaign.hidePaymentSchedule')
    : i18n.t('campaign.seePaymentSchedule')
)
const totalScheduleAmount = computed(() => totalAmount.value * scheduleIterations)
const totalScheduleAmountInEuros = computed(() =>
  functions.convertToEuros(totalScheduleAmount.value)
)
const totalScheduleTiersAmount = computed(() => tiersAmount.value * scheduleIterations)
const totalScheduleTiersAmountInEuros = computed(() =>
  functions.convertToEuros(totalScheduleTiersAmount.value)
)
const totalScheduleTip = computed(() => tipSelected.value * scheduleIterations)
const totalScheduleTipInEuros = computed(() => functions.convertToEuros(totalScheduleTip.value))

const getScheduleDate = index => {
  if (index === 0) return haMoment().format('DD/MM/YYYY')
  return dayOfLevy.value
    ? haMoment().day(dayOfLevy.value).add(index, 'month').format('DD/MM/YYYY')
    : haMoment().add(index, 'month').format('jj/MM/YYYY')
}
</script>

<template>
  <div class="cart-summary-schedule">
    <div class="cart-summary-schedule__header">
      <p v-if="!$device.isMobile">{{ $t('checkout.scheduler.title') }}</p>
      <HaButton icon-side="right" variant="link" @click="showSchedule = !showSchedule">
        <template #icon>
          <fa :icon="showSchedule ? 'chevron-up' : 'chevron-down'" />
        </template>
        <span v-if="$device.isMobile">{{ $t('checkout.scheduler.title') }}</span>
        <span v-else>
          {{ buttonScheduleLabel }}
        </span>
      </HaButton>
    </div>
    <template v-if="showSchedule">
      <div class="cart-summary-schedule__content">
        <p class="cart-summary-schedule__content__item">
          <span>
            {{ $t('checkout.scheduler.date', [getScheduleDate(0)]) }}
            <span class="cart-summary-schedule__content__item__bull">&bull;</span>
            {{ $t('checkout.scheduler.today') }}
          </span>
          <span class="cart-summary-schedule__content__item__price">
            {{ $t('campaign.currencyUnit', [totalAmountInEuros]) }}
          </span>
        </p>
        <p
          v-for="index in scheduleIterations - 1"
          :key="index"
          class="cart-summary-schedule__content__item"
        >
          <span>
            {{ $t('checkout.scheduler.date', [getScheduleDate(index)]) }}
          </span>
          <span class="cart-summary-schedule__content__item__price">
            {{ $t('campaign.currencyUnit', [totalAmountInEuros]) }}
          </span>
        </p>
      </div>
      <div class="cart-summary-schedule__footer">
        <p class="cart-summary-schedule__footer__item cart-summary-schedule__footer__item--total">
          <span>{{ $t('campaign.total') }}</span>
          <span class="cart-summary-schedule__footer__item__price">
            {{ $t('campaign.currencyUnit', [totalScheduleAmountInEuros]) }}
          </span>
        </p>
        <p class="cart-summary-schedule__footer__item">
          <span>{{ $t('checkout.scheduler.for', [organization.name]) }}</span>
          <span class="cart-summary-schedule__footer__item__price">
            {{ $t('campaign.currencyUnit', [totalScheduleTiersAmountInEuros]) }}
          </span>
        </p>
        <p class="cart-summary-schedule__footer__item">
          <span>{{ $t('checkout.scheduler.contribution') }}</span>
          <span class="cart-summary-schedule__footer__item__price">
            {{ $t('campaign.currencyUnit', [totalScheduleTipInEuros]) }}
          </span>
        </p>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.cart-summary-schedule {
  &__header,
  &__content,
  &__footer {
    margin: $ha-spacing-large;

    &__item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      margin-bottom: $ha-spacing-tiny;

      &__bull {
        color: var(--ha-color-text-lightest);
      }

      &__price {
        color: var(--ha-color-text);
        font-weight: $ha-font-weight-semibold;
      }
    }
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: $ha-spacing-medium $ha-spacing-small;

    @include mediaQuery(600) {
      justify-content: space-between;
      margin: $ha-spacing-large;
    }

    p {
      margin: 0;
      color: var(--ha-color-text);
      font-weight: $ha-font-weight-semibold;
      font-size: $ha-font-size-regular;
    }
  }

  &__footer {
    &__item {
      margin-bottom: calc($ha-spacing-tiny / 2);
      font-size: $ha-font-size-small;

      &--total {
        margin-bottom: $ha-spacing-small;
        color: var(--ha-color-text);
        font-weight: $ha-font-weight-semibold;
        font-size: $ha-font-size-large;

        &__price {
          font-weight: $ha-font-weight-bold;
        }
      }
    }
  }
}
</style>
