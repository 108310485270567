<template>
  <div class="BlockedCollectBanner">
    <div class="BlockedCollectBanner--HelpText">
      <p>
        {{ $t('campaign.step.blockedBanner.paymentHasBeenSuspended') }}
      </p>
      <p>
        {{ $t('campaign.step.blockedBanner.giveUsEmailToBeNotified', [organization.name]) }}
      </p>
    </div>
    <div class="BlockedCollectBanner--Email">
      <validation-observer slim>
        <validation-provider
          v-slot="{ valid, validated, errors }"
          name="email"
          rules="required|email"
          slim
        >
          <ha-form-field :is-valid="validated ? valid : null">
            <template #label>
              {{ $t('campaign.field.yourEmailAddress') }}
            </template>
            <ha-input
              v-model="email"
              :placeholder="$t('campaign.field.yourEmailAddress')"
              :is-valid="validated ? valid : null"
              name="email"
            />
            <template #error>{{ errors[0] }}</template>
          </ha-form-field>
          <ha-button
            class="BlockedCollectBanner--Submit"
            color="basic"
            :disabled="!valid"
            :data-ux="`Forms_${metadata.type}${isWidget ? 'Widget' : ''}_EmailProspect`"
            @click="sendEmail"
          >
            {{ $t('button.send') }}
          </ha-button>
        </validation-provider>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash-es'
import { HaFormField, HaInput, HaButton } from '@ha/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'BlockedCollectBanner',
  components: {
    HaFormField,
    HaInput,
    HaButton,
    ValidationProvider,
    ValidationObserver
  },
  inject: ['$notifications'],
  props: {
    isWidget: Boolean
  },
  data() {
    return {
      email: null
    }
  },
  computed: {
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.metadata.organization)
    }
  },
  methods: {
    async sendEmail() {
      try {
        await this.$store.dispatch('carts/sendProspect', {
          metadata: this.metadata,
          email: this.email
        })
        this.$notifications.push({
          type: 'success',
          title: this.$t('toast.prospect.title'),
          body: this.$t('toast.prospect.body', [get(this.organization, 'name')]),
          timeout: 5000
        })
      } catch (error) {
        this.$notifications.push(
          {
            type: 'danger',
            title: this.$t('error.code.' + error.response.status + '.title'),
            body: this.$t('error.code.' + error.response.status + '.message'),
            timeout: 5000
          },
          error
        )
      }
    }
  }
}
</script>

<style lang="scss">
.BlockedCollectBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: $ha-spacing-large;
  padding: $ha-spacing-large;
  font-size: $ha-font-size-small;
  background-color: var(--ha-color-primary);
  border-radius: $ha-radius-large;

  @include mediaQuery(600) {
    flex-direction: row;
    justify-content: space-between;
  }

  &--HelpText {
    @include mediaQuery(600) {
      width: 45%;
    }

    p {
      color: var(--ha-color-white);
    }

    :first-child {
      font-weight: $ha-font-weight-semibold;
    }
  }

  &--Email {
    text-align: center;

    @include mediaQuery(600) {
      width: 45%;
      text-align: left;
    }

    .HaFormField {
      text-align: left;
    }

    .Label {
      color: var(--ha-color-white);
    }
  }

  &--Submit {
    margin-top: $ha-spacing-medium;
  }
}
</style>
