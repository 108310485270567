<script setup>
import { computed } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { functions } from '@ha/helpers'
import { getPaymentFrequency } from '@/helpers/misc'
import useTiers from '@/components/tiers/useTiers'
import useStoreData from '@/composables/useStoreData'
import {
  getTierPrice,
  isTierMonthly,
  isTierScheduledPayments
} from '@/components/tiers/helpers/index.ts'

const props = defineProps({
  tier: { type: Object, required: true }
})

const { i18n } = useContext()
const { selectedTiers } = useTiers()
const { form } = useStoreData()

const priceLabel = computed(() =>
  props.tier.price
    ? i18n.t(`globals.price`, { price: functions.convertToEuros(props.tier.price) })
    : i18n.t('globals.free')
)
const scheduledLabel = computed(() =>
  isTierMonthly(props.tier) ? i18n.t('tiers.price.perMonth') : i18n.t('tiers.price.perTime')
)
const scheduledTotal = computed(() => {
  const tierPrice =
    getTierPrice(props.tier, selectedTiers.value) * getPaymentFrequency(props.tier, form.value)
  const total = i18n.t('globals.price', { price: functions.convertToEuros(tierPrice) })
  return i18n.t('tiers.price.scheduledTotal', { total })
})
</script>

<template>
  <p class="tier-item-price-fixed">
    {{ priceLabel }}
    <template v-if="isTierScheduledPayments(props.tier)">
      <span class="tier-item-price-fixed__label">
        {{ scheduledLabel }}
      </span>
      <span class="tier-item-price-fixed__total">
        {{ scheduledTotal }}
      </span>
    </template>
  </p>
</template>

<style lang="scss" scoped>
.tier-item-price-fixed {
  margin: 0;
  color: var(--ha-color-text);

  &::first-letter {
    text-transform: capitalize;
  }

  &__label,
  &__total {
    display: block;
    margin-top: $ha-spacing-mini;
    font-weight: $ha-font-weight-semibold;
    text-align: center;
  }

  &__label {
    color: var(--ha-color-text-light);
    font-size: $ha-font-size-regular;
  }

  &__total {
    color: var(--ha-color-text-lightest);
    font-size: $ha-font-size-small;
  }
}
</style>
