<script>
/*
In order to update the vente's metadata we have to use defineComponent and empty head
Source : https://composition-api.nuxtjs.org/packages/usemeta/
@TODO : When the migration to nuxt 3 will be done it will be necessary to remove the defineComponent
and use the useHead composable : https://nuxt.com/docs/getting-started/seo-meta#usehead
*/
import { defineComponent, useContext, useStore } from '@nuxtjs/composition-api'
import { ref, computed, onMounted, onBeforeUnmount, inject } from 'vue'
import { ValidationObserver } from 'vee-validate'
import { HaTile, HaButton } from '@ha/components'
import useMetaData from '@/composables/useMetaData'
import useSale from '@/components/forms/sales/useSale'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import SaleHeader from '@/components/forms/sales/partials/SaleHeader.vue'
import useNotifications from '@/composables/useNotifications'
import SalePersonalInformations from '@/components/forms/sales/partials/SalePersonalInformations.vue'
import SalePrice from '@/components/forms/sales/partials/SalePrice.vue'
import SaleSummary from '@/components/forms/sales/partials/SaleSummary.vue'
import NoticeVoluntaryContribution from '@/components/voluntary-contributions/NoticeVoluntaryContribution.vue'
import LegalCheckboxes from '@/components/carts/LegalCheckboxes.vue'
import CardSecurity from '@/components/globals/informations/CardSecurity.vue'
import ActionReport from '@/components/reports/ActionReport.vue'
import useLegalCheckbox from '@/components/carts/useLegalCheckbox'
import FormBlockedCollectBanner from '@/components/forms/informations/FormBlockedCollectBanner.vue'
import useStoreData from '@/composables/useStoreData'
import useTracking from '@/composables/useTracking'
import { scrollCenterToErrorElement } from '@/helpers/formUtils'

export default defineComponent({
  components: {
    ValidationObserver,
    HaButton,
    HaTile,
    SalePrice,
    SaleHeader,
    SalePersonalInformations,
    SaleSummary,
    NoticeVoluntaryContribution,
    LegalCheckboxes,
    CardSecurity,
    ActionReport,
    FormBlockedCollectBanner
  },
  setup() {
    const { $recaptcha, $config, $gtm } = useContext()
    const { setMetaData } = useMetaData()
    const { onError } = useNotifications()
    const { trackingMethods } = useTracking()
    const {
      cart,
      selectedTier,
      customAmount,
      isFixedPrice,
      setVente,
      createCart,
      redirectToPayment
    } = useSale()
    const { canCollect, storeRouteParams } = useStoreData()
    const { tipSelected, tipSuggested } = useVoluntaryContribution()
    const { legalState } = useLegalCheckbox()
    const store = useStore()


    const isWidget = inject('isWidget')
    const venteForm = ref(null)
    const isSubmitting = ref(false)

    const isTipable = computed(() => {
      return tipSelected.value > 0
    })

    const hasPrice = computed(() => isFixedPrice.value || customAmount.value > 0)
    const isTipConsentValid = computed(() => !isTipable.value || legalState.value.tipConsent)

    const isSubmitBtnActivated = computed(() => {
      return (
        !isSubmitting.value &&
        legalState.value.gdrpConsent &&
        isTipConsentValid.value &&
        hasPrice.value
      )
    })
    const requiresCaptcha = computed(() => {
      return $config.NUXT_ENV_ONE_PAGE_RECAPTCHA_ENABLED
    })

    const getCanSubmit = async () => {
      const isFormValid = await venteForm.value.validate()
      const hasToken = !requiresCaptcha.value || (await $recaptcha.execute('sale_checkout'))

      return isFormValid && hasToken
    }

    const onSubmit = async () => {
      isSubmitting.value = true
      try {
        const canSubmit = await getCanSubmit()
        const tipData = {
          tipSuggestion: tipSuggested.value.default,
          tipSuggestionMax: tipSuggested.value.max,
          tipSuggestionMin: tipSuggested.value.min,
          tipChosen: tipSelected.value
        }
        if (!canSubmit) {
          scrollCenterToErrorElement(venteForm.value)
        }
        if (canSubmit) {
          await createCart()
          await redirectToPayment()
          trackingMethods.onFormSubmission(tipData)
        }
      } catch (err) {
        onError(err, { body: err.message })
      } finally {
        isSubmitting.value = false
      }
    }

    setVente()
    setMetaData()

    onMounted(async () => {
      $gtm.push(store.getters['carts/getGtmData'](1, storeRouteParams.value))
      if (requiresCaptcha.value) await $recaptcha.init()
    })

    onBeforeUnmount(async () => {
      if (requiresCaptcha.value) await $recaptcha.destroy()
    })

    return {
      cart,
      customAmount,
      selectedTier,
      isSubmitBtnActivated,
      venteForm,
      isTipable,
      isSubmitting,
      onSubmit,
      canCollect,
      isWidget
    }
  },
  head: {}
})
</script>

<template>
  <ValidationObserver :ref="'venteForm'" class="VenteStep" tag="div">
    <SaleHeader />
    <FormBlockedCollectBanner
      v-if="!canCollect"
      class="VenteStep--HeaderOverlay VenteStep--Card"
      :is-widget="isWidget"
    />
    <template v-else>
      <SalePrice class="VenteStep--Card VenteStep--HeaderOverlay" />
      <SalePersonalInformations class="VenteStep--Card" />
      <SaleSummary class="VenteStep--Card" />

      <HaTile class="VenteStep--Card VenteStep--ContributionCard">
        <div class="VenteStep--ContributionCard--Wrapper">
          <NoticeVoluntaryContribution horizontal center-text />
        </div>
        <div class="VenteStep--ContributionCard--Wrapper">
          <LegalCheckboxes :is-tipable="isTipable" />
        </div>
      </HaTile>
      <HaButton
        class="VenteStep--Card VenteStep--Submit"
        :disabled="!isSubmitBtnActivated"
        :loading="isSubmitting"
        fluid
        @click="onSubmit"
      >
        {{ $t('campaign.validateAndPay') }}
      </HaButton>

      <CardSecurity class="VenteStep--Card" horizontal />
      <ActionReport />
    </template>
  </ValidationObserver>
</template>

<style lang="scss" scoped>
.VenteStep {
  @include mediaQuery(600) {
    &--Card {
      max-width: $ha-unit * 98 !important; // 784px
      margin: $ha-spacing-medium auto;
    }
  }

  &--HeaderOverlay {
    position: relative;
    z-index: 1;
    margin-top: -$ha-spacing-giant;
  }

  &--ContributionCard {
    &--Wrapper {
      padding: $ha-spacing-large;

      &:first-child {
        border: solid var(----ha-color-border);
        border-bottom: $ha-border-regular;
      }
    }
  }

  &--Submit {
    display: block;
    margin: $ha-spacing-medium auto;
  }

  .CardSecurity {
    margin: $ha-spacing-medium auto;
  }

  :deep(.BlockedCollectBanner) {
    background-color: var(--ha-color-white);
    box-shadow: $ha-box-shadow-close;

    p {
      color: var(--ha-color-text) !important;
    }
  }
}
</style>
