<template>
  <div class="CardOrganization--Address CardAddress">
    <section v-if="doesPlaceNameStartWithUrl">
      <ha-button
        :href="address"
        target="_blank"
        variant="link"
        color="primary"
        :data-ux="`Forms_${metadata.type}_CardAddress_EventLink`"
      >
        {{ $t('card.address.eventLink') }}
      </ha-button>
    </section>
    <template v-else>
      <section v-if="hasCoordinates" class="CardAddress--Map Is-Paddingless">
        <div class="CardAddress--MapWrapper">
          <div class="CardAddress--MapImage">
            <MapLibre :coordinates="coordinates" />
          </div>
          <span>{{ $t('card.organization.map') }}</span>
        </div>
      </section>
      <section class="CardAddress--Location Is-Borderless">
        <span v-if="addressName" class="CardOrganization--Title">
          {{ addressName }}
        </span>
        <p class="CardAddress--Address">{{ address }}</p>
        <p class="CardAddress--Address">{{ zipCity }}</p>
        <p class="CardAddress--Address">{{ country }}</p>
      </section>
    </template>
  </div>
</template>

<script>
import { get } from 'lodash-es'
import { HaButton } from '@ha/components'
import { enum as enums } from '@ha/helpers'
import MapLibre from '@/components/globals/map/MapLibre.vue'

export default {
  name: 'CardAddress',
  components: {
    HaButton,
    MapLibre
  },
  computed: {
    hasCoordinates() {
      return !!get(this, 'form.place.geoLocation')
    },
    coordinates() {
      const geoLocation = get(this, 'form.place.geoLocation')
      const { latitude, longitude } = geoLocation

      return [latitude, longitude]
    },
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    place() {
      return get(this.form, 'place')
    },
    address() {
      return get(this, 'place.address', '')
    },
    addressName() {
      return get(this, 'place.name', '')
    },
    zipCity() {
      return `${get(this, 'place.zipCode', '')} ${get(this, 'place.city', '')}`
    },
    country() {
      const country = enums.Country.find(item => item.code3 === this.place?.country)
      return country ? country.label.fr : 'France'
    },
    contact() {
      return get(this.form, 'contact')
    },
    doesPlaceNameStartWithUrl() {
      const place = get(this, 'form.place')

      if (!place) {
        return false
      }

      const { name } = get(this, 'form.place')
      const pattern = new RegExp(/^(https?:\/\/)/gim)

      return !!pattern.test(name)
    }
  }
}
</script>

<style lang="scss">
.CardAddress {
  border-bottom: $ha-border-width-regular solid set-alpha(border, 0.5);

  &--Map {
    padding: 0;

    + .CardAddress--Location {
      border-top: 0;
    }

    &Wrapper {
      position: relative;
      height: $ha-unit * 20; // 160px
      margin: 0 auto;
      overflow: hidden;
      background-color: var(--ha-color-background-disabled);
      border-radius: $ha-radius-regular;

      @include mediaQuery(900) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      > span {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: var(--ha-color-text-disabled);
        font-weight: $ha-font-weight-semibold;
        text-transform: uppercase;
      }
    }

    &Image {
      position: relative;
      z-index: 2;
      height: 100%;
    }
  }

  &--Location {
    padding: $ha-spacing-large;

    @include mediaQuery(600) {
      text-align: center;
    }

    @include mediaQuery(900) {
      text-align: left;
    }
  }

  &--Address {
    margin: 0;
  }
}
</style>
