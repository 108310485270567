<template>
  <HaTile data-testid="personal-informations">
    <h3>{{ $t('campaign.step.payer.title') }}</h3>
    <PayerPersonalInformations :is-address-needed="isAddressNeeded" />
  </HaTile>
</template>

<script>
import { functions, haMoment } from '@ha/helpers'
import { HaTile } from '@ha/components'
import PayerPersonalInformations from '@/components/payers/PayerPersonalInformations.vue'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import useFormType from '@/composables/useFormType'

export default {
  name: 'Payer',
  components: {
    HaTile,
    PayerPersonalInformations
  },
  setup() {
    const { personalInformations } = usePayerPersonalInformations()
    const { isCrowdfunding } = useFormType()

    return {
      personalInformations,
      isCrowdfunding
    }
  },
  data() {
    return {
      gtmEventHasBeenSent: false
    }
  },
  computed: {
    containsDonation() {
      return this.cart?.containsDonation || false
    },
    cart() {
      return this.$store.getters['carts/getCart'](this.metadata)
    },
    cartId() {
      return this.cart?.id
    },
    cartItems() {
      return this.cart?.itemList || []
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.metadata.organization)
    },
    showAddressForRF() {
      return (
        this.organization.fiscalReceiptEligibility &&
        functions.sumBy(this.cart?.paymentTerms, 'taxReceiptEligible') > 0
      )
    },
    isAddressNeeded() {
      return this.showAddressForRF || this.containsDonation || this.isCrowdfunding
    }
  },
  watch: {
    cartItems() {
      if (!this.gtmEventHasBeenSent) {
        this.$gtm.push(this.$store.getters['carts/getGtmData'](3, this.metadata))
        this.gtmEventHasBeenSeent = true
      }
    }
  },
  mounted() {
    // Used to make nextStep generic for all forms
    this.$emit('update:go-to-next-step', this.goToNextStep)
    this.$emit('update:can-click-next', true)

    if (!this.gtmEventHasBeenSent && this.cartItems.length > 0) {
      this.$gtm.push(this.$store.getters['carts/getGtmData'](3, this.metadata))
      this.gtmEventHasBeenSeent = true
    }
  },
  methods: {
    getData() {
      const payer = {
        firstName: this.personalInformations.firstName,
        lastName: this.personalInformations.lastName,
        email: this.personalInformations.email
      }

      if (this.personalInformations.isCompany) {
        payer.companyName = this.personalInformations.companyName
        if (this.showAddressForRF) {
          payer.companySiren = this.personalInformations.companySiren
          payer.companyLegalStatus = this.personalInformations.companyLegalStatus
        }
      }

      if (this.containsDonation) {
        payer.dateOfBirth = haMoment(this.personalInformations?.birthDate, 'DD/MM/YYYY').format()
      }

      // country is mandatory in API
      let place = { country: 'FRA' }

      if (this.personalInformations.isCompany || this.showAddressForRF || this.containsDonation) {
        place = {
          address: this.personalInformations.address,
          city: this.personalInformations.city,
          zipCode: this.personalInformations.zipCode,
          country: this.personalInformations.country
        }
      }

      payer.place = place

      // save new payer data in storage
      this.$store.dispatch('payer/setPayer', [payer, this.cartId])

      return {
        payer
      }
    },
    goToNextStep(formMetaData) {
      const formData = this.getData()
      // For testing purpose only => to be removed when api will have this parameter optional
      console.warn('[consent]: forced to true for testing purpose')
      formData.payer.consent = true

      this.$store.dispatch('carts/setPaiementStatus', [
        this.$route.query?.status,
        this.$route.query?.errorCode
      ])

      return this.$store
        .dispatch('payer/putPayer', [formData, formMetaData, this.cart?.id])
        .catch(err => {
          throw err
        })
    }
  }
}
</script>
