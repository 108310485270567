import { computed } from 'vue'
import { functions } from '@ha/helpers'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import useCart from '@/composables/useCart'

export default () => {
  const { tipSelected } = useVoluntaryContribution()
  const { cart } = useCart()

  const getTierAmount = tier => tier?.customAmount ?? tier?.price ?? 0
  const getTierAmountHT = tier => getTierAmount(tier) / (1 + tier.vatRate / 100)
  const getTierTvaAmount = tier => getTierAmount(tier) - getTierAmountHT(tier)

  const tiersAmount = computed(() => {
    return cart.value.items.reduce((sum, item) => sum + getTierAmount(item), 0)
  })
  const tiersVatAmount = computed(() =>
    cart.value.items.reduce((sum, item) => sum + getTierTvaAmount(item), 0)
  )
  const totalAmount = computed(() => tiersAmount.value + tipSelected.value)
  const tiersVatAmountInEuros = computed(() => functions.convertToEuros(tiersVatAmount.value))
  const tiersAmountInEuros = computed(() => functions.convertToEuros(tiersAmount.value))
  const totalAmountInEuros = computed(() => functions.convertToEuros(totalAmount.value))
  const dayOfLevy = computed(() => {
    const cartItem = cart.value.items.find(item => item.dayOfLevy)
    return cartItem?.dayOfLevy
  })
  const isMonthly = computed(
    () => !!cart.value.items.find(item => item.paymentFrequency === 'Monthly')
  )

  return {
    isMonthly,
    dayOfLevy,
    tipSelected,
    tiersAmount,
    tiersVatAmount,
    totalAmount,
    totalAmountInEuros,
    tiersAmountInEuros,
    tiersVatAmountInEuros
  }
}
