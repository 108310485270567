<template>
  <div v-if="isDiscountCodePristine || editDiscount" class="Coupon">
    <div class="Coupon--Text" @click="onToggleDiscountInput">
      {{ $t('abTasty.stepFour.discountLabel') }}
      <fa class="AddIcon" :icon="showDiscountInput ? 'minus-circle' : 'plus-circle'" />
    </div>
    <div v-if="showDiscountInput && editDiscount" class="Coupon--InputGroup">
      <HaInput
        v-model="discountCodeModel"
        :is-valid="isDiscountCodePristine ? null : isDiscountCodeValid"
        :aria-label="$t('coupon.label')"
        data-testid="input-discount"
        aria-describedby="error-message-coupon"
        @keypress.enter="onCouponClick"
      />
      <HaButton
        class="Coupon--Button"
        variant="outline"
        :loading="isLoading"
        :data-ux="`Forms_${metadata.type}_CouponField_AddCoupon`"
        data-testid="button-discount"
        @click="onCouponClick"
      >
        {{ $t('button.add') }}
        <span class="visually-hidden">{{ $t('coupon.label') }}</span>
      </HaButton>
    </div>
    <span v-if="!isDiscountCodeValid && !isDiscountCodePristine" id="error-message-coupon" class="Coupon--Error">
      {{ discountCodeErrorMessage }}
    </span>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { HaInput, HaButton } from '@ha/components'
import useDiscountCodeField from '@/components/discount-codes/useDiscountCodeField'

const props = defineProps({
  editDiscount: { type: Boolean, default: true }
})

const emit = defineEmits(['applied'])

const isLoading = ref(false)
const {
  discountCodeModel,
  submitDiscountCode,
  isDiscountCodeValid,
  isDiscountCodePristine,
  discountCodeErrorMessage
} = useDiscountCodeField()

const showDiscountInput = ref(false)

const onCouponClick = async () => {
  if (!discountCodeModel.value) return

  isLoading.value = true
  await submitDiscountCode()
  if (isDiscountCodeValid.value) {
    emit('applied')
  }
  isLoading.value = false
}

const onToggleDiscountInput = () => {
  showDiscountInput.value = !showDiscountInput.value
}
</script>

<style lang="scss" scoped>
/* stylelint-disable */
.Coupon {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: $ha-spacing-mini;
  margin-bottom: $ha-spacing-medium;

  @include mediaQuery(600) {
    align-items: flex-start;
    justify-content: left;
  }

  &--Text {
    display: flex;
    align-items: center;
    margin-bottom: $ha-spacing-tiny;
    margin-inline: $ha-spacing-mini;
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;
    text-align: center;
    cursor: pointer;

    @include mediaQuery(600) {
      padding-right: $ha-spacing-medium;
      margin-inline: $ha-spacing-large;
      text-align: right;
    }

    .AddIcon {
      height: $ha-spacing-medium * 1.25;
      margin-left: $ha-spacing-tiny;
      color: var(--ha-color-primary);
      border: $ha-spacing-mini * 0.75 solid
        color-mix(in srgb, var(--ha-color-iris) 10%, var(--ha-color-white));
      border-radius: $ha-radius-round;
    }
  }

  &--InputGroup {
    display: inline-flex;
    flex-wrap: nowrap;
    margin-inline: $ha-spacing-mini;

    @include mediaQuery(600) {
      margin-inline: $ha-spacing-large;
    }
  }

  &--Error {
    width: 100%;
    margin-top: $ha-spacing-mini;
    color: var(--ha-color-danger);
    font-weight: $ha-weight-semibold;
    font-size: $ha-font-size-tiny;

    @include mediaQuery(600) {
      text-align: left;
    }
  }

  .Input--Wrapper .Input {
    text-transform: uppercase;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .HaButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
