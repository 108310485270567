<script setup>
import { computed, ref, watch } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { functions } from '@ha/helpers'
import CustomPriceInput from '@/components/globals/form-fields/CustomPriceInput.vue'
import useTiers from '@/components/tiers/useTiers'
import useFormType from '@/composables/useFormType'
import { getTierQuantity, getTierPrice } from '@/components/tiers/helpers/index.ts'

const props = defineProps({
  tier: { type: Object, required: true }
})
const emit = defineEmits(['update-selected-tiers'])

const { i18n } = useContext()
const { selectedTiers, updateSelectedTier, resetOtherSelectedTiers, resetSelectedTiersQuantity } =
  useTiers()
const { isCrowdfunding } = useFormType()

/* eslint-disable vue/no-setup-props-destructure */
const initialCustomAmout = selectedTiers.value[props.tier.id]
  ? functions.convertToEuros(selectedTiers.value[props.tier.id].customAmount)
  : null
const customAmount = ref(initialCustomAmout)

const minAmountEuros = computed(() => functions.convertToEuros(props.tier?.minAmount) ?? 0)

const isTierSelected = computed(() => isCrowdfunding.value && !!selectedTiers.value[props.tier.id])

const rules = computed(() => {
  const rulesWithQuantity = `REQUIRED_AMOUNT|MIN_AMOUNT:${minAmountEuros.value}|MAX_AMOUNT:99999.99`
  if (isCrowdfunding.value) return isTierSelected.value ? rulesWithQuantity : ''
  const tierQuantity = getTierQuantity(props.tier, selectedTiers.value)
  return tierQuantity ? rulesWithQuantity : ''
})

const isImmediate = computed(() => {
  if (isCrowdfunding.value) return isTierSelected.value
  const tierQuantity = getTierQuantity(props.tier, selectedTiers.value)
  const tierPrice = getTierPrice(props.tier, selectedTiers.value)
  return !!tierQuantity || !!tierPrice
})

const getFormattedCustomAmount = value =>
  value ? functions.convertToCents(parseFloat(value)) : null

const onCustomPriceChange = value => {
  if (isCrowdfunding.value) resetOtherSelectedTiers(props.tier)
  updateSelectedTier(props.tier, { customAmount: getFormattedCustomAmount(value) })
  if (isCrowdfunding.value) {
    resetSelectedTiersQuantity()
  } else {
    emit('update-selected-tiers')
  }
}

/*
Need to watch selectedTier because it can be updated at mounted in the step tier due to cart persistent state
@TODO: Verify after tiers step refacto if it can be deleted
*/
watch(selectedTiers, () => {
  const selectedAmount = selectedTiers.value[props.tier.id]?.customAmount
  if (!!selectedAmount && selectedAmount !== getFormattedCustomAmount(customAmount.value)) {
    customAmount.value = functions.convertToEuros(selectedAmount)
  }
})
</script>

<template>
  <CustomPriceInput
    v-model="customAmount"
    :rules="rules"
    size="small"
    step="0.01"
    :is-immediate="isImmediate"
    class="tier-item-price-custom"
    data-test="input-amount-pwyw"
    @input="onCustomPriceChange"
  >
    <template #suffix>
      {{ i18n.t('globals.price', { price: '' }) }}
    </template>
  </CustomPriceInput>
</template>

<style scoped lang="scss">
.tier-item-price-custom {
  :deep(.Input) {
    font-size: $ha-font-size-jumbo;
  }
}
</style>
