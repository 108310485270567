<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="organization.fiscalReceiptEligibility"
    class="RFHighlight"
    :class="{ 'RFHighlight--light': lightTheme }"
    data-testid="info-rf"
  >
    <fa :icon="icon" aria-hidden="true" />
    <span v-html="$t('campaign.priceAfterRfReduction', [priceAfterRfReduction])" />
  </div>
</template>

<script>
import { computeRfReduction } from '@/helpers/formUtils'
import { functions } from '@ha/helpers'

export default {
  name: 'FiscalReductionHighlight',
  props: {
    price: {
      validator(value) {
        return !Number.isNaN(parseFloat(value))
      },
      required: true
    },
    isCompany: {
      type: Boolean,
      default: false
    },
    lightTheme: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.metadata.organization)
    },
    icon() {
      return this.lightTheme ? 'tag' : 'lightbulb'
    },
    priceAfterRfReduction() {
      return functions.formatThousands(
        functions.convertToEuros(parseFloat(this.price) - this.getRFReduction())
      )
    }
  },
  methods: {
    getRFReduction() {
      return computeRfReduction(
        parseFloat(this.price),
        this.organization.isSubjectToColucheLaw,
        this.isCompany
      )
    }
  }
}
</script>

<style lang="scss">
.RFHighlight {
  margin-top: $ha-spacing-medium;
  padding: $ha-spacing-medium;
  color: var(--ha-color-text-light);
  font-size: $ha-font-size-small;
  text-align: left;
  background: var(--ha-color-background);
  border-radius: $ha-radius-large;

  svg,
  b {
    color: var(--ha-color-primary);
  }

  svg {
    margin-right: $ha-spacing-tiny;
  }

  &--light {
    margin-top: $ha-spacing-small;
    padding: 0;
    color: var(--ha-color-text-lightest);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;
    background: none;

    svg,
    b {
      color: var(--ha-color-text-lightest);
    }
  }
}
</style>
