var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasData)?_c('div',{class:['CardNumbers Card-Split', { 'CardNumbers-Sticky': _vm.sticky, Card: !_vm.sticky && !_vm.widget }]},[_c('section',{staticClass:"CardNumbers--Main"},[(!_vm.sticky)?_c('NumberPart',{attrs:{"data":_vm.mainData,"sticky":_vm.sticky}}):_vm._e(),_vm._v(" "),((_vm.isCrowdfunding || _vm.counter) && _vm.mainData.type == 'amountCollected')?[(_vm.$device.isMobile || _vm.sticky || (_vm.widget && !_vm.counter))?[_c('div',{staticClass:"CardNumbers--Mobile"},[(_vm.form)?_c('RadialProgress',{attrs:{"diameter":25,"amount-collected":_vm.form.amountCollected,"goal":_vm.form.financialGoal,"start-color":_vm.formColor,"stop-color":_vm.formColor,"stroke-linecap":"square","background-circle-width":3,"progress-circle-width":3,"background-circle-color":"#D1D6DE"}}):_vm._e(),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('campaign.fundedAt', [
                _vm.percentage,
                _vm.convertToEuros(_vm.form.amountCollected),
                _vm.convertToEuros(_vm.form.financialGoal)
              ])
            )}})],1)]:[(_vm.form)?_c('RadialProgress',{attrs:{"animated":"","has-percentage":"","diameter":100,"amount-collected":_vm.form.amountCollected,"goal":_vm.form.financialGoal,"start-color":_vm.formColor,"stop-color":_vm.formColor,"stroke-linecap":"square"}}):_vm._e(),_vm._v(" "),(_vm.form.financialGoal >= 0)?_c('span',{staticClass:"CardNumbers--Goal"},[_vm._v("\n          "+_vm._s(_vm.$t('campaign.goal', [_vm.convertToEuros(_vm.form.financialGoal)]))+"\n        ")]):_vm._e()]]:_vm._e(),_vm._v(" "),(_vm.isCrowdfunding && !_vm.widget)?_c('ha-button',{staticClass:"CardNumbers--Contribute",attrs:{"fluid":"","href":"#tickets"}},[_vm._v("\n      "+_vm._s(_vm.$t('campaign.contribute'))+"\n    ")]):_vm._e()],2),_vm._v(" "),(_vm.secondaryData.length > 0 && !_vm.sticky && (!_vm.widget || _vm.counter))?_c('section',{staticClass:"CardNumbers--Secondary"},_vm._l((_vm.secondaryData),function(secondary){return _c('NumberPart',{key:secondary.type,attrs:{"data":secondary}})}),1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }