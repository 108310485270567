import { CampaignType } from '@/helpers/enums'

import ParticipantsStep from '@/components/participants/ParticipantsStep.vue'
import PayersStep from '@/components/payers/PayersStep.vue'
import CartsStep from '@/components/carts/CartsStep.vue'
import TiersStep from '@/components/tiers/TiersStep.vue'

export const FormSteps = {
  [CampaignType.EVENT]: [
    {
      title: 'campaign.stepName.ticketSelection',
      icon: 'shopping-cart',
      component: TiersStep,
      'data-testid': 'tab-prices'
    },
    {
      title: 'campaign.stepName.participants',
      icon: 'user',
      component: ParticipantsStep,
      'data-testid': 'tab-members'
    },
    {
      title: 'campaign.stepName.billingInformation',
      icon: 'list',
      component: PayersStep,
      'data-testid': 'tab-payer'
    },
    {
      title: 'campaign.stepName.summary',
      icon: 'check-square',
      component: CartsStep,
      'data-testid': 'tab-summary'
    }
  ],
  [CampaignType.SHOP]: [
    {
      title: 'campaign.stepName.productSelection',
      icon: 'shopping-cart',
      component: TiersStep,
      'data-testid': 'tab-prices'
    },
    {
      title: 'campaign.stepName.productsDetails',
      icon: 'boxes',
      component: ParticipantsStep,
      'data-testid': 'tab-members'
    },
    {
      title: 'campaign.stepName.billingInformation',
      icon: 'list',
      component: PayersStep,
      'data-testid': 'tab-payer'
    },
    {
      title: 'campaign.stepName.summary',
      icon: 'check-square',
      component: CartsStep,
      'data-testid': 'tab-summary'
    }
  ],
  [CampaignType.MEMBERSHIP]: [
    {
      title: 'campaign.stepName.type.membership.membershipSelection',
      icon: 'shopping-cart',
      component: TiersStep,
      'data-testid': 'tab-prices'
    },
    {
      title: 'campaign.stepName.type.membership.members',
      icon: 'user',
      component: ParticipantsStep,
      'data-testid': 'tab-members'
    },
    {
      title: 'campaign.stepName.billingInformation',
      icon: 'list',
      component: PayersStep,
      'data-testid': 'tab-payer'
    },
    {
      title: 'campaign.stepName.summary',
      icon: 'check-square',
      component: CartsStep,
      'data-testid': 'tab-summary'
    }
  ],
  [CampaignType.CROWDFUNDING]: [
    {
      title: 'campaign.stepName.donationSelection',
      icon: 'shopping-cart',
      component: TiersStep,
      'data-testid': 'tab-prices'
    },
    {
      title: 'campaign.stepName.contributors',
      icon: 'user',
      component: ParticipantsStep,
      'data-testid': 'tab-members'
    },
    {
      title: 'campaign.stepName.billingInformation',
      icon: 'list',
      component: PayersStep,
      'data-testid': 'tab-payer'
    },
    {
      title: 'campaign.stepName.summary',
      icon: 'check-square',
      component: CartsStep,
      'data-testid': 'tab-summary'
    }
  ]
}
