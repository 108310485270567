import { computed } from 'vue'
import { FormSteps } from '@/helpers/formConfig'
import { useContext } from '@nuxtjs/composition-api'
import useStoreData from '@/composables/useStoreData'
import useRouteParams from './useRouteParams'
import useFormType from './useFormType'

export default () => {
  const { form } = useStoreData()
  const { step, storeRouteParams } = useRouteParams()
  const { isShop, isCrowdfunding } = useFormType()
  const { i18n } = useContext()

  const hasCustomFieldsOrOptions = computed(() => {
    let hasOptionOrCustomFields = false
    form.value.tiers.forEach(tier => {
      if (tier?.customFields?.length || tier?.extraOptions?.length) {
        hasOptionOrCustomFields = true
      }
    })
    return hasOptionOrCustomFields
  })

  const formSteps = computed(() => {
    if ((isShop.value || isCrowdfunding.value) && !hasCustomFieldsOrOptions.value) {
      return FormSteps[storeRouteParams.value.type].filter((_, index) => index !== 1)
    }
    return FormSteps[storeRouteParams.value.type]
  })

  const stepName = computed(() => {
    return i18n.t(formSteps.value[step.value - 1].title)
  })

  return {
    hasCustomFieldsOrOptions,
    formSteps,
    stepName
  }
}
