<template>
  <ha-modal class="ModalExpiredSession" v-on="$listeners">
    <template #header>
      {{ $t('modal.expiredSession.title') }}
    </template>
    <template #body>
      <p>{{ $t('modal.expiredSession.content_1') }}</p>
      <p>
        {{
          $t(`modal.expiredSession.content_2.${form.formType.toLowerCase()}`)
        }}
      </p>
    </template>
    <template #footer>
      <ha-button
        data-ux="Forms_Modal_ExpiredSession_GotIt"
        @click="$emit('close')"
      >
        {{ $t('modal.close') }}
      </ha-button>
    </template>
  </ha-modal>
</template>

<script>
import { HaModal, HaButton } from '@ha/components'

export default {
  name: 'ModalExpiredSession',
  components: {
    HaModal,
    HaButton
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    }
  }
}
</script>
