import colorConvert from 'color-convert'

export const getCustomColor = hexColor => {
  const hslColor = colorConvert.hex.hsl(hexColor)
  const [h, s, l] = hslColor.values()

  const colorAttributes = {
    '--ha-color-primary': `hsl(${h}, ${s}%, ${l}%)`,
    '--ha-color-primary-h': `${h}`,
    '--ha-color-primary-s': `${s}%`,
    '--ha-color-primary-l': `${l}%`
  }

  // get proper format for inserting in html tag
  let customColors = ''
  for (let key in colorAttributes) {
    customColors += `${key}: ${colorAttributes[key]};`
  }

  return customColors
}
