<template>
  <a
    :href="formattedURL(tag.url)"
    target="_blank"
    :data-ux="dataUx"
    @click="trackClickSegment()"
  >
    {{ tag.name }}
  </a>
</template>

<script>
import { functions } from '@ha/helpers'
import useTracking from '@/composables/useTracking'

export default {
  name: 'Tag',
  props: {
    tag: {
      type: Object
    }
  },
  setup() {
    const { trackingTrack } = useTracking()
    return { trackingTrack }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    dataUx() {
      return `Forms_${this.form?.formType}_TagCloud_${this.tag?.infoSeo.dataUx}`
    }
  },
  methods: {
    formattedURL(slug) {
      return '/e/' + this.cleanSlug(slug)
    },
    cleanSlug(slug) {
      return functions.deburr(slug.toLowerCase())
        .replace(/('|’)/g, '---')
        .replace(/\s/g, '--')
        .replace('&', 'et')
    },
    trackClickSegment() {
      this.trackingTrack('Explore Link Clicked', {
        activity_type: this.tag.url.includes('act') ? this.tag?.infoSeo?.tagName : '',
        category_tags: this.tag.url.includes('cat') ? this.tag?.infoSeo?.tagName : '',
        city: this.tag?.infoSeo?.city ?? '',
        department: this.tag?.infoSeo?.department ?? '',
        ha_tags: this.tag.url.includes('evt') ? this.tag?.infoSeo?.tagName : '',
        link_origin: 'CardSeoTags',
        url: this.formattedURL(this.tag.url),
        region: this.tag?.infoSeo?.region ?? '',
      })
    }
  }
}
</script>
