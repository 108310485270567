<template>
  <div class="SelectDonation Block" data-testid="donation-block">
    <div class="Block--Title Block--Title-Active" data-testid="title-donation">
      <fa icon="heart" aria-hidden="true" />
      {{ $t('campaign.myDonation') }}
    </div>
    <div class="Block--Content">
      <p v-if="hasOneTierPayment" class="SelectDonation--OneTier">
        {{ hasOneTierPaymentLabel }}
      </p>
      <ha-input-group v-else class="SelectDonation--TierType">
        <ha-button
          v-if="hasPonctualPayments"
          :variant="selectedTierType === 'ponctual' ? 'fill' : 'outline'"
          :color="selectedTierType === 'ponctual' ? 'primary' : 'basic'"
          data-testid="button-unique-donation"
          @click="changeTierType('ponctual')"
        >
          {{ $t('campaign.IGiveOneTime') }}
        </ha-button>
        <ha-button
          v-if="hasMonthlyPayments"
          :variant="selectedTierType === 'monthly' ? 'fill' : 'outline'"
          :color="selectedTierType === 'monthly' ? 'primary' : 'basic'"
          data-testid="button-monthly-donation"
          @click="changeTierType('monthly')"
        >
          {{ $t('campaign.IGiveMonthly') }}
        </ha-button>
      </ha-input-group>

      <div v-if="monthlyTiers || ponctualTiers" class="SelectDonation--Tiers">
        <template v-if="selectedTierType === 'ponctual'">
          <ha-button
            v-for="tier in ponctualTiers"
            :key="`ponctual_${tier.id}`"
            :variant="isTierActive(tier) ? 'fill' : 'outline'"
            :color="isTierActive(tier) ? 'primary' : 'basic'"
            data-test="unique-price"
            @click="updateSelectedTier(tier)"
          >
            {{ `${convertToEuros(tier.price)} €` }}
          </ha-button>
        </template>
        <template v-if="selectedTierType === 'monthly'">
          <ha-button
            v-for="tier in monthlyTiers"
            :key="`monthly_${tier.id}`"
            :variant="isTierActive(tier) ? 'fill' : 'outline'"
            :color="isTierActive(tier) ? 'primary' : 'basic'"
            data-test="monthly-price"
            @click="updateSelectedTier(tier)"
          >
            {{ `${convertToEuros(tier.price)} €` }}
          </ha-button>
        </template>
      </div>

      <div
        v-if="selectedTier && selectedTier.description"
        class="SelectDonation--Description"
        data-testid="donation-description"
      >
        {{ selectedTier.description }}
      </div>

      <validation-provider
        v-if="isAcceptOpenDonation"
        v-slot="{ errors, validated, valid }"
        ref="companyStatus"
        name="company-status"
        :rules="`MIN_AMOUNT:0.5|MAX_AMOUNT:99999.99`"
        slim
      >
        <ha-form-field class="SelectDonation--CustomTier" :is-valid="validated ? valid : null">
          <template #label> {{ $t('campaign.customTierPrice') }} </template>
          <HaInput
            v-model="customTierPrice"
            :is-valid="validated ? valid : null"
            placeholder="--,--"
            data-testid="input-price-custom"
            @keydown="allowOnlyNumericEntry"
            @input="cleanCustomTierPrice"
            @focus="onFirstClick('First Interaction Campaign', { step: 0, step_name: 'Don' })"
          />
          <span>€</span>
          <template #error>{{ errors[0] }}</template>
        </ha-form-field>
      </validation-provider>

      <FiscalReductionHighlight v-if="fiscalEligibility" :price="price" :is-company="isCompany" />

      <SelectDonationPaymentDate
        v-if="selectedTierType === 'monthly'"
        @update-payment-day="value => $emit('update-payment-day', value)"
      />
    </div>
  </div>
</template>

<script>
import { groupBy, cloneDeep } from 'lodash-es'
import { ValidationProvider } from 'vee-validate'
import { HaButton, HaInputGroup, HaInput, HaFormField } from '@ha/components'
import { functions } from '@ha/helpers'
import { cleanInputStringNumber } from '@/helpers/formUtils'
import FiscalReductionHighlight from '@/components/globals/price/FiscalReductionHighlight.vue'
import SelectDonationPaymentDate from '@/components/forms/donations/partials/SelectDonationPaymentDate.vue'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import useStoreData from '@/composables/useStoreData'
import useTracking from '@/composables/useTracking'

export default {
  name: 'SelectDonation',
  components: {
    HaButton,
    HaInputGroup,
    HaInput,
    HaFormField,
    ValidationProvider,
    FiscalReductionHighlight,
    SelectDonationPaymentDate
  },
  props: {
    fiscalEligibility: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { personalInformations } = usePayerPersonalInformations()
    const { form, organization } = useStoreData()
    const { trackingMethods } = useTracking()
    const { onFirstClick } = trackingMethods
    return { personalInformations, form, onFirstClick, organization }
  },
  data() {
    return {
      selectedTier: null,
      customTierPrice: null,
      selectedTierType: 'ponctual'
    }
  },
  computed: {
    price() {
      return this.selectedTier?.price || 0
    },
    groupedTiers() {
      return groupBy(
        this.tiers?.filter(item => typeof item.price !== 'undefined') || [],
        'tierType'
      )
    },
    isAcceptOpenDonation() {
      const enableMonthlyOpenDonation =
        !!this.monthlyTierCustom && this.selectedTierType === 'monthly'
      const enablePonctualOpenDonation =
        !!this.ponctualTierCustom && this.selectedTierType === 'ponctual'
      return (
        this.form.acceptOpenDonation && (enableMonthlyOpenDonation || enablePonctualOpenDonation)
      )
    },
    monthlyTiers() {
      return this.groupedTiers.MonthlyDonation
    },
    ponctualTiers() {
      return this.groupedTiers.Donation
    },
    hasMonthlyPayments() {
      return !!(this.monthlyTiers || this.isAcceptOpenDonation)
    },
    hasPonctualPayments() {
      return !!(this.ponctualTiers || this.isAcceptOpenDonation)
    },
    hasOneTierPayment() {
      return (
        (this.hasMonthlyPayments && !this.hasPonctualPayments) ||
        (this.hasPonctualPayments && !this.hasMonthlyPayments)
      )
    },
    hasOneTierPaymentLabel() {
      return this.hasMonthlyPayments
        ? this.$t('campaign.IGiveMonthly')
        : this.$t('campaign.IGiveOneTime')
    },
    tiers() {
      // break the ref or vuex gonna cry
      return cloneDeep(this.form?.tiers)
    },
    monthlyTierCustom() {
      return (
        this.tiers?.find(
          item => item.tierType === 'MonthlyDonation' && !item.price && item.minAmount
        ) || null
      )
    },
    ponctualTierCustom() {
      return (
        this.tiers?.find(item => item.tierType === 'Donation' && !item.price && item.minAmount) ||
        null
      )
    },
    isCompany() {
      return this.personalInformations?.isCompany || false
    }
  },
  watch: {
    customTierPrice(value) {
      if (value !== null) {
        const customTier =
          this.selectedTierType === 'monthly' ? this.monthlyTierCustom : this.ponctualTierCustom
        const selectedTier = {
          ...customTier,
          price: !value ? 0 : functions.convertToCents(value),
          isCustom: true
        }
        this.selectedTier = selectedTier
        this.$emit('update-selected-tier', selectedTier)
      }
    }
  },
  created() {
    this.selectedTierType =
      this.hasMonthlyPayments && !this.hasPonctualPayments ? 'monthly' : 'ponctual'
  },
  methods: {
    changeTierType(type) {
      this.selectedTierType = type
      this.updateSelectedTier(null)
    },
    updateSelectedTier(tier) {
      this.onFirstClick('First Interaction Campaign', { step: 0, step_name: 'Don' })
      this.selectedTier = tier
      this.customTierPrice = null
      this.$emit('update-selected-tier', tier)
    },
    isTierActive(tier) {
      return this.selectedTier?.id === tier.id
    },
    convertToEuros(price) {
      return functions.formatThousands(functions.convertToEuros(price))
    },
    allowOnlyNumericEntry(event) {
      functions.allowOnlyNumericEntry(event, { allowDecimal: true })
    },
    cleanCustomTierPrice(value) {
      this.customTierPrice = cleanInputStringNumber(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.SelectDonation {
  &--TierType {
    .HaButton {
      width: 50%;
      margin-bottom: $ha-spacing-big;
      padding: $ha-spacing-tiny;
    }
  }

  &--OneTier {
    margin-bottom: $ha-spacing-medium;
    color: var(--ha-color-text);
    text-align: center;
    cursor: default;
  }

  &--Description {
    margin-top: $ha-spacing-medium;
    padding: $ha-spacing-medium;
    text-align: left;
    background: var(--ha-color-background);
    border-radius: $ha-radius-large;
  }

  &--Tiers {
    display: inline-flex;
    flex-wrap: wrap;
    gap: $ha-spacing-small;
    justify-content: center;
    width: 100%;

    .HaButton {
      padding: $ha-spacing-medium $ha-spacing-medium;
      font-weight: $ha-weight-semibold;
      white-space: nowrap;
    }
  }

  &--CustomTier {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $ha-spacing-medium;

    :deep(.Input--Wrapper) {
      width: $ha-unit * 16; // 128px
      margin-right: $ha-spacing-medium;
      margin-left: $ha-spacing-medium;
    }

    :deep(.Input) {
      font-weight: $ha-weight-bold;
      text-align: center;
    }

    :deep(.HaFormField--Label) {
      margin-bottom: 0;

      .Label {
        color: var(--ha-color-text);
        font-weight: $ha-font-weight-regular;
      }
    }

    :deep(.HaFormField--Field) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
