<template>
  <div class="switch-lang">
    <a :href="basePath" class="switch-lang__item" data-testid="switch-lang-french">
      <img src="@/assets/images/pictos/fr.png" alt="Français" loading="lazy" />
    </a>
    <a :href="basePath + '/en'" class="switch-lang__item" data-testid="switch-lang-english">
      <img src="@/assets/images/pictos/en.png" alt="English" loading="lazy" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'LangSwitch',
  inject: ['isWidget'],
  computed: {
    basePath() {
      const { organizationSlug, formType, campaignSlug } = this.$route.params
      return this.isWidget
        ? `/associations/${organizationSlug}/${formType}/${campaignSlug}/widget`
        : `/associations/${organizationSlug}/${formType}/${campaignSlug}`
    },
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    }
  }
}
</script>

<style lang="scss">
.switch-lang {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 calc($ha-spacing-small / 2 * -1);

  &__item {
    display: block;
    width: $ha-unit * 3.75;
    height: $ha-unit * 2.5;
    margin: 0 calc($ha-spacing-small / 2);

    @include mediaQuery(900) {
      width: $ha-unit * 5;
      height: $ha-unit * 3.5;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
}
</style>
