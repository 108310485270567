import { haMoment } from '@ha/helpers'

/**
 * Difference in months between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @returns {Date}
 */
export function differenceInMonths(date1, date2) {
  const result = date1.diff(date2, 'months', true)
  return result || 0
}

/**
 * get terms between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @param {Number} amount amount of the term
 * @param {Number} termsDay day of the term
 * @param {String} validityType validity type of the form
 * @returns {Array} array of terms
 */
export function getTermsBetweenTwoDates(startDate, endDate, amount, termsDay, validityType) {
  const terms = []
  const currentMonth = startDate.month()
  const currentYear = startDate.year()

  let nbTerms = 10

  if (validityType === 'Custom') {
    const dayTermsEndDate = new Date(endDate)

    if (endDate.isSameOrBefore(haMoment(dayTermsEndDate).day(termsDay).startOf('day'), 'day')) {
      endDate = endDate.day(1).subtract(1, 'day')
    }
    nbTerms = Math.ceil(differenceInMonths(endDate, startDate.day(termsDay).startOf('day')))
    // show 12 terms max
    nbTerms = Math.min(nbTerms, 12)
  }

  for (let i = 0; i < nbTerms; i++) {
    terms.push({
      Amount: amount,
      Date: haMoment().year(currentYear).month(currentMonth).date(termsDay).add(i, 'months')
    })
  }

  return terms
}

/**
 * Get payment frequence from tier and form
 * @param {Object} tier
 * @param {Object} form
 * @returns {Number}
 */
export const getPaymentFrequency = (tier, form) => {
  if (tier?.paymentFrequency === 'Monthly') {
    const startDate = form?.startDate
    const endDate = form?.endDate

    switch (form?.validityType) {
      case 'MovingYear':
        return Math.ceil(
          haMoment(startDate).add(1, 'year').diff(haMoment(startDate), 'months', true)
        )
      case 'Custom':
        // eslint-disable-next-line no-case-declarations
        const date = haMoment().isAfter(startDate) ? haMoment() : haMoment(startDate)
        return getTermsBetweenTwoDates(
          date.startOf('day'),
          haMoment(endDate).startOf('day'),
          tier.terms[0].amount,
          haMoment(tier.terms?.[0]?.date).format('DD'),
          form?.validityType
        ).length

      default:
        return 10
    }
  }
  if (tier?.paymentFrequency === 'Installment') {
    return 3
  }
  return 1
}
