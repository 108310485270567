import { render, staticRenderFns } from "./DiscountCodeField.vue?vue&type=template&id=3ae66a9c&scoped=true"
import script from "./DiscountCodeField.vue?vue&type=script&setup=true&lang=js"
export * from "./DiscountCodeField.vue?vue&type=script&setup=true&lang=js"
import style0 from "./DiscountCodeField.vue?vue&type=style&index=0&id=3ae66a9c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ae66a9c",
  null
  
)

export default component.exports