<template>
  <div v-if="showContributors" class="Contributor Card">
    <h3>{{ $t('contributors.title') }}</h3>
    <div class="Contributor--Background">
      <div
        v-for="(contributor, index) in contributors"
        :key="index"
        class="Contributor--Contributor"
      >
        <div class="Contributor--Contributor--Header">
          <figure class="Avatar Avatar-Empty">
            <figcaption>
              {{ getNameFirstChar(contributor) }}
            </figcaption>
          </figure>
          <div class="ContributorHeader">
            <span>
              {{ getNameCensored(contributor) }}
            </span>
            <span>
              {{ $t('contributors.date', [formatDate(contributor.date)]) }}
            </span>
          </div>
        </div>
        <div
          v-if="contributor.comment"
          class="Contributor--Contributor--Comment"
        >
          {{ contributor.comment }}
        </div>
      </div>
    </div>
    <ha-button
      v-if="!noMoreResult"
      class="ButtonMoreComment"
      @click="fetchContributors()"
    >
      {{ $t('contributors.moreButton') }}
    </ha-button>
  </div>
</template>

<script>
import { HaButton } from '@ha/components'
import { haMoment } from '@ha/helpers'
import { UrlToApiTypes } from '@/helpers/enums'
import { enum as enums } from '@ha/helpers'

export default {
  name: 'ContributorBlock',
  components: {
    HaButton
  },
  data() {
    return {
      displayedContributors: [],
      pageSize: 8,
      continuationToken: null,
      noMoreResult: false
    }
  },
  computed: {
    showContributors() {
      return this.contributors.length && this.form.displayContributorName
    },
    contributors() {
      return this.$store.state.contributors?.contributors || []
    },
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    }
  },
  async mounted() {
    if (
      enums.FormType.CROWDFUNDING.toUpperCase() ===
      UrlToApiTypes[this.$route.params.formType]
    ) {
      await this.fetchContributors()
    }
  },
  methods: {
    async fetchContributors() {
      const result = await this.$store.dispatch(
        'contributors/fetchContributors',
        { ...this.metadata, continuationToken: this.continuationToken }
      )
      this.continuationToken = result.pagination?.continuationToken
      this.noMoreResult = result.pagination?.totalPages === 1
    },
    getNameFirstChar(contributor) {
      return contributor.isAnonymous || !contributor.firstName
        ? 'X'
        : contributor.firstName[0].toUpperCase()
    },
    getNameCensored(contributor) {
      if (
        contributor.isAnonymous ||
        !(contributor.firstName && contributor.lastName)
      ) {
        return 'Anonyme'
      }

      return `${
        contributor.firstName
      }.${contributor.lastName[0]?.toUpperCase()}`
    },
    formatDate(date) {
      return haMoment(date).format('DD/MM/YYYY')
    }
  }
}
</script>

<style lang="scss">
.Contributor {
  font-size: $ha-font-size-small;

  &--Background {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &--Contributor {
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: $ha-spacing-medium $ha-spacing-tiny;
    column-gap: $ha-spacing-tiny;
    row-gap: $ha-spacing-tiny;

    &--Header {
      display: flex;
      align-items: center;

      .ContributorHeader {
        display: flex;
        flex-direction: column;

        span:first-child {
          font-weight: $ha-font-weight-bold;
        }
      }
    }

    &--Comment {
      padding-left: ($ha-unit * 5 + $ha-spacing-tiny);
    }
  }

  .ButtonMoreComment {
    margin-top: $ha-spacing-medium;
  }
}

.Avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $ha-unit * 5; // 24px
  min-width: $ha-unit * 5; // 24px
  height: $ha-unit * 5; // 24px
  margin-right: $ha-spacing-small;
  overflow: hidden;
  font-weight: $ha-font-weight-semibold;
  font-size: $ha-font-size-small;
  text-align: center;
  background-color: var(--ha-color-background);
  border-radius: $ha-radius-round;

  &.Avatar-Empty {
    color: var(--ha-color-white);
    background-color: var(--ha-color-primary);
  }

  &--Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--Name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
