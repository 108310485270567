<script setup>
import { computed } from 'vue'
import { HaButton, HaInputGroup } from '@ha/components'
import Card from '@/components/globals/cards/Card.vue'
import FiscalReductionHighlight from '@/components/globals/price/FiscalReductionHighlight.vue'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import useStoreData from '@/composables/useStoreData'

defineProps({
  selectedTier: {
    type: Object
  }
})

const { organization, form } = useStoreData()
const { personalInformations, updatePersonalInformations, allowOnlyOrganism, allowOnlyIndividual } =
  usePayerPersonalInformations()

const organizationName = computed(() => organization.value?.name || '')
const isCompany = computed(() => personalInformations.value?.isCompany || false)
const fiscalRulesMessage = computed(() =>
  isCompany.value
    ? form.value?.taxSystem?.organization?.description
    : form.value?.taxSystem?.personal?.description
)
</script>

<template>
  <Card
    class="FiscalReductionSection"
    :data-ruban="$t('campaign.fiscalReductionRuban')"
    data-testid="fiscal-reduction-block"
  >
    <template #title>
      {{ $t('campaign.fiscalReduction') }}
    </template>
    <template #content>
      <p v-html="$t('campaign.fiscalReductionDescription', [organizationName])" />
      <FiscalReductionHighlight :price="selectedTier.price" :is-company="isCompany" />
      <HaInputGroup class="FiscalReductionSection--IsCompany">
        <HaButton
          v-if="!allowOnlyOrganism"
          :variant="!isCompany ? 'fill' : 'outline'"
          :color="!isCompany ? 'primary' : 'basic'"
          data-testid="button-individual"
          @click="updatePersonalInformations({ isCompany: false })"
        >
          {{ $t('campaign.personalInformationsIsPerson') }}
        </HaButton>
        <HaButton
          v-if="!allowOnlyIndividual"
          :variant="isCompany ? 'fill' : 'outline'"
          :color="isCompany ? 'primary' : 'basic'"
          data-testid="button-company"
          @click="updatePersonalInformations({ isCompany: true })"
        >
          {{ $t('campaign.personalInformationsIsCompany') }}
        </HaButton>
      </HaInputGroup>
      <p v-if="fiscalRulesMessage" class="FiscalReductionSection--Rules">
        {{ fiscalRulesMessage }}
      </p>
    </template>
  </Card>
</template>

<style lang="scss">
.FiscalReductionSection {
  position: relative;

  &::after {
    position: absolute;
    top: 1.25 * $ha-unit; // 10px
    right: 0;
    display: block;
    padding: calc(0.75 * $ha-unit) $ha-unit; // 6px 8px
    color: var(--ha-color-white);
    font-size: $ha-unit;
    line-height: 1;
    text-align: left;
    text-transform: uppercase;
    background: #f28f48;
    transform: rotate(-90deg);
    content: attr(data-ruban);
  }

  &::before {
    position: absolute;
    top: 5.75 * $ha-unit; // 46px
    right: 2.0625 * $ha-unit; // 16.5px
    width: 0;
    height: 0;
    border-top: $ha-unit solid #f28f48; // 8px
    border-right: calc(1.25 * $ha-unit) solid transparent; // 10px
    border-left: calc(1.25 * $ha-unit) solid transparent; // 10 px
    content: '';
  }

  &--Rules {
    margin: 0;
    padding: $ha-spacing-large;
    color: var(--ha-color-text-light);
    text-align: left;
    background: var(--ha-color-background);
    border-radius: $ha-radius-large;
  }

  .RFHighlight {
    margin: 0;
  }

  &--IsCompany {
    margin-top: $ha-spacing-large;
    margin-bottom: $ha-spacing-large;

    .HaButton {
      width: 100%;
      padding: $ha-spacing-tiny;
    }
  }
}
</style>
