<template>
  <section class="Card CardSocials">
    <p class="CardSocials--Title">
      {{ $t(`card.socials.title.${campaignType(form.formType)}`) }}
    </p>
    <ShareLinks with-url-copy />
  </section>
</template>

<script>
import { enum as enums } from '@ha/helpers'
import ShareLinks from '@/components/globals/share/ShareLinks.vue'

export default {
  /**
   * TODO: add share buttons to the lib (same as the back-office)
   */
  name: 'CardSocials',
  components: {
    ShareLinks
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    formTypes() {
      return enums.FormType
    }
  },
  methods: {
    campaignType(type) {
      switch (type) {
        case this.formTypes.CROWDFUNDING:
          return 'collect'
        case this.formTypes.EVENT:
          return 'event'
        case this.formTypes.MEMBERSHIP:
          return 'campaign'
        case this.formTypes.SHOP:
          return 'shop'
        default:
          return 'page'
      }
    }
  }
}
</script>

<style lang="scss">
.CardSocials {
  &--Title {
    margin: 0;
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;
  }
}
</style>
