<template>
    <HaModal
      class="feedback-form-modal"
      :show-close-button="false"
    >
    <template #header>
      <button class="arrowButton" type="button" @click="onArrowClick">
        <fa :icon="['far', 'arrow-left']" />
        <span class="visually-hidden">{{ $t('modal.feedbackForm.spanArrow')}}</span>
      </button>
    </template>
      <template #body>
        <h3>{{ $t('modal.feedbackForm.title') }}</h3>
        <p>{{ $t('modal.feedbackForm.subtitle') }}</p>
        <form @submit.prevent="onSend">
          <ha-radio
            v-for="(option, optionIndex) in options"
            :key="optionIndex"
            v-model="selectedOption"
            :required="!isCustom"
            :value="optionIndex"
            name="feedback"
            @change="onChange"
          >
            {{ option }}
          </ha-radio>
          <ha-radio
            v-model="isCustom"
            :value="true"
            :required="selectedOption === null"
            name="feedback-custom"
            @change="onChangeCustom"
          >
          {{ optionCustom }}
          </ha-radio>
          <ha-text-area
              id="id"
              v-if="isCustom"
              v-model="customFeedback"
              :aria-label="$t('modal.feedbackForm.placeholder')"
              name="textarea"
              :placeholder="$t('modal.feedbackForm.placeholder')"
              :max-length="300"
            />

          <HaButton type="submit" fluid icon-side="right" class="submit-button">
            {{ $t('modal.feedbackForm.buttonLabel') }}
            <template #icon>
              <fa :icon="['far', 'arrow-right']" />
            </template>
          </HaButton>
        </form>
      </template>
    </HaModal>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { HaButton, HaModal, HaRadio, HaTextArea } from '@ha/components'

const emit = defineEmits(['send', 'go-back'])

const { i18n } = useContext()

const options = computed(() => i18n.t('modal.feedbackForm.options'))
const optionCustom = computed(() => i18n.t('modal.feedbackForm.optionCustom'))
const selectedOption = ref(null)
const isCustom = ref(false)
const customFeedback = ref()

const onArrowClick = () => {
  emit("go-back")
}

const onChange = () => {
  isCustom.value = false
}

const onChangeCustom = () => {
  selectedOption.value = null
}

const onSend = () => {
  const feedback = {
    hasGivenFeedback: true,
    feedback: options.value[selectedOption.value] ?? optionCustom.value,
    customFeedback: isCustom.value ? customFeedback.value : ''
  }
  emit("send", feedback)
}

</script>

<style lang="scss" scoped>
.feedback-form-modal {
  :deep(.HaModal--Content){

    @include mediaQuery(600, "max"){
      /* stylelint-disable */
      height: calc(100dvh - var(--header-height));
      /* stylelint-enable */

      @supports not (height: 1dvh) {
        height: calc(100vh - var(--header-height));
    }
    }
  }

  form {
    @include mediaQuery(600, "max"){
      display: flex;
      flex-direction: column;
      height: calc(100% - var(--header-height) * 2);
    }
  }

  :deep(.HaModal--Body){
    height: 100%;
  }

  :deep(.HaModal--Footer){
    display: none;
  }

  .arrowButton:hover {
    cursor: pointer;
  }

  h3 {
    margin-bottom: $ha-spacing-small;
    font-size: $ha-font-size-large;
  }

  p {
    font-size: $ha-font-size-small;
  }

  :deep(.HaRadio) {
    margin-top: $ha-spacing-medium;

    &:last-of-type{
      margin-bottom: $ha-spacing-large;
    }
  }

  &:not(:has(input[type='radio']:checked)) {
    .submit-button {
      color: var(--ha-color-text-disabled);
      background-color: var(--ha-color-background-disabled);
      cursor: not-allowed;
    }
  }

  .submit-button {
    margin-top: auto;
  }

  &:has(input[type='radio']:checked) {
    .HaError {
      display: none;
    }
  }

  :deep(.HaTextArea) {
    margin-block: $ha-spacing-small;

    ::placeholder, .HaTextArea--Counter {
      color: var(--ha-color-text);
      opacity: .8;
    }
  }
}
</style>
