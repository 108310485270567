var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.HaTile,[(Object.keys(_setup.modelsByTierId).length)?_c('div',{staticClass:"participant-step",attrs:{"data-test":"selected-price"}},[_vm._l((_setup.modelsByTierId),function(cartItemList,tierId,tierAggregationIndex){return _c('div',{key:tierId},[(_setup.getTierName(tierId))?_c('h3',[_vm._v("\n        "+_vm._s(!_setup.isShop && !_setup.isCrowdfunding ? '' : cartItemList.length + 'x')+"\n        "+_vm._s(_setup.getTierName(tierId))+"\n      ")]):_vm._e(),_vm._v(" "),(tierId !== 'undefined')?_vm._l((cartItemList),function({ participantLocalId, ...cartItem },index){return _c('div',{key:index,class:{
            'participant-step__tier': participantLocalId || cartItem.extraOptions?.length
          },attrs:{"data-test":"block-participant"}},[(!_setup.isShop && !_setup.isCrowdfunding)?_c('h4',{staticClass:"participant-step__tier__title"},[_c('fa',{attrs:{"icon":"user"}}),_vm._v("\n            "+_vm._s(_vm.$t(`campaign.step.participants.type.${_setup.form.formType.toLowerCase()}.participant`))+"\n            "+_vm._s(index + 1)+"\n          ")],1):_vm._e(),_vm._v(" "),_c('div',{class:[
              'participant-step__tier__field-container',
              {
                'participant-step__tier__field-container--flex': cartItem.extraOptions?.length
              }
            ],attrs:{"data-test":"block-tarif"}},[(participantLocalId)?_c(_setup.ParticipantInformation,{attrs:{"has-mandatory-information":!_setup.isShop && !_setup.isCrowdfunding,"participant-local-id":participantLocalId,"display-copy-info-btn":_setup.displayCopyInfoBtn(tierAggregationIndex, index)}}):_vm._e(),_vm._v(" "),(cartItem.extraOptions?.length)?_c(_setup.HaTile,{attrs:{"data-test":"block-option"}},[_c('h5',[_vm._v("\n                "+_vm._s(_vm.$t('campaign.step.participants.extraOptions'))+"\n              ")]),_vm._v(" "),_c(_setup.ExtraOptions,{attrs:{"cart-id":_setup.cart.id,"cart-item":cartItem,"index":index}})],1):_vm._e()],1)])}):_vm._e()],2)}),_vm._v(" "),(_setup.hasOneItemRequired)?_c('span',{staticClass:"annotation"},[_vm._v("\n      "+_vm._s(_vm.$t('campaign.requiredFields'))+"\n    ")]):_vm._e()],2):_c('div',{staticClass:"participant-step-loading"},[_vm._v("Chargement des participants")])])
}
var staticRenderFns = []

export { render, staticRenderFns }