<script setup>
import { computed } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { HaButton } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import useTracking from '@/composables/useTracking'
import useModalities from '@/composables/useModalities'

const props = defineProps({
  horizontal: { type: Boolean },
  centerText: { type: Boolean }
})
const { i18n } = useContext()
const { trackingMethods } = useTracking()
const { onFirstClick } = trackingMethods
const { organization } = useStoreData()
const { isPaymentTermsEnabled } = useModalities()


const wrapperClass = computed(() => [
  'ContributionNotice',
  {
    'ContributionNotice-Flex': props.horizontal,
    'ContributionNotice-CenterText': props.centerText
  }
])

const trackFirstClick = () => {
  onFirstClick('First Interaction Campaign', {
    step: 0,
    step_name: 'CGU'
  })
}

const text_1 = computed(() => i18n.t('contributionNotice.text_1'))

const text_2 = computed(() => i18n.t('contributionNotice.text_2'))

</script>

<template>
  <div :class="wrapperClass">
    <span class="ContributionNotice--Icon">
      <fa icon="heart" aria-hidden="true" />
    </span>
    <p>
      <span v-if="isPaymentTermsEnabled">*</span><span v-html="text_1"></span>
      <HaButton
        :href="`/associations/${organization.organizationSlug}`"
        target="_blank"
        rel="noopener noreferrer"
        variant="link"
        :data-ux="`Forms_${metadata.type}_ContributionNotice_OrganismPage`"
        data-testid="link-asso-name"
        @click="trackFirstClick"
      >
        {{ organization.name }}
      </HaButton><span v-html="text_2"></span><!-- Do not put span under HaButton bc it will create a space in the sentence -->
    </p>
  </div>
</template>

<style lang="scss" scoped>
.ContributionNotice {
  p {
    margin: 0;
    color: var(--ha-color-text);
    font-size: $ha-font-size-small;
  }

  &--Icon {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: $ha-unit * 4; // 32px
    height: $ha-unit * 4; // 32px
    margin: auto;
    margin-bottom: $ha-spacing;
    color: var(--ha-color-white);
    background-color: var(--ha-color-primary);
    border-radius: 100%;
  }

  &-CenterText {
    text-align: center;
  }

  &-Flex {
    @include mediaQuery(600) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;

      .ContributionNotice--Icon {
        margin: 0;
      }

      p {
        margin-left: $ha-spacing-medium;
      }
    }
  }
}
</style>
