<script setup>
import { inject, computed, onBeforeMount } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { HaButton } from '@ha/components'
import { functions } from '@ha/helpers'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import useRouteParams from '@/composables/useRouteParams'
import useTracking from '@/composables/useTracking'
import useModalities from '@/composables/useModalities'
import useAbTasty from '~/composables/useAbTasty'

const props = defineProps({
  cart: { type: Object, required: true },
  realAmount: { type: Number, default: 0 }
})
const emit = defineEmits(['open-explication-modal', 'open-contribution-modal'])

const isWidget = inject('isWidget')

const { i18n } = useContext()

const { storeRouteParams } = useRouteParams()
const { trackingMethods, trackingTrack } = useTracking()
const { tipSelectedInEuros, tipSelected, tipSuggested } = useVoluntaryContribution()
const { initAbTasty, isCampaignExecuted, variationId, campaignId } = useAbTasty()

const { modalitiesStatus } = useModalities()

onBeforeMount(() => {
  initAbTasty('abcdTestFourStepCV')
})

const formattedTip = computed(() => `${tipSelectedInEuros.value} €`)
const paymentsNumber = computed(
  () => props.cart.paymentTerms.filter(item => item.realAmount).length
)
const hasScheduledPayments = computed(() => {
  // modalitiesStatus condition is here to exclude this feature
  // scheduledPayments isn't payments modalities (sigh)
  return paymentsNumber.value > 1 && modalitiesStatus.value <= 1
})

const dataUxContributionExplanations = `Forms_${storeRouteParams.value.type}${
  isWidget ? 'Widget' : ''
}_StepSummary_Toggle_ModalContributionExplication`

const dataUxContributionEdition = `Forms_${storeRouteParams.value.type}${
  isWidget ? 'Widget' : ''
}_StepSummary_Toggle_ModalContributionSelection`

const tipTotal = computed(() => {
  return functions.convertToEuros(paymentsNumber.value * tipSelected.value)
})

const contributingToHelloAsso = computed(() =>
  i18n.t('campaign.step.summary.contributingToHelloAsso')
)

const isABTestRunning = computed(() => isCampaignExecuted.value ?? false)

const openContributionModal = () => {
  trackingMethods.onTipsUpdate(
    'Tips Info set',
    {
      tipSuggestion: tipSuggested.value.default,
      tipSuggestionMax: tipSuggested.value.max,
      tipSuggestionMin: tipSuggested.value.min,
      tipChosen: tipSelected.value
    },
    {
      isAbTastyRunning: isABTestRunning.value,
      variationId: variationId?.value,
      campaignId: campaignId?.value
    }
  )
  emit('open-contribution-modal')
}

const openExplanationModal = () => {
  emit('open-explication-modal')
  trackingTrack('Tips Explanation Clicked', {
    isAbTastyRunning: isABTestRunning.value,
    variationId: variationId?.value,
    campaignId: campaignId?.value
  })
}
</script>

<template>
  <div data-testid="consent-user">
    <div class="contribution-line">
      <div class="contribution-line-cell">
        <p class="contribution-line-title">
          <strong>
            {{ contributingToHelloAsso }}
          </strong>
        </p>
        <div>
          <HaButton
            variant="link"
            :data-ux="dataUxContributionExplanations"
            data-testid="button-cv-explication"
            @click="openExplanationModal"
          >
            {{ $t(`campaign.step.summary.whyContribute`) }}
            <span class="visually-hidden">{{ $t('campaign.step.summary.whyContributeHidden') }}</span>
          </HaButton>
          -
          <HaButton
            variant="link"
            :data-ux="dataUxContributionEdition"
            data-testid="button-change-contribution"
            @click="openContributionModal"
          >
            {{ $t('button.update') }}
            <span class="visually-hidden">{{ $t('campaign.step.summary.updateContributionHidden') }}</span>
          </HaButton>
        </div>
      </div>

      <div class="contribution-line-cell contribution-line-cell-total">
        <span class="contribution-line-price">
          {{ formattedTip }}
          <template v-if="hasScheduledPayments">
            <br v-if="$device.isMobile" />
            <span>
              {{ $t('campaign.step.summary.byTerms') }}
            </span>
            <small>
              {{ $t('campaign.step.summary.totalContribution', [tipTotal]) }}
            </small>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contribution-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: $ha-spacing-large;
  padding-bottom: $ha-spacing-large;
  text-align: center;
  border-top: $ha-border-width-large dashed set-alpha(border, 0.5);
  border-bottom: $ha-border-width-large dashed set-alpha(border, 0.5);

  @include mediaQuery(600) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    text-align: left;

    &-cell {
      display: flex;

      &:first-of-type {
        flex-direction: column;
        margin-bottom: 0;
      }

      &:last-of-type {
        flex: 0 0 auto;
        flex-direction: row;
      }
    }
  }

  &-cell {
    display: flex;
    flex-direction: column;

    &:last-of-type {
      white-space: nowrap;
    }

    &-selector {
      margin: $ha-spacing-small 0;
    }

    &-total {
      justify-content: flex-end;
    }
  }

  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    color: var(--ha-color-text);
    text-align: start;

    @include mediaQuery(600, 'max') {
      text-align: center;
    }

    &--Abtest {
      margin-bottom: $ha-spacing-tiny;
      @include mediaQuery(600, 'max') {
        flex-direction: column;
      }
    }
  }

  &-icon {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: $ha-unit * 4; // 32px
    height: $ha-unit * 4; // 32px
    margin-right: $ha-spacing-small;
    color: var(--ha-color-white);
    background-color: var(--ha-color-primary);
    border-radius: 100%;
  }

  &-explanation {
    margin-bottom: $ha-spacing-tiny;
    color: var(--ha-color-text);
  }

  &-price {
    margin-bottom: $ha-spacing-tiny;
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-large;

    @include mediaQuery(600) {
      margin-bottom: 0;
      margin-left: $ha-spacing-small;
      text-align: right;
    }

    small {
      color: var(--ha-color-text-lightest);
      font-size: $ha-font-size-small;
    }
  }
}
</style>
