import { computed, watch } from 'vue'
import { functions } from '@ha/helpers'
import { useSsrState } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import useCart from '@/composables/useCart'

const customAmountInEuros = useSsrState('customAmountInEuros', () => null)
const dayOfLevy = useSsrState('dayOfLevy', () => Math.min(new Date().getDate(), 28))

export default () => {
  const { form } = useStoreData()
  const { cart, updateCartTiers, createCart, redirectToPayment } = useCart()

  const customAmount = computed(() =>
    customAmountInEuros.value ? functions.convertToCents(customAmountInEuros.value) : null
  )
  const selectedTier = computed(() => {
    const tier = form.value?.tiers?.[0]
    return { ...tier, customAmount: customAmount.value, dayOfLevy: dayOfLevy.value }
  })
  const isFixedPrice = computed(() => !!selectedTier.value.price)
  const isMonthly = computed(() => selectedTier.value.paymentFrequency === 'Monthly')

  const updateCustomAmountInEuros = newCustomAmount => {
    customAmountInEuros.value = newCustomAmount
  }
  const setVente = () => {
    updateCartTiers([selectedTier.value])

    watch(selectedTier, () => {
      updateCartTiers([selectedTier.value])
    })
  }

  return {
    dayOfLevy,
    isMonthly,
    isFixedPrice,
    cart,
    selectedTier,
    customAmount,
    customAmountInEuros,
    createCart,
    redirectToPayment,
    setVente,
    updateCustomAmountInEuros
  }
}
