<template>
  <ha-jumbotron
    direction="horizontal"
    :class="`CampaignState CampaignState-${state}`"
    :title="title"
    :sub-title="subtitle"
  >
    <template #callToAction>
      <ha-button
        :href="administrationUrl"
        target="_blank"
        :color="state === 'Deleted' ? 'danger' : 'primary'"
        variant="outline"
        size="large"
        icon-side="right"
        :data-ux="`Forms_${metadata.type}_CampaignState_GoToCampaignSettings`"
      >
        {{ $t('jumbotron.toSettings') }}
        <template #icon>
          <fa icon="chevron-right" />
        </template>
      </ha-button>
    </template>
  </ha-jumbotron>
</template>

<script>
import { enum as enums } from '@ha/helpers'
import { HaButton, HaJumbotron } from '@ha/components'
import { getFormTypeForUrl } from '@/helpers/formUtils'

export default {
  name: 'CampaignState',
  components: {
    HaButton,
    HaJumbotron
  },
  props: {
    state: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: this.$t('campaign.state.' + this.state.toLowerCase() + '.title'),
      subtitle: this.$t(
        'campaign.state.' + this.state.toLowerCase() + '.subtitle'
      )
    }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    formTypes() {
      return enums.FormType
    },
    administrationUrl() {
      const { organizationSlug, campaignSlug } = this.$route.params

      const isSale = this.form.formType === this.formTypes.PAYMENTFORM
      const formTypeForBO = isSale ? 'ventes' : getFormTypeForUrl(this.form.formType)

      return  `${process.env.NUXT_ENV_BO_URL}/${organizationSlug}/${formTypeForBO}/${campaignSlug}/diffusion`
    }
  },
  methods: {
    buttonState() {
      switch (this.state) {
        case 'Draft':
          return 'primary'
        case 'Disabled':
          return 'info'
        case 'Deleted':
          return 'danger'
        default:
          return 'primary'
      }
    }
  }
}
</script>

<style lang="scss">
.CampaignState {
  margin-top: $ha-spacing-large;

  &-Draft,
  &-Disabled {
    background-color: var(--ha-color-primary);
  }

  &-Deleted {
    background-color: var(--ha-color-danger);
  }
}
</style>
