<script>
export default {
  inheritAttrs: false
}
</script>

<template>
  <div class="contributor_information">
    <label>
      <span aria-hidden="true">email</span>
      <input v-bind="$attrs" v-on="$listeners" autocomplete="off" tabindex="-1" type="text" />
    </label>
  </div>
</template>

<style lang="scss">
.contributor_information {
  position: 'absolute';
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;
}
</style>