<script setup>
import { computed, useSlots } from 'vue'

const props = defineProps({
  title: String,
  icon: String,
  noPadding: Boolean
})
const slot = useSlots()

const displayHeader = computed(() => !!props.title || !!slot?.header)
const displayBody = computed(() => !!slot?.default)

const noPaddingClassName = computed(() =>
  props.noPadding ? 'vente-card__content--no-padding' : ''
)
const noTitleClassName = computed(() => {
  return !props.title && !props.noPadding ? 'vente-card__content--no-title' : ''
})

const separatorClassName = computed(() => {
  return displayHeader.value ? 'vente-card__content--separator' : ''
})

const contentClassName = computed(() => [
  'vente-card__content',
  separatorClassName.value,
  noPaddingClassName.value,
  noTitleClassName.value
])
</script>

<template>
  <div class="vente-card">
    <div class="vente-card__container">
      <div v-if="displayHeader" class="vente-card__title">
        <slot name="header">
          <h3>
            <fa v-if="icon" :icon="icon" aria-hidden="true" />
            {{ title }}
          </h3>
        </slot>
      </div>
      <div v-if="displayBody" :class="contentClassName">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vente-card {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-top: $ha-spacing-big;

  &__container {
    width: 100%;
    overflow: hidden;
    background-color: var(--ha-color-white);
    background-clip: padding-box;
    border-radius: $ha-radius-large;
    box-shadow: $ha-box-shadow-close;
  }

  &__title {
    padding: $ha-spacing-medium;
    color: var(--ha-color-text);
    font-weight: $ha-weight-semibold;
    font-size: $ha-font-size-large;
    text-align: center;

    h3 {
      margin: 0;
    }
  }

  &__content {
    padding: 0 $ha-spacing-large $ha-spacing-large;

    &--no-padding {
      padding: 0;
    }

    &--no-title {
      padding-top: $ha-spacing-large;
    }

    &--separator {
      padding-top: $ha-spacing-medium;
      border-top: 1px solid var(--ha-color-background-disabled);
    }
  }
}
</style>
