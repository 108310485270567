<script setup>
import MandatoryFields from '@/components/participants/partials/MandatoryFields.vue'
import { HaTile } from '@ha/components'
import useParticipantInformation from '@/components/participants/useParticipantInformation'
import { ref, computed, toRefs } from 'vue'
import { useContext, useStore } from '@nuxtjs/composition-api'
import useFormType from '@/composables/useFormType'
import useStoreData from '@/composables/useStoreData'
import CustomField from '@/components/custom-fields/CustomField.vue'

const props = defineProps({
  participantLocalId: { type: [Number, String], required: true },
  displayCopyInfoBtn: { type: Boolean },
  hasMandatoryInformation: { type: Boolean }
})

const {
  state: { tierToUseForPaymentLocalKey },
  methods: {
    getParticipantInformation,
    applyParticipantMandatoryInformationToAllTiers,
    optimisticUploadFile,
    onUseTierInfoForPayment
  }
} = useParticipantInformation()
const store = useStore()
const { isShop, isCrowdfunding } = useFormType()
const { form } = useStoreData()
const { i18n } = useContext()

const isUserConnected = computed(() => !!store.getters['user/getMe']().userId)

const { participantLocalId, displayCopyInfoBtn } = toRefs(props)
const localParticipantInformation = ref(getParticipantInformation(participantLocalId.value))
const applyInfoToAllParticipant = ref(displayCopyInfoBtn.value ? false : null)

const useTierInfoForPayment = computed(() => {
  if (isUserConnected.value) return null
  return props.participantLocalId === tierToUseForPaymentLocalKey.value
})

const title = computed(() => {
  const titleKey =
    isShop.value || isCrowdfunding.value
      ? 'campaign.step.participants.furtherInformations'
      : `campaign.step.participants.type.${form.value?.formType.toLowerCase()}.participantProfile`

  return i18n.t(titleKey)
})

const onMandatoryFieldUpdate = () => {
  if (props.displayCopyInfoBtn && applyInfoToAllParticipant.value) {
    applyParticipantMandatoryInformationToAllTiers()
  }
}
const onApplyInfoToAllParticipants = value => {
  applyInfoToAllParticipant.value = value
  applyParticipantMandatoryInformationToAllTiers()
}
</script>

<script>
export default {
  name: 'ParticipantInformation'
}
</script>

<template>
  <HaTile>
    <h5 v-if="title">
      {{ title }}
    </h5>

    <MandatoryFields
      v-if="props.hasMandatoryInformation"
      :firstname="localParticipantInformation.firstname"
      :lastname="localParticipantInformation.lastname"
      :email="localParticipantInformation.email"
      :apply-info-to-all-participant="applyInfoToAllParticipant"
      :use-tier-info-for-payment="useTierInfoForPayment"
      :test-id="props.participantLocalId"
      @update="onMandatoryFieldUpdate"
      @update:firstname="localParticipantInformation.firstname = $event"
      @update:lastname="localParticipantInformation.lastname = $event"
      @update:email="localParticipantInformation.email = $event"
      @update:apply-info-to-all-participant="onApplyInfoToAllParticipants"
      @update:use-tier-info-for-payment="onUseTierInfoForPayment(props.participantLocalId)"
    />

    <CustomField
      v-for="(customField, index) in localParticipantInformation.customFields"
      :id="`${participantLocalId}-${index}`"
      :key="index"
      :validation="customField.validation"
      :model="customField.model"
      :meta="customField.meta"
      class="custom-field"
      @input="customField.model.value = $event"
      @update:file="optimisticUploadFile(props.participantLocalId, index, $event)"
    />
  </HaTile>
</template>

<style lang="scss" scoped>
.custom-field {
  margin-top: $ha-spacing-medium;
}
</style>
