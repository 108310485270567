import { SelectedTiers, Tier } from '../tiers.interface'

export const getCartQuantity = (selectedTiers: SelectedTiers): number => {
  return selectedTiers
    ? Object.keys(selectedTiers).reduce((total, tierId) => {
        return total + (selectedTiers[tierId]?.quantity ?? 0)
      }, 0)
    : 0
}

export const getTierQuantity = (tier: Tier, selectedTiers: SelectedTiers): number =>
  selectedTiers?.[tier.id as number]?.quantity ?? 0
