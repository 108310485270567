import { computed, ref, watch } from 'vue'
import { CouponStates } from '@/helpers/enums'
import { useStore, useContext } from '@nuxtjs/composition-api'
import useNotifications from '@/composables/useNotifications'
import useRouteParams from '@/composables/useRouteParams'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import useCart from '@/composables/useCart'

export default () => {
  const store = useStore()
  const { i18n } = useContext()
  const { onError } = useNotifications()
  const { storeRouteParams } = useRouteParams()
  const { setTipSuggested } = useVoluntaryContribution()
  const { cartTipData } = useCart()

  const discountCodeState = ref(CouponStates.PRISTINE)
  const discountCodeErrorMessage = ref('')
  const discountCodeModel = ref('')

  const isDiscountCodeValid = computed(() => discountCodeState.value === CouponStates.VALID)
  const isDiscountCodePristine = computed(() => discountCodeState.value === CouponStates.PRISTINE)
  const cart = computed(() => store.getters['carts/getCart'](storeRouteParams.value))

  const cartDiscountCode = computed(() => store.getters['carts/getCartCoupon'](cart.value?.id))

  const handleError = error => {
    discountCodeState.value = CouponStates.INVALID
    if (error.statusCode === 409) {
      discountCodeErrorMessage.value = i18n.t('coupon.invalid')
    } else {
      discountCodeErrorMessage.value = i18n.t(`error.genericError`)
      onError(error, {
        type: 'danger',
        title: i18n.t(`error.code.${error.statusCode}.title`),
        body: error.message,
        timeout: 5000
      })
    }
  }

  const defineStateAndMessage = () => {
    if (!cartDiscountCode.value) {
      discountCodeErrorMessage.value = ''
      discountCodeState.value = CouponStates.PRISTINE
    } else {
      discountCodeErrorMessage.value = i18n.t('coupon.valid')
      discountCodeState.value = CouponStates.VALID
    }
    store.commit('carts/SET_TIP', [cart.value?.tipData?.tipSuggestion, storeRouteParams.value])
  }

  const submitDiscountCode = async () => {
    discountCodeState.value = CouponStates.PRISTINE
    try {
      await store.dispatch('carts/applyCoupon', [
        discountCodeModel.value,
        storeRouteParams.value,
        cart.value.id
      ])
      discountCodeState.value = CouponStates.VALID
    } catch (error) {
      handleError(error)
    }
  }

  const deleteDiscountCode = async () => {
    try {
      await store.dispatch('carts/removeCoupon', [storeRouteParams.value, cart.value.id])
      discountCodeState.value = CouponStates.PRISTINE
    } catch (error) {
      handleError(error)
    }
  }

  watch(
    cartDiscountCode,
    () => {
      discountCodeModel.value = cartDiscountCode.value
      defineStateAndMessage()

      // After adding discount code, cart is updated and also tip suggestions
      setTipSuggested(
        {
          min: cartTipData.value.min,
          max: cartTipData.value.max,
          default: cartTipData.value.default
        },
        cartTipData.value.selected == null
      )
    },
    { immediate: true }
  )

  return {
    discountCodeModel,
    isDiscountCodeValid,
    isDiscountCodePristine,
    discountCodeErrorMessage,
    submitDiscountCode,
    deleteDiscountCode
  }
}
