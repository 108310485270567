<script setup>
import useCartSummary from '@/components/carts/summary/useCartSummary'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import VoluntaryContribution from '@/components/voluntary-contributions/VoluntaryContribution.vue'

const { tipSelectedInEuros } = useVoluntaryContribution()
const { tiersAmount } = useCartSummary()
</script>

<template>
  <div class="cart-summary-contribution">
    <p class="cart-summary-contribution__item">
      <span>{{ $t('campaign.step.summary.contributingToHelloAsso') }}</span>
      <span class="cart-summary-contribution__item__price">
        {{ $t('campaign.currencyUnit', [tipSelectedInEuros]) }}
      </span>
    </p>
    <VoluntaryContribution :tiers-amount="tiersAmount" />
  </div>
</template>

<style lang="scss">
.cart-summary-contribution {
  margin: 0 $ha-spacing-large;
  padding: $ha-spacing-medium 0 $ha-spacing-large;

  &__item {
    display: flex;
    flex-flow: row nowrap;
    gap: $ha-spacing;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    color: var(--ha-color-text);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-large;

    &__price {
      color: var(--ha-color-text-light);
      white-space: nowrap;
    }
  }
}
</style>
