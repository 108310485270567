<template>
  <fieldset v-if="allowOptionalDonation && isCollectAllowed" class="OptionalDonation">
    <legend class="OptionalDonation--Title">
      {{ $t(`optionalDonation.${form.formType}.addDonation_1`) }}
      <ha-button
        v-if="organizationSlug"
        :href="`/associations/${organizationSlug}`"
        target="_blank"
        rel="noopener noreferrer"
        variant="link"
        :data-ux="`Forms_${metadata.type}_OptionalDonation_OrgLink`"
      >
        {{ organizationName }}
      </ha-button>
      <span v-if="!organizationSlug">
        {{ organizationName }}
      </span>
      {{ $t(`optionalDonation.${form.formType}.addDonation_2`) }}
    </legend>
    <div class="OptionalDonation--Controls">
      <ha-radio
        v-for="(tier, tierIndex) in optionalDonationTiers"
        :key="tierIndex"
        v-model="price"
        class="OptionalDonation--ControlItem"
        name="donation"
        :value="tier.price"
        :disabled="isFetching"
        :data-testid="tier.dataTestId"
        @change="onDonationChange"
      >
        {{ getDonationTierLabel(tier) }}
      </ha-radio>
      <ha-radio
        v-model="isCustom"
        class="OptionalDonation--ControlCustom"
        name="donation"
        :value="true"
        data-testid="radio-button-custom"
      >
        <ha-input
          id="custom_optionalDonation"
          type="number"
          :value="customPrice"
          :placeholder="$t('optionalDonation.freeDonationPlaceholder')"
          :disabled="!isCustom || isFetching"
          data-testid="custom-donation-amount"
          :aria-label="$t('tiers.selector.freeDonationInput')"
          @input="onCustomDonationChange"
          @keydown="functions.allowOnlyNumericEntry($event, { allowDecimal: true })"
        />
      </ha-radio>
    </div>
  </fieldset>
</template>

<script>
import { HaButton, HaInput, HaRadio } from '@ha/components'
import {
  cleanInputStringNumber
} from '@/helpers/formUtils'
import { functions } from '@ha/helpers'

export default {
  name: 'TiersOptionalDonation',
  components: { HaButton, HaInput, HaRadio },
  props: {
    optionalDonation: {
      validator: v => v === null || v === undefined || (typeof v === 'object' && !Array.isArray(v)) // Refactor - complex type conditioning
    },
    isFetching: Boolean
  },
  data() {
    return {
      customPrice: null,
      isCustom: false,
      price: this.optionalDonation?.customAmount || 0,
      functions
    }
  },
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    compliance() {
      return this.$store.getters['organizations/getOrganizationCompliance'](
        this.metadata.organization
      )
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.organizationSlug)
    },
    allowOptionalDonation() {
      return this.form?.acceptOpenDonation
    },
    isCollectAllowed() {
      return this.compliance?.allowCollect || true
    },
    organizationName() {
      return this.organization?.name
    },
    organizationSlug() {
      return this.metadata.organization
    },
    optionalDonationTiers() {
      const optionalDonationTiers =
        this.form.openDonationPresetAmounts?.map((preset, index) => ({
          price: preset,
          tierType: 'Donation',
          dataTestId: `radio-button-${index + 1}`
        })) || []
      optionalDonationTiers.unshift({
        price: 0,
        tierType: 'Donation',
        dataTestId: 'radio-button-0'
      })
      return optionalDonationTiers
    }
  },
  mounted() {
    this.checkIfIsCustomDonation()
  },
  methods: {
    disableCustom() {
      this.customPrice = null
      this.isCustom = false
    },
    onCustomDonationChange(value) {
      this.customPrice = cleanInputStringNumber(value)
      const customPriceEuros = this.customPrice
        ? functions.convertToCents(parseFloat(this.customPrice))
        : 0
      this.$emit('update-optional-donation', customPriceEuros)
    },
    onDonationChange() {
      this.disableCustom()
      this.$emit('update-optional-donation', this.price)
    },
    getDonationTierLabel(tier) {
      return tier.price
        ? this.$t(`campaign.currencyUnit`, [functions.convertToEuros(tier.price)])
        : this.$t('optionalDonation.noDonation')
    },
    checkIfIsCustomDonation() {
      this.isCustom = this.price && !this.form.openDonationPresetAmounts?.includes(this.price)
      if (this.isCustom) {
        this.customPrice = functions.convertToEuros(this.price)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
fieldset {
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border: none;
  min-inline-size: min-content;

  // trick bc we can't use border-top because <legend> is build-in to go hover fieldset border-top
  &::before {
    position:absolute;
    top: calc(-1em - $ha-spacing-jumbo);
    display:block;
    width: 100%;
    height: $ha-border-width-regular;
    background-color: set-alpha(border, 0.5);
    content: "";
  }
}

.OptionalDonation {
  margin-top: $ha-spacing-jumbo;
  text-align: left;

  @include mediaQuery(900) {
    text-align: right;
  }

  &--Title {
    margin-bottom: 0.75em;
    color: var(--ha-color-text);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-large;

    > .HaButton {
      font-size: $ha-font-size-large;
    }
  }

  &--Controls {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include mediaQuery(900) {
      flex-direction: row;
      align-items: center;
      margin-top: $ha-spacing-medium;
    }
  }

  &--ControlItem,
  &--ControlCustom {
    white-space: nowrap;

    span {
      white-space: nowrap;
    }
  }

  &--ControlItem {
    margin-bottom: $ha-spacing-tiny;

    @include mediaQuery(900) {
      margin-bottom: $ha-spacing-small;
      margin-left: $ha-spacing-big;
    }

    + .OptionalDonation--ControlItem {
      // Dev+ compilation order overrides styles with HaComponents one
      // Waiting to solve this compilation order issue
      margin-top: 0 !important;
    }
  }

  &--ControlCustom {
    width: 100%;
    margin-top: 0;
  }

  :deep(label) {
    font-size: $ha-font-size-small;
  }
}
</style>
