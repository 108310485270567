<script setup>
import useStoreData from '@/composables/useStoreData'
import OrganizationLogo from '@/components/globals/logo/OrganizationLogo.vue'
import { HaButton } from '@ha/components'

const { form, organization } = useStoreData()
</script>

<template>
  <div v-if="organization && form" data-test="vente-header" class="VenteHeader">
    <OrganizationLogo size="small" data-test="vente-header-logo" />
    <ha-button
      class="VenteHeader--OrganizationName"
      :href="`/associations/${metadata.organization}`"
      target="_blank"
      rel="noopener noreferrer"
      variant="link"
      data-test="vente-header-organization-name"
    >
      {{ organization.name }}
    </ha-button>
    <h1 class="VenteHeader--FormTitle" data-test="vente-header-title">{{ form.title }}</h1>
    <div class="VenteHeader--FormDescription" data-test="vente-header-description">
      {{ form.description || '' }}
    </div>
  </div>
</template>

<style lang="scss">
.VenteHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: $ha-spacing-giant;
  color: var(--ha-color-white);
  text-align: center;
  background-color: var(--ha-color-primary);

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    z-index: -1;
    width: 50vw;
    background-color: var(--ha-color-primary);
    transform: translateX(-100%);
    content: '';
  }

  &::after {
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    z-index: -1;
    width: 50vw;
    background-color: var(--ha-color-primary);
    transform: translateX(100%);
    content: '';
  }

  &--FormDescription {
    margin-bottom: $ha-spacing-medium;
  }

  .OrganizationLogo {
    margin: $ha-spacing-large auto;
  }

  &--OrganizationName.HaButton-Link.HaButton-Primary {
    margin-top: 0;
    color: var(--ha-color-white) !important;
    font-weight: $ha-weight-semibold;
    font-size: $ha-font-size-regular;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &--FormTitle {
    margin: $ha-spacing-large 0 $ha-spacing-medium 0;
    font-size: $ha-font-size-giant;
  }
}
</style>
