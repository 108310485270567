<script setup>
import SelectVoluntaryContribution from '@/components/abTests/stepFour/partials/SelectVoluntaryContribution.vue'

const props = defineProps({
  realAmount: { type: Number, default: 0 }
})
const emit = defineEmits(['open-explanation-modal'])

const openExplanationModal = () => {
  emit('open-explanation-modal')
}
</script>

<template>
  <div data-testid="consent-user">
    <p>Votre contribution à HelloAsso <span>(libre et modifiable)</span></p>
    <SelectVoluntaryContribution
      :tiers-amount="realAmount"
      :isIllustrations="false"
      @open-explanation-modal="openExplanationModal"
    ></SelectVoluntaryContribution>
  </div>
</template>

<style lang="scss" scoped>
/* stylelint-disable */
p {
  margin-bottom: 0;
  margin-left: 0;
  font-weight: $ha-font-weight-bold;
  font-size: $ha-font-size-big;
  color: var(--ha-color-midnight);

  span {
    font-weight: $ha-font-weight-regular;
    font-size: $ha-font-size-small;
    display: block;

    @media (min-width: 600px) {
      display: inline;
    }
  }
}
</style>
