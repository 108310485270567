<template>
  <Card class="CardMemo">
    <template #title>
      {{ $t(`card.memo.title`) }}
    </template>
    <template #content>
      <section v-if="itemsQuantity > 0" class="CardMemoItem">
        <div class="CardMemoItem--Label">
          {{
            $tc(`card.memo.type.${form.formType.toLowerCase()}.items`, itemsQuantity, {
              nbTickets: itemsQuantity
            })
          }}
        </div>
        <div class="CardMemoItem--Price">
          {{ itemPrice > 0 ? convertToEuros(itemPrice) : $t('card.memo.free') }}
        </div>
      </section>

      <section v-if="optionsQuantity > 0" class="CardMemoItem">
        <div class="CardMemoItem--Label">
          {{
            $tc('card.memo.options', optionsQuantity, {
              nbOptions: optionsQuantity
            })
          }}
        </div>
        <div class="CardMemoItem--Price">
          {{ optionsPrice > 0 ? convertToEuros(optionsPrice) : $t('card.memo.free') }}
        </div>
      </section>

      <section v-if="donationPrice > 0" class="CardMemoItem">
        <div class="CardMemoItem--Label">
          {{ $t('card.memo.donation') }}
        </div>
        <div class="CardMemoItem--Price">
          {{ convertToEuros(donationPrice) }}
        </div>
      </section>

      <section v-if="discount > 0" class="CardMemoItem">
        <div class="CardMemoItem--Label">
          {{ $t('card.memo.discount') }}
        </div>
        <div class="CardMemoItem--Price">- {{ convertToEuros(discount) }}</div>
      </section>

      <section class="CardMemoTotal">
        {{ $t('card.memo.amount') }}
        <span class="CardMemoTotal--Price">
          {{ convertToEuros(totalPrice) }}
        </span>
      </section>
    </template>
  </Card>
</template>

<script>
import { filter, size, get, forEach, sumBy } from 'lodash-es'
import { enum as enums, functions } from '@ha/helpers'
import { itemType } from '@/helpers/enums'

import Card from '@/components/globals/cards/Card.vue'

export default {
  name: 'CardMemo',
  components: { Card },
  computed: {
    cart() {
      return this.$store.getters['carts/getCart'](this.metadata)
    },
    cartItems() {
      return get(this.cart, 'itemList', [])
    },
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    formTypes() {
      return enums.FormType
    },
    discount() {
      return get(this.cart, 'paymentTerms[0].discount', 0)
    },
    itemsQuantity() {
      return size(this.filterMainTiers())
    },
    itemPrice() {
      let total = 0
      const tickets = this.filterMainTiers()
      forEach(tickets, ticket => {
        // get payment frequency
        // return cart ticket amount, multiplied by payment frequency
        total += get(ticket, 'customAmount', 0)
      })
      return total
    },
    options() {
      const itemsWithOptions = filter(this.cartItems, function (item) {
        return item.extraOptions
      })
      const options = []
      forEach(itemsWithOptions, item => {
        options.push(
          ...filter(item.extraOptions, option => {
            return option.value
          })
        )
      })
      return options
    },
    optionsQuantity() {
      return size(this.options)
    },
    optionsPrice() {
      return sumBy(
        this.options,
        (option) => option.paymentTerms ? option.paymentTerms[0].realAmount : option.price || 0
      )
    },
    donationPrice() {
      if ([this.formTypes.CROWDFUNDING].includes(this.form.formType)) {
        return 0
      }
      const donations = filter(this.cartItems, { tierType: 'Donation' })
      return sumBy(donations, 'customAmount')
    },
    totalPrice() {
      this.$emit('update:total-amount', this.itemPrice + this.optionsPrice + this.donationPrice - this.discount)
      return this.itemPrice + this.optionsPrice + this.donationPrice - this.discount
    }
  },
  methods: {
    convertToEuros(price) {
      return functions.convertToEuros(price) + ' €'
    },
    filterMainTiers() {
      return filter(this.cartItems, {
        tierType: itemType[this.metadata.type]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.CardMemo {
  width: 100%;
  text-align: center;

  @include mediaQuery(900) {
    position: sticky;
    top: 8em;
  }

  &Item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: $ha-spacing-tiny 0;

    + .CardMemoItem {
      border-top: $ha-border-width-regular solid set-alpha(border, 0.5);
    }

    > * {
      width: 50%;
      font-size: $ha-font-size-small;
    }

    &--Label {
      padding-right: $ha-spacing-mini;
      overflow: hidden;
      font-weight: $ha-font-weight-semibold;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
    }

    &--Price {
      padding-left: $ha-spacing-mini;
      white-space: nowrap;
      text-align: right;
    }
  }

  &Total {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    justify-content: flex-end;
    margin-top: $ha-spacing-tiny;
    padding-top: $ha-spacing-medium;
    font-weight: $ha-font-weight-bold;
    border-top: $ha-border-regular;

    &--Price {
      padding-left: $ha-spacing-tiny;
      color: var(--ha-color-primary);
    }
  }
}
</style>
