<template>
  <div class="PriceListItem">
    <h4 v-if="cartItem.firstname" class="PriceListItem--Owner">
      {{ getTicketOwnerName(cartItem) }}
    </h4>
    <div class="PriceListItem--Details">
      <div class="PriceListItem--DetailsName" data-test="selected-price-name">
        <template v-if="tierName">{{ tierName }}</template>
        <template v-else><slot name="tierName" /></template>
      </div>
      <div class="PriceListItem--DetailsPrice" data-test="selected-price-amount">
        {{ getTicketPrice(cartItem) }}
      </div>
    </div>
    <div v-if="cartItem.extraOptions" class="PriceListItem--Options">
      <div
        v-for="(option, o) in cartItem.extraOptions.filter(option => {
          return option.value == true
        })"
        :key="`cart-summary-${index}-option-${o}`"
        class="PriceListItem--Option"
      >
        <div class="PriceListItem--OptionName">{{ $t('campaign.step.summary.option') }} : {{ option.label }}</div>
        <div class="PriceListItem--OptionPrice">
          {{ getOptionPrice(option) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { functions } from '@ha/helpers'
import useModalities from '@/composables/useModalities'

export default {
  name: 'PriceListItem',
  props: {
    cartItem: {
      type: null,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    tierName: {
      type: String,
      default: null
    }
  },
  setup() {
    const { isPaymentTermsEnabled } = useModalities()
    return { isPaymentTermsEnabled }
  },
  computed: {
    cart() {
      return this.$store.getters['carts/getCart'](this.metadata)
    },
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    tierItems() {
      return this.cart?.tierList || []
    }
  },
  methods: {
    getTicketOwnerName(cartItem) {
      return `${cartItem?.firstname || ''} ${cartItem?.lastname || ''}`
    },
    getTicketPrice(cartItem) {
      const paymentFrequency = this.isPaymentTermsEnabled ? 1 : cartItem?.paymentTerms?.length || 1

      return `${functions.convertToEuros((cartItem?.customAmount || 0) * paymentFrequency)} €`
    },
    getOptionPrice(option) {
      const optionPrice = option?.paymentTerms?.[0]?.initialAmount || 0

      return optionPrice !== 0
        ? `${functions.convertToEuros(optionPrice)} €`
        : this.$t('option.free')
    }
  }
}
</script>

<style lang="scss">
.PriceListItem {
  + .PriceListItem {
    padding-top: $ha-spacing-small;
  }

  &--Owner {
    margin-bottom: 0;
    font-weight: $ha-font-weight-semibold;
  }

  &--Details,
  &--Option {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

    &Price {
      flex: 0 0 auto;
      padding-left: $ha-spacing-large;
    }
  }

  &--Details {
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-large;

    &Name {
      word-break: break-word;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  &--Option {
    color: var(--ha-color-text-light);

    @include mediaQuery(900) {
      padding-left: $ha-spacing-small;
    }
  }
}
</style>
