<script setup>
import { computed, ref } from 'vue'
import { functions } from '@ha/helpers'
import { useContext } from '@nuxtjs/composition-api'
import SaleCard from '@/components/forms/sales/partials/SaleCard.vue'
import SelectLevyDate from '@/components/globals/form-fields/SelectLevyDate.vue'
import useSale from '@/components/forms/sales/useSale'
import CustomPriceInput from '@/components/globals/form-fields/CustomPriceInput.vue'
import useTracking from '@/composables/useTracking'

const { selectedTier, isFixedPrice, updateCustomAmountInEuros, isMonthly, dayOfLevy } = useSale()
const { i18n } = useContext()

const freePrice = ref(null)
const { trackingMethods } = useTracking()
const { onFirstClick } = trackingMethods

const price = computed(() => selectedTier.value?.price ?? 0)
const priceInEuros = computed(() => functions.convertToEuros(price.value))
const minAmount = computed(() =>
  selectedTier.value?.minAmount && selectedTier.value.minAmount >= 50
    ? selectedTier.value?.minAmount
    : 50
)
const minAmountInEuros = computed(() => functions.convertToEuros(minAmount.value))
const vatRate = computed(() => selectedTier.value?.vatRate ?? 0)
const vatLabel = computed(() =>
  vatRate.value
    ? i18n.t('campaign.step.vente.vatRate', [vatRate.value])
    : i18n.t('campaign.step.vente.noVatRate')
)
const validationRule = computed(() => `required|MIN_AMOUNT:${minAmountInEuros.value}`)

const handleInput = () => {
  updateCustomAmountInEuros(freePrice.value)
}

const trackFirstClick = () => {
  onFirstClick('First Interaction Campaign', {
    step: 0,
    step_name: 'Montant'
  })
}
</script>

<template>
  <SaleCard class="vente-price">
    <template #header>
      <template v-if="isFixedPrice">
        <p class="vente-price__title">
          {{ $t('campaign.step.vente.amount') }}
        </p>
        <p class="vente-price__amount">{{ $t('campaign.currencyUnit', [priceInEuros]) }}</p>
      </template>

      <CustomPriceInput
        v-else
        v-model="freePrice"
        size="large"
        :rules="validationRule"
        :min="parseInt(minAmountInEuros)"
        step="0.01"
        data-test="vente-prix-custom"
        tracking-name="First Interaction Campaign"
        @input="handleInput"
        @click="trackFirstClick"
      >
        <template #customPriceLabel>
          <span class="custom-price__title"> {{ $t('campaign.step.vente.freeAmount') }}</span>
          <span v-if="minAmount" class="vente-price__subtitle">
            {{ $t('campaign.step.ticketingSelect.from', [minAmountInEuros]) }}
          </span>
        </template>
        <template #suffix>€</template>
      </CustomPriceInput>

      <p class="vente-price__tva" data-test="vente-prix-vat">
        {{ vatLabel }}
      </p>
    </template>

    <SelectLevyDate
      v-if="isMonthly"
      class="vente-price__daySelector"
      :day-of-levy="dayOfLevy"
      @update:dayOfLevy="dayOfLevy = $event"
    />
  </SaleCard>
</template>

<style lang="scss">
.vente-price {
  &__daySelector {
    padding: 0;
  }

  .Label {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  &__title {
    margin: 0;
    color: var(--ha-color-text);
    font-weight: $ha-weight-semibold;
    font-size: $ha-font-size-large;
    text-align: center;
  }

  &__subtitle {
    margin: 0;
    color: var(--ha-color-text-light);
    font-weight: $ha-weight-regular;
    font-size: $ha-font-size-regular;
    text-align: center;
  }

  &__amount {
    margin: $ha-spacing-tiny 0;
    color: var(--ha-color-primary);
    font-weight: $ha-weight-semibold;
    font-size: $ha-unit * 5;
    line-height: 1;
    text-align: center;

    @include mediaQuery(600) {
      font-size: $ha-unit * 6.25;
    }
  }

  &__tva {
    margin: 0;
    color: var(--ha-color-text-light);
    font-weight: $ha-weight-regular;
    font-size: $ha-font-size-small;
    text-align: center;
  }
}
</style>
