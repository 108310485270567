<script setup>
import { computed, onMounted, onBeforeUnmount, ref, onBeforeMount } from 'vue'
import { useContext } from '@nuxtjs/composition-api';
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import SelectVoluntaryContribution from '@/components/abTests/stepFour/partials/SelectVoluntaryContribution.vue'
import useAbTasty from '@/composables/useAbTasty'
/* if we keep it maybe we could delete VoluntaryContributionStepper */
const props = defineProps({
  realAmount: { type: Number, default: 0 }
})
const emit = defineEmits(['open-explanation-modal'])
const { tipSelectedInEuros } = useVoluntaryContribution()
const {
  initAbTasty,
  isVariationE,
} = useAbTasty()
const { i18n } = useContext()

const openExplanationModal = () => {
  emit('open-explanation-modal')
}

const btnText = ref(i18n.t('button.update'))

const formattedTip = computed(() => `${tipSelectedInEuros.value} €`)

const toggleFakeBtn = (e) => {
  if (e.newState === 'open') {
    btnText.value = i18n.t('modal.contributionSelection.newModal.validate')
  } else {
    btnText.value = i18n.t('button.update')
  }
}

onBeforeMount(() => {
  initAbTasty('abcdTestFourStepCV')
})

onMounted(() => {
  const details = document.querySelector("details")
  details.addEventListener("toggle", toggleFakeBtn)
})

onBeforeUnmount(() => {
  const details = document.querySelector("details")
  details.removeEventListener("toggle", toggleFakeBtn)
})

</script>

<template>
  <div data-testid="consent-user">
    <details>
      <summary>
        <p>
          Votre contribution à HelloAsso <span>({{ formattedTip }})</span>
        </p>
        <fa v-if="!isVariationE" icon="angle-up" />
        <div
          v-else
          class="buttonMocked"
        >
          {{ btnText }}
        </div>
      </summary>
      <p>(libre et modifiable)</p>
      <SelectVoluntaryContribution
        :tiers-amount="realAmount"
        @open-explanation-modal="openExplanationModal"
      ></SelectVoluntaryContribution>
    </details>
  </div>
</template>

<style lang="scss" scoped>
/* stylelint-disable */
details {
  overflow: hidden;
  border: 1px solid var(--ha-color-primary);
  border-radius: $ha-radius-regular;

  &:not([open]) {
    border-color: var(--ha-color-border);
    summary svg {
      transform: rotate(180deg);
      color: var(--ha-color-text-light);
    }
  }

  p {
    margin-left: 1em;
    color: var(--ha-color-midnight);
    font-size: $ha-font-size-small;
    @include mediaQuery(600) {
      font-size: $ha-font-size-large;
    }
  }

  summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    list-style: none;
    cursor: pointer;

    p {
      margin-bottom: 0;
      margin-left: 0;
      font-weight: $ha-font-weight-bold;
      font-size: $ha-font-size-small;

      @include mediaQuery(600) {
        font-size: $ha-font-size-large;
      }

      span {
        color: var(--ha-color-primary);
      }
    }

    svg {
      transition: transform 0.2s;
      color: var(--ha-color-primary);
    }
  }
  .buttonMocked {
    background-color: hsl(var(--ha-color-primary-h), var(--ha-color-primary-s), calc(var(--ha-color-primary-l) * 1));
    color: var(--ha-color-white);
    padding: $ha-spacing-small $ha-spacing-medium;
    border-radius: $ha-radius-regular;
    font-size: $ha-font-size-small;

    &:hover {
      background-color: hsl(var(--ha-color-primary-h), var(--ha-color-primary-s), calc(var(--ha-color-primary-l) * 0.875));
    }

    @include mediaQuery(600) {
      padding: $ha-spacing-small $ha-spacing-large;
    }
  }
}
</style>
