import { getValidationProviderRules, getValidationProviderMode } from '@/helpers/formUtils'

const componentNameInCustomFieldTypeMapping = {
  HaNumericInput: ['Number'],
  HaSelect: ['ChoiceList'],
  HaCheckbox: ['Checkbox'],
  HaSwitch: ['Switch'],
  HaRadio: ['YesNo', 'RADIO'],
  Datepicker: ['Date'],
  HaTextArea: ['FreeText'],
  HaFileUploader: ['File'],
  HaInput: ['TextInput', 'Phone', 'Zipcode']
}

const convertCustomFieldTypeInComponentName = type => {
  return (
    Object.entries(componentNameInCustomFieldTypeMapping).find(([, customFiledTypes]) => {
      return customFiledTypes.includes(type)
    })?.[0] ?? 'HaInput'
  )
}

const getMultiChoiceOptions = (inputType, values) => {
  if (!['HaSelect', 'HaCheckbox', 'HaSwitch', 'HaRadio'].includes(inputType)) return null

  return values.map(content => ({ text: content, value: content }))
}

const getFrontEndCustomFieldModel = ({ values, type, ...rawModel }) => {
  const inputType = convertCustomFieldTypeInComponentName(type)
  const model = { ...rawModel, inputType, type }

  if (inputType === 'HaFileUploader') model.file = null
  else if (type === 'YesNo') {
    // Force the value to null to allow vee-validate to see changes in the input
    if (model.value === undefined) model.value = null

    model.multiChoiceOptions = [
      // YesNo has no values but uses the same display as multiChoice values
      { text: 'Oui', value: true },
      { text: 'Non', value: false }
    ]
  } else {
    model.multiChoiceOptions = getMultiChoiceOptions(inputType, values)
  }
  return model
}

const getFrontEndCustomField = ({ isRequired, ...rawModel }) => ({
  model: getFrontEndCustomFieldModel(rawModel),
  meta: { isLoading: false, forceError: null },
  validation: {
    validationProviderMode: getValidationProviderMode(rawModel.type),
    rules: getValidationProviderRules({ type: rawModel.type, isRequired }),
    isRequired
  }
})

const getBackEndFormatCustomField = customFields => {
  const {
    model: { id, value, multiChoiceOptions, type, label, inputType },
    validation: { isRequired }
  } = customFields

  return {
    id,
    label,
    isRequired,
    type,
    value: ['HaTextArea', 'HaInput'].includes(inputType) ? value?.trim() : value,
    values: type === 'RADIO' ? [] : multiChoiceOptions?.map(({ value }) => value)
  }
}

export const getParticipantInformationFromTier = (tier, localKey) => {
  const { firstname, lastname, email, customFields } = tier
  return {
    firstname: firstname || '',
    lastname: lastname || '',
    email: email || '',
    localKey,
    customFields: customFields?.map(getFrontEndCustomField)
  }
}

export const getBackEndParticipantInformation = participantInformation => {
  const { firstname, lastname, email, customFields } = participantInformation
  return {
    firstname,
    lastname,
    email,
    customFields: customFields?.map(getBackEndFormatCustomField)
  }
}
