<script setup>
import { computed } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import useRouteParams from '@/composables/useRouteParams'
import useStoreData from '@/composables/useStoreData'
import { getMaxByForm } from '@/components/tiers/helpers/index.ts'

const { i18n } = useContext()
const { formType } = useRouteParams()
const { form } = useStoreData()

const remainingEntries = computed(() => {
  return i18n.tc(`tiers.${formType.value}.maxByForm`, getMaxByForm(form.value), {
    max: getMaxByForm(form.value)
  })
})
</script>

<template>
  <p class="max-form-entries" data-test="tiers-max-form-entries">
    {{ remainingEntries }}
  </p>
</template>

<style lang="scss">
.max-form-entries {
  display: flex;
  margin-bottom: $ha-spacing-large;
  padding-bottom: $ha-spacing-small;
  color: var(--ha-color-text-light);
  font-size: $ha-font-size-small;
  border-bottom: $ha-border-width-regular solid set-alpha(border, 0.5);
}
</style>
