<script setup>
import {
  HaFormField,
  HaLoader,
  HaInput,
  HaNumericInput,
  HaSelect,
  HaTextArea,
  HaFileUploader,
  HaRadio,
  HaCheckbox
} from '@ha/components'
import Datepicker from '@/components/globals/form-fields/Datepicker.vue'
import { useContext } from '@nuxtjs/composition-api'
import { ValidationProvider } from 'vee-validate'
import { computed, ref, onMounted } from 'vue'

const props = defineProps({
  id: { type: String, default: null },
  validation: { type: Object, required: true },
  model: { type: Object, required: true },
  meta: { type: Object, default: () => ({}) }
})

const validationProviderRef = ref(null)
const emit = defineEmits(['input', 'update:file'])
const { i18n } = useContext()

const formattedMultiChoiceOptions = computed(() => {
  if (!props.model.multiChoiceOptions) return []

  return [
    {
      text: i18n.t('button.choose'),
      value: null
    },
    ...props.model.multiChoiceOptions
  ]
})

const isFieldComponent = componentName => componentName === props.model.inputType
const fieldLabel = computed(() => props.model.label + (props.validation.isRequired ? '*' : ''))
const getValidationCondition = ({ validated, valid }) => {
  if (props.meta.forceError) return false
  return validated ? valid : null
}

const getCommonInputProps = validatorParams => ({
  value: props.model.value,
  name: props.id,
  'data-test': props.id,
  isValid: getValidationCondition(validatorParams)
})

const onFileUpdate = newFile => {
  if (!newFile || props.meta.isLoading) return
  emit('update:file', newFile)
}

onMounted(() => {
  //* Compensate vee-validate behavior if no v-model provided
  //* https://vee-validate.logaretm.com/v3/advanced/model-less-validation.html
  if (props.model.inputType === 'HaFileUploader') validationProviderRef.value.validateSilent()
})
</script>
<template>
  <ValidationProvider
    v-slot="{ errors, ...validatorParams }"
    ref="validationProviderRef"
    :name="props.id"
    :rules="validation.rules || null"
    :mode="validation.validationProviderMode"
    slim
  >
    <HaFormField :is-valid="getValidationCondition(validatorParams)">
      <template #label>
        {{ fieldLabel }}
        <HaLoader v-if="props.meta.isLoading" />
      </template>

      <HaInput
        v-if="isFieldComponent('HaInput')"
        v-bind="getCommonInputProps(validatorParams)"
        :max-length="250"
        :hide-counter="true"
        data-test="input-custom-field-tarif"
        @input="emit('input', $event)"
      />

      <HaNumericInput
        v-else-if="isFieldComponent('HaNumericInput')"
        v-bind="getCommonInputProps(validatorParams)"
        :can-be-negative="true"
        data-test="input-custom-field-tarif"
        @input="emit('input', $event)"
      />

      <HaSelect
        v-else-if="isFieldComponent('HaSelect')"
        v-bind="getCommonInputProps(validatorParams)"
        :model="props.model.value"
        :options="formattedMultiChoiceOptions"
        data-test="input-custom-field-tarif"
        @change="emit('input', $event)"
      />

      <Datepicker
        v-else-if="isFieldComponent('Datepicker')"
        v-bind="getCommonInputProps(validatorParams)"
        format="dd/MM/yyyy"
        placeholder="jj/mm/aaaa"
        data-test="input-custom-field-tarif"
        @input="emit('input', $event)"
      />

      <HaTextArea
        v-else-if="isFieldComponent('HaTextArea')"
        v-bind="getCommonInputProps(validatorParams)"
        :max-length="5000"
        cols="30"
        rows="10"
        data-test="input-custom-field-tarif"
        @input="emit('input', $event)"
      />

      <client-only v-else-if="isFieldComponent('HaFileUploader')">
        <HaFileUploader
          :is-valid="getValidationCondition(validatorParams)"
          :file="props.model.file || null"
          :data-test="props.id"
          :label="$t('campaign.field.file')"
          :formats="['pdf', 'gif', 'png', 'jpg']"
          max-size="5 Mo"
          accept="image/*,application/pdf"
          @input="onFileUpdate"
        />
      </client-only>

      <template v-else-if="isFieldComponent('HaRadio')">
        <div class="radio__group">
          <HaRadio
            v-for="(option, index) in props.model.multiChoiceOptions"
            :key="index"
            :name="props.id"
            :value="option.value"
            :model="props.model.value"
            :is-valid="getValidationCondition(validatorParams)"
            :data-test="`${model.id}-${index}`"
            @change="emit('input', option.value)"
          >
            {{ option.text }}
          </HaRadio>
        </div>
      </template>

      <HaCheckbox
        v-else-if="isFieldComponent('HaCheckbox')"
        :data-test="props.id"
        :is-checked="props.model.value"
        :value="!!model.value"
        data-test="input-custom-field-tarif"
        @change="emit('input', $event)"
      >
        <HaLabel>{{ label }}</HaLabel>
      </HaCheckbox>
      <template #error> {{ props.meta.forceError || errors[0] }}</template>
    </HaFormField>
  </ValidationProvider>
</template>
