<template>
  <section>
    <h3>{{ $t('cartModalities.title') }}</h3>
    <form>
      <div class="choose-modality-category">
        <label for="once">
          <input
            type="radio"
            id="once"
            name="modalities"
            value="1"
            required
            :checked="modalitiesStatus === 1"
            @change="changeModalityFromRadioEvent"
          />
          <span>{{ $t('cartModalities.radio.once') }}</span>
        </label>
        <label for="multiple">
          <input
            type="radio"
            id="multiple"
            name="modalities"
            :value="availableFrequencies?.[0].frequency"
            :checked="modalitiesStatus > 1"
            @change="changeModalityFromRadioEvent"
          />
          <span>{{ $t('cartModalities.radio.multiple') }}</span>
        </label>
      </div>
      <template v-if="isCurrentModalityInModalities">
        <div class="available-frequencies">
          <template v-if="isFrequenciesAvailable && availableFrequencies?.length">
            <label :for="frequency" v-for="{ frequency } of availableFrequencies" :key="frequency">
              <input
                type="radio"
                name="frequency"
                :id="frequency"
                :value="frequency"
                :checked="modalitiesStatus === frequency"
                @change="changeModalityFromRadioEvent"
              />
              <span>{{ frequency }}x</span>
            </label>
          </template>
        </div>
        <p>{{ $t('cartModalities.chooseSuitableModalities') }}</p>
        <details open>
          <summary>
            <span>{{ $t('cartModalities.schedule') }}</span>
            <fa icon="angle-up" />
          </summary>
          <ul>
            <li v-for="({ date, realAmountWithTip }, index) of currentPaymentTerms" :key="date">
              <template v-if="index === 0">
                <span>{{ $t('cartModalities.today') }}</span>
                <div>
                  <span>{{ functions.convertToEuros(realAmountWithTip) }} €</span>
                  <button
                    v-if="hasOptionOrDonationOrNoModalitiesTier && !isInstallmentLoading"
                    type="button"
                    @click="openModalitiesModal"
                  >
                    {{ $t('cartModalities.details') }}
                  </button>
                  <HaLoader v-if="isInstallmentLoading"></HaLoader>
                </div>
              </template>
              <template v-else>
                <span>{{ haMoment(date).format('DD/MM/YYYY') }}</span>
                <span>{{ functions.convertToEuros(realAmountWithTip) }} €</span>
              </template>
            </li>
          </ul>
        </details>
        <HaModal v-if="isModalitiesModalOpen" @close="closeModalitiesModal">
          <template #header>{{ $t('cartModalities.modal.header') }}</template>
          <template #body>
            <p>{{ $t('cartModalities.modal.disclaimer') }}</p>
            <div v-if="enabledCartOptions.length">
              <h4>{{ $t('cartModalities.modal.options') }}</h4>
              <ul>
                <li v-for="optionLabel of enabledCartOptions" :key="optionLabel">
                  {{ optionLabel }}
                </li>
              </ul>
            </div>
            <div v-if="cartDonations.length">
              <h4>{{ $t('cartModalities.modal.donations') }}</h4>
              <ul>
                <li v-for="_ of cartDonations">
                  {{ $t('cartModalities.modal.donationTo') }} {{ organization.name }}
                </li>
              </ul>
            </div>
            <div v-if="noModalitiesTiers.length">
              <h4>{{ $t('cartModalities.modal.ineligibleTiers') }}</h4>
              <ul>
                <li v-for="tierLabel of noModalitiesTiers" :key="tierLabel">{{ tierLabel }}</li>
              </ul>
            </div>
            <!-- <div v-if="modalitiesTiers.length">
              <h4>{{ $t('cartModalities.modal.eligibleTiers') }}</h4>
              <ul>
                <li v-for="tierLabel of modalitiesTiers" :key="tierLabel">{{ tierLabel }}</li>
              </ul>
            </div> -->
          </template>
        </HaModal>
      </template>
    </form>
  </section>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue'
import { HaModal, HaLoader } from '@ha/components'
import { functions, haMoment } from '@ha/helpers'
import useModalities from '@/composables/useModalities'
import useCart from '@/composables/useCart'
import useStoreData from '@/composables/useStoreData'

const { organization } = useStoreData()

const { cartStore, cartOptions, cartDonations } = useCart()

const {
  modalitiesStatus,
  changeModality,
  availableFrequencies,
  isFrequenciesAvailable,
  isInstallmentLoading,
  currentPaymentTerms
} = useModalities()

const isModalitiesModalOpen = ref(false)

const enabledCartOptions = computed(() => {
  const optionsArray = cartOptions.value.filter(option => option.value).map(option => option.label)

  return mergeDuplicateLabelsInStringArray(optionsArray)
})

// const modalitiesTiers = computed(() => {
//   const labelsArray = cartStore.value.itemList
//     .filter(item => item.paymentTerms.length > 1)
//     .map(item => cartStore.value.tierList.find(tier => tier.id === item.tierId).label)

//   return mergeDuplicateLabelsInStringArray(labelsArray)
// })

const noModalitiesTiers = computed(() => {
  const singleFrequencySelectedTiersIds = cartStore.value.itemList
    .filter(item => item.paymentTerms.length === 1)
    .map(item => item.tierId)

  const singleFrequencySelectedTiersLabels = cartStore.value.tierList
    .filter(tier => singleFrequencySelectedTiersIds.includes(tier.id))
    .filter(tier => tier.price) // Exclusion des tarifs gratuits - FEAT-GP-813.
    .map(tier => tier.label)

  return mergeDuplicateLabelsInStringArray(singleFrequencySelectedTiersLabels)
})

const hasOptionOrDonationOrNoModalitiesTier = computed(() => {
  return (
    enabledCartOptions.value.length || cartDonations.value.length || noModalitiesTiers.value.length
  )
})

const isCurrentModalityInModalities = computed(() => {
  return availableFrequencies.value?.map(obj => obj.frequency).includes(modalitiesStatus.value)
})

const mergeDuplicateLabelsInStringArray = stringArray => {
  const frequencyObject = stringArray.reduce(
    (acc, curr) => ((acc[curr] = (acc[curr] || 0) + 1), acc),
    {}
  )

  const finalLabelsArray = []

  for (const [key, value] of Object.entries(frequencyObject)) {
    finalLabelsArray.push(value > 1 ? `${key} x${value}` : key)
  }

  return finalLabelsArray
}

const openModalitiesModal = () => {
  isModalitiesModalOpen.value = true
}
const closeModalitiesModal = () => {
  isModalitiesModalOpen.value = false
}

const changeModalityFromRadioEvent = e => {
  changeModality(Number(e.target.value))
}

onBeforeMount(() => {
  changeModality(cartStore.value.paymentTerms.length)
})
</script>

<style scoped lang="scss">
section {
  padding-top: 1.5rem;

  h3 {
    padding: $ha-spacing-small $ha-spacing-medium;
    font-size: $ha-font-size-jumbo;
    text-align: center;
    background-color: var(--ha-color-background);
  }
}

.HaModal {
  :deep(.HaModal--Body) {
    display: flex;
    flex-direction: column;
    gap: 1em;

    h4 {
      margin-bottom: 0.25em;
      color: var(--ha-color-iris);
      font-weight: $ha-font-weight-regular;
    }

    p {
      margin: 0;
    }

    ul {
      padding-left: 2em;

      li {
        list-style: initial;
      }
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;

  > p {
    font-size: 0.8em;
    text-align: center;
  }
}

.choose-modality-category {
  display: flex;
  flex-direction: column;
  gap: 1em;

  label {
    display: inline-flex;
    gap: 0.5em;
    align-items: center;
  }

  :is(input[type='radio'], label) {
    cursor: pointer;
  }
}

input[type='radio'] {
  --radio-middle-color: var(--ha-color-flint);

  width: 1.25em;
  margin: 0;
  background-color: var(--ha-color-white);
  border: 1px solid var(--radio-middle-color);
  border-radius: 1.25em;
  appearance: none;
  aspect-ratio: 1;

  &:checked {
    --radio-middle-color: var(--ha-color-iris);

    border-width: 0.375em;
  }

  &:focus {
    outline: 1px solid var(--ha-color-flint);
    outline-offset: 1px;
  }
}

.available-frequencies {
  display: flex;
  gap: 2em;
  justify-content: center;

  label {
    --size: 2.5em;

    position: relative;
    display: block;
    width: var(--size);
    height: var(--size);
    padding: 0.2em;
    color: var(--ha-color-storm);
    font-weight: 700;
    font-size: 0.75em;
    line-height: 2em;
    text-align: center;
    border: 1px solid var(--ha-color-flint);
    border-radius: 4px;
    aspect-ratio: 1;

    &,
    > * {
      cursor: pointer;
    }

    &:focus-within {
      outline: 1px solid var(--ha-color-flint);
      outline-offset: 1px;
    }

    &:has(:checked) {
      color: var(--ha-color-white);
      background-color: var(--ha-color-iris);
      border-color: transparent;
    }

    input[type='radio'] {
      position: absolute;
      opacity: 0;
    }
  }
}

details {
  overflow: hidden;

  &:not([open]) {
    summary svg {
      transform: rotate(90deg);
    }
  }

  summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    list-style: none;
    box-shadow: $ha-box-shadow-close;
    cursor: pointer;

    svg {
      transition: transform 0.2s;
    }
  }

  ul {
    margin-bottom: 1em;

    li {
      display: flex;
      gap: 1em;
      align-items: center;
      justify-content: space-between;
      padding: 0.5em 1em;
      border-bottom: 1px solid var(--ha-color-air);

      &:first-of-type {
        font-weight: 700;

        > div {
          display: inline-flex;
          flex-direction: column;
          align-items: flex-end;

          span {
            color: var(--ha-color-storm);
          }
        }

        > span:first-of-type {
          color: var(--ha-color-iris);
        }

        button {
          padding: 0;
          color: var(--ha-color-iris);
          font-weight: initial;
          font-size: 0.8em;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
