<script setup>
import useCartSummary from '@/components/carts/summary/useCartSummary'
import VoluntaryContribution from '@/components/carts/summary/partials/VoluntaryContribution.vue'
import TotalAmount from '@/components/carts/summary/partials/TotalAmount.vue'
import TiersAmount from '@/components/carts/summary/partials/TiersAmount.vue'
import PaymentSchedule from '@/components/carts/summary/partials/PaymentSchedule.vue'

const { isMonthly } = useCartSummary()
</script>

<template>
  <div>
    <TiersAmount />
    <VoluntaryContribution />
    <TotalAmount />
    <PaymentSchedule v-if="isMonthly" />
  </div>
</template>
