<template>
  <div
    v-if="hasData"
    :class="['CardNumbers Card-Split', { 'CardNumbers-Sticky': sticky, Card: !sticky && !widget }]"
  >
    <section class="CardNumbers--Main">
      <NumberPart v-if="!sticky" :data="mainData" :sticky="sticky" />
      <template v-if="(isCrowdfunding || counter) && mainData.type == 'amountCollected'">
        <template v-if="$device.isMobile || sticky || (widget && !counter)">
          <div class="CardNumbers--Mobile">
            <RadialProgress
              v-if="form"
              :diameter="25"
              :amount-collected="form.amountCollected"
              :goal="form.financialGoal"
              :start-color="formColor"
              :stop-color="formColor"
              stroke-linecap="square"
              :background-circle-width="3"
              :progress-circle-width="3"
              background-circle-color="#D1D6DE"
            />
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-html="
                $t('campaign.fundedAt', [
                  percentage,
                  convertToEuros(form.amountCollected),
                  convertToEuros(form.financialGoal)
                ])
              "
            />
          </div>
        </template>
        <template v-else>
          <RadialProgress
            v-if="form"
            animated
            has-percentage
            :diameter="100"
            :amount-collected="form.amountCollected"
            :goal="form.financialGoal"
            :start-color="formColor"
            :stop-color="formColor"
            stroke-linecap="square"
          />
          <span v-if="form.financialGoal >= 0" class="CardNumbers--Goal">
            {{ $t('campaign.goal', [convertToEuros(form.financialGoal)]) }}
          </span>
        </template>
      </template>

      <ha-button
        v-if="isCrowdfunding && !widget"
        class="CardNumbers--Contribute"
        fluid
        href="#tickets"
      >
        {{ $t('campaign.contribute') }}
      </ha-button>
    </section>
    <section
      v-if="secondaryData.length > 0 && !sticky && (!widget || counter)"
      class="CardNumbers--Secondary"
    >
      <NumberPart v-for="secondary in secondaryData" :key="secondary.type" :data="secondary" />
    </section>
  </div>
</template>

<script>
/* @REFACTO
We need to simplify this component, the mechanisms are not simply understandable 
Ticket azure : https://dev.azure.com/helloasso/HelloAsso/_workitems/edit/15878
*/
import { enum as enums, functions, DateOperator} from '@ha/helpers'
import { HaButton } from '@ha/components'
import { defaultColor } from '@/constants'

import NumberPart from '@/components/globals/informations/NumberPart.vue'
import RadialProgress from '@/components/globals/informations/RadialProgress.vue'
import useFormType from '@/composables/useFormType'

export default {
  name: 'CardNumbers',
  components: {
    NumberPart,
    HaButton,
    RadialProgress
  },
  props: {
    sticky: {
      type: Boolean
    },
    widget: Boolean,
    counter: Boolean
  },
  setup() {
    const { isCrowdfunding, isEvent } = useFormType()
    return { isCrowdfunding, isEvent }
  },
  computed: {
    preferredDisplay() {
      return {
        [enums.FormType.EVENT]: [
          this.timeLeftBeforeStart.count > 0 && this.form.saleStartDate
            ? `saleStartDate`
            : `saleEndDate`,
          `${this.form.formType}Members`,
          'amountCollected'
        ],
        [enums.FormType.MEMBERSHIP]: [
          this.timeLeftBeforeStart.count > 0 && this.form.saleStartDate
            ? `saleStartDate`
            : 'amountCollected',
          `saleEndDate`,
          `${this.form.formType}Members`
        ],
        [enums.FormType.CROWDFUNDING]: [
          this.timeLeftBeforeStart.count > 0 && this.form.saleStartDate
            ? `saleStartDate`
            : 'amountCollected',
          `saleEndDate`,
          `${this.form.formType}Members`
        ]
      }
    },
    form() {
      if (this.widget) {
        return this.$store.getters['forms/getEnrichedForm'](this.metadata)
      }
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    hasData() {
      return this.data.length > 0
    },
    percentage() {
      return ((this.form.amountCollected / this.form.financialGoal) * 100).toFixed(0)
    },
    formColor() {
      return this.form.color || defaultColor
    },
    data() {
      const data = []

      if (!this.isEvent && (this.form.amountVisible || this.widget)) {
        data.push({
          type: 'amountCollected',
          category: 'number',
          icon: ['fas', 'coins'],
          value: functions.convertToEuros(this.form.amountCollected),
          display: 'after'
        })
      }

      if (
        this.timeLeftBeforeStart.count <= 0 &&
        this.timeLeftBeforeEnd.count > 0 &&
        (this.form.saleEndDate || (this.isCrowdfunding && this.form.endDate))
      ) {
        data.push({
          type: `saleEndDate`,
          icon: ['fas', 'clock'],
          value: `${this.timeLeftBeforeEnd.count} ${this.timeLeftBeforeEnd.label}`,
          display: 'before'
        })
      }

      if (this.timeLeftBeforeStart.count > 0 && this.form.saleStartDate) {
        data.push({
          type: `saleStartDate`,
          icon: ['fas', 'clock'],
          value: `${this.timeLeftBeforeStart.count} ${this.timeLeftBeforeStart.label}`,
          display: 'before'
        })
      }

      if (this.form.displayParticipantsCount || this.widget) {
        data.push({
          type: `${this.form.formType}Members`,
          category: 'number',
          icon: ['fas', 'user'],
          value: this.form.participantCount,
          display: 'after'
        })
      }

      return data
    },
    hasMoreData() {
      return this.data.length > 1
    },
    mainData() {
      let chosen = null
      if (this.hasMoreData) {
        const preferredDisplay = this.preferredDisplay[this.form.formType] || []

        preferredDisplay.forEach(preferred => {
          const display = this.data.find(item => item?.type === preferred)
          if (display && !chosen) {
            chosen = display
          }
        })
      }
      return chosen ? chosen : this.data[0]
    },
    secondaryData() {
      return this.data.filter(data => this.mainData.type !== data.type)
    },
    timeLeftBeforeEnd() {
      const today = new Date()
      const endDate = this.isCrowdfunding ? this.form.endDate : this.form.saleEndDate
      const diffDays = new DateOperator(endDate).diff(today, 'day')
      const diffHours = new DateOperator(endDate).diff(today, 'hours')
      const diffMinutes = new DateOperator(endDate).diff(today, 'minutes')

      if (diffDays < 1 && diffHours < 1) {
        return {
          count: diffMinutes,
          label: this.$tc('card.time.minutes', diffMinutes)
        }
      }
      if (diffDays < 1 && diffMinutes >= 60) {
        return {
          count: diffHours,
          label: this.$tc('card.time.hours', diffHours)
        }
      }
      return {
        count: diffDays,
        label: this.$tc('card.time.days', diffDays)
      }
    },
    timeLeftBeforeStart() {
      const today = new Date()
      const startDate = this.form.saleStartDate
      const diffDays = new DateOperator(startDate).diff(today, 'day')
      const diffHours = new DateOperator(startDate).diff(today, 'hours')
      const diffMinutes = new DateOperator(startDate).diff(today, 'minutes')

      if (diffDays < 1 && diffHours < 1) {
        return {
          count: diffMinutes,
          label: this.$tc('card.time.minutes', diffMinutes)
        }
      }
      if (diffDays < 1 && diffMinutes >= 60) {
        return {
          count: diffHours,
          label: this.$tc('card.time.hours', diffHours)
        }
      }
      return {
        count: diffDays,
        label: this.$tc('card.time.days', diffDays)
      }
    }
  },
  methods: {
    convertToEuros(price) {
      return functions.formatThousands(functions.convertToEuros(price))
    }
  }
}
</script>

<style lang="scss">
.CardNumbers {
  &--Main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .NumberPart {
      &--Label {
        font-weight: $ha-font-weight-semibold;
        font-size: $ha-font-size-large;
      }

      &--Icon {
        color: var(--ha-color-primary);
        font-size: $ha-font-size-large;
      }

      &--Highlight {
        color: var(--ha-color-primary);
        font-weight: $ha-font-weight-bold;
        font-size: $ha-font-size-jumbo;
      }
    }
  }

  &--Secondary {
    .NumberPart {
      &--Icon {
        margin-right: $ha-spacing-medium;
        color: var(--ha-color-text-light);
      }

      &--Highlight {
        color: var(--ha-color-primary);
        font-weight: $ha-font-weight-bold;
      }
    }
  }

  &--Goal {
    margin-bottom: $ha-spacing-medium;
  }

  &--Mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .Radial {
      margin-right: $ha-spacing-small;
    }

    b {
      color: var(--ha-color-primary);
    }
  }
}
</style>
