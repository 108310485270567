import { ExtraOption } from '@/components/extra-options/extraOptions.interface'
import { SelectedTiers, Tier } from '@/components/tiers/tiers.interface'

export const getRequiredExtraOptionsWithMax = (tier: Tier): ExtraOption[] => {
  if (!tier) throw new Error('Missing required params tier')
  const extraOptionsWithMax = tier.extraOptions?.filter(
    (item: ExtraOption) => item.isRequired && Number.isInteger(item.remainingNumber)
  )
  return extraOptionsWithMax || []
}

export const getTierPrice = (tier: Tier, selectedTiers: SelectedTiers): number => {
  if (!tier || !selectedTiers) throw new Error('Missing required params tier or selectedTiers')
  return selectedTiers[tier.id as number]?.customAmount ?? tier.price ?? 0
}

export const getTier = (tiers: Tier[], tierId: string): Tier | undefined => {
  if (!tiers || !tierId) throw new Error('Missing required params tiers or tierId')
  return tiers.find(item => item.id?.toString() === tierId.toString())
}

export const isTierCustomAmount = (tier: Tier): boolean => {
  if (!tier) throw new Error('Missing required params tier')
  return !tier.price && tier.minAmount != null
}

export const isTierFree = (tier: Tier): boolean => {
  if (!tier) throw new Error('Missing required params tier')
  return tier.price === 0 && !tier.minAmount
}

export const isTierMonthly = (tier: Tier): boolean => {
  if (!tier) throw new Error('Missing required params tier')
  return tier.paymentFrequency === 'Monthly' || tier.tierType === 'MonthlyDonation'
}

export const isTierInstallment = (tier: Tier): boolean => {
  if (!tier) throw new Error('Missing required params tier')
  return tier.paymentFrequency === 'Installment'
}

export const isTierScheduledPayments = (tier: Tier): boolean => {
  if (!tier) throw new Error('Missing required params tier')
  return isTierMonthly(tier) || isTierInstallment(tier)
}
