<script setup>
import { computed } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import { DateOperator, functions } from '@ha/helpers'

const props = defineProps({
  tipSelected: { type: Number, default: 0 },
  payments: { type: Array, default: () => [] }
})

const { i18n } = useContext()
const sortedPayments = computed(() => {
  const payments = [...props.payments]
  payments.sort((currentItem, nextItem) => new Date(currentItem.date) - new Date(nextItem.date))
  return payments
})

const getPaymentAmount = item => {
  /* Voluntary contribution is not applied to 0€ payments */
  const amount = item.realAmount > 0 ? item.realAmount + props.tipSelected : item.realAmount
  return functions.convertToEuros(amount)
}

const getPaymentDate = (item, index) => {
  return index === 0 ? i18n.t('globals.today') : new DateOperator(item.date).format('DD/MM/YYYY')
}
</script>

<template>
  <div data-testid="payment-scheduler">
    <span class="Summary--Title">
      {{ $t('campaign.step.summary.titleScheduler') }}
    </span>
    <div class="SummaryForm">
      <div class="PaymentScheduler">
        <p class="PaymentScheduler--About">
          {{ $t('campaign.step.summary.schedulerHelp') }}
        </p>
        <div class="Scheduler">
          <div class="SchedulerWrapper">
            <div class="Scheduler--Header">
              <div class="Scheduler--Row">
                <div
                  v-for="(item, index) in sortedPayments"
                  :key="index"
                  class="Scheduler--Column"
                  data-test="dates"
                >
                  {{ getPaymentDate(item, index) }}
                </div>
              </div>
            </div>
            <div class="Scheduler--Body">
              <div class="Scheduler--Row">
                <div
                  v-for="(item, index) in sortedPayments"
                  :key="index"
                  class="Scheduler--Column"
                  data-test="amounts"
                >
                  {{ $t('campaign.currencyUnit', [getPaymentAmount(item)]) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.PaymentScheduler {
  margin-top: $ha-spacing-large;

  &--About {
    font-size: $ha-font-size-small;
  }

  .Scheduler {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    overflow-x: auto;
    background-color: var(--ha-color-white);

    &Wrapper {
      width: 100%;
    }

    &--Header {
      .Scheduler--Column {
        padding-top: $ha-spacing-small;
        padding-bottom: $ha-spacing-small;
        color: var(--ha-color-text-light);
        font-weight: $ha-font-weight-semibold;
        font-size: $ha-font-size-small;
        background-color: var(--ha-color-background-disabled);
      }
    }

    &--Row {
      display: flex;
      flex: 1 1 auto;
    }

    &--Column {
      flex: 1 1 ($ha-unit * 14); // 112px
      width: 100%;
      min-width: $ha-unit * 14; // 112px
      padding: $ha-spacing-medium;
      text-align: center;

      @include mediaQuery(600) {
        flex: 1 1 ($ha-unit * 16); // 128px
        min-width: $ha-unit * 16; // 128px
      }

      + .Scheduler--Column {
        border-left: $ha-border-regular;
      }
    }
  }

  /**
  * Fix scrollbar in Safari
  */

  .Scheduler::-webkit-scrollbar {
    height: $ha-unit; // 8px
  }

  .Scheduler::-webkit-scrollbar-track {
    background-color: var(--ha-color-background);
    border-radius: $ha-radius-round;
  }

  .Scheduler::-webkit-scrollbar-thumb {
    background-color: set-lightness('text-disabled', 1.25);
    border-radius: $ha-radius-round;
  }

  .Scheduler::-webkit-scrollbar-thumb:hover {
    background-color: set-lightness('text-disabled', 1.125);
  }

  .Scheduler::-webkit-scrollbar-thumb:window-inactive {
    background-color: var(--ha-color-background-disabled);
  }
}
</style>
