<template>
  <section class="SummaryForm--Total">
    <div class="CardSummary">
      <!-- Tickets -->
      <section
        :class="['CardSummary--Section-Tickets', { 'CardSummary--Section': !isCrowdfunding }]"
      >
        <CartSummaryTiers
          :title="getCartTicketsTitle"
          :cart-elements="cartTickets"
          data-test="row-price"
        />
      </section>

      <!-- Donation -->
      <section
        v-if="cartDonations.length"
        class="CardSummary--Section CardSummary--Section-Donations"
      >
        <CartSummaryTiers
          :title="getCartDonationTitle"
          :cart-elements="cartDonations"
          data-testid="row-optional-donation"
        />
      </section>

      <!-- Reduction -->
      <section
        v-if="displayCouponSection"
        class="CardSummary--Section CardSummary--Section-Reduction"
      >
        <h3 v-show="coupon && !editDiscount" class="CardSummary--Title">
          {{ $t('campaign.step.summary.discount') }}
        </h3>
        <DiscountCodeField v-show="editDiscount" @applied="onUpdateDiscountCode" />
        <!-- v-show instead of v-if is required to have the following div reactive! -->
        <div v-show="coupon && !editDiscount" class="CardSummary--Coupon">
          <PriceListItem
            :key="`cart-summary-2`"
            :cart-item="{
              customAmount: discount
            }"
          >
            <div slot="tierName">
              <div class="Tier-Name-WithIcon">
                {{ coupon }}
                <client-only>
                  <ha-button
                    variant="flat"
                    color="danger"
                    size="small"
                    :data-ux="`Forms_${metadata.type}_CardSummary_UpdateCoupon`"
                    data-testid="modify-discount"
                    @click="editDiscount = true"
                  >
                    <template #icon>
                      <fa icon="pencil-alt" />
                      <span class="visually-hidden">
                        {{ $t('coupon.update') }}
                      </span>
                    </template>
                  </ha-button>
                </client-only>
              </div>
            </div>
          </PriceListItem>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { filter, forEach, get, sumBy, size } from 'lodash-es'
import { HaButton } from '@ha/components'
import { functions } from '@ha/helpers'
import useFormType from '@/composables/useFormType'

import PriceListItem from '@/components/carts/summary/partials/PriceListItem.vue'
import DiscountCodeField from '@/components/discount-codes/DiscountCodeField.vue'
import CartSummaryTiers from '@/components/carts/summary/CartSummaryTiers.vue'

export default {
  name: 'CardSummary',
  components: {
    HaButton,
    DiscountCodeField,
    PriceListItem,
    CartSummaryTiers
  },
  props: {
    hasScheduledPayments: Boolean
  },
  setup() {
    const { isCrowdfunding } = useFormType()

    return { isCrowdfunding }
  },
  data() {
    return {
      editDiscount: true
    }
  },
  computed: {
    getCartTicketsTitle() {
      return !this.isCrowdfunding
        ? this.$t(`campaign.step.summary.type.${this.form.formType.toLowerCase()}.options`)
        : null
    },
    getCartDonationTitle() {
      return this.$tc('campaign.step.summary.donationTitle', [this.organization.name])
    },
    cart() {
      return this.$store.getters['carts/getCart'](this.metadata)
    },
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](this.organizationSlug)
    },
    organizationSlug() {
      return this.metadata.organization
    },
    discount: {
      cache: false,
      get() {
        const discount = this.$store.getters['carts/getDiscount'](this.metadata)
        return discount > 0 ? -discount : 0
      }
    },
    cartItems() {
      return this.cart?.itemList || []
    },
    cartTickets() {
      return this.cartItems.filter(item => item?.tierType !== 'Donation')
    },
    cartDonations() {
      return this.cartItems.filter(item => item?.tierType === 'Donation')
    },
    hasOnlyFreeTickets() {
      return sumBy(this.cartTickets, 'customAmount') === 0
    },
    displayCouponSection() {
      return get(this.form, 'displayDiscountCode', true) ? !this.hasOnlyFreeTickets : false
    },
    options() {
      const itemsWithOptions = filter(this.cartItems, function (item) {
        return item.extraOptions
      })
      const options = []
      forEach(itemsWithOptions, item => {
        options.push(
          ...filter(item.extraOptions, option => {
            return option.value
          })
        )
      })
      return options
    },
    total() {
      return functions.convertToEuros(
        this.totalItems + this.totalOptions + this.totalDonation + this.discount
      )
    },
    totalItems() {
      let total = 0

      forEach(this.cartTickets, item => {
        // get payment frequency
        const paymentFrequency = size(get(item, 'paymentTerms', 1))
        // return cart item amount, multiplied by payment frequency
        total += get(item, 'customAmount', 0) * paymentFrequency
      })
      return total
    },
    totalOptions() {
      let total = 0

      total += sumBy(this.options, function (option) {
        return option.paymentTerms ? option.paymentTerms[0].realAmount : 0
      })
      return total
    },
    totalDonation() {
      let total = 0

      forEach(this.cartDonations, item => {
        total += get(item, 'customAmount')
      })
      return total
    },
    coupon() {
      return this.$store.getters['carts/getCartCoupon'](get(this, 'cart.id'))
    }
  },
  // this is used to ensure that promo code display correctly when page is reloaded
  watch: {
    coupon: {
      immediate: true,
      handler() {
        if (this.coupon) {
          this.editDiscount = false
        }
      }
    }
  },
  methods: {
    convertToEuros(price) {
      return functions.convertToEuros(price)
    },
    onUpdateDiscountCode() {
      this.editDiscount = false
    }
  }
}
</script>

<style lang="scss" scoped>
.CardSummary {
  padding-bottom: $ha-spacing-large;

  &--Title {
    margin-bottom: $ha-spacing-small;
    font-weight: $ha-font-weight-bold;
  }

  &--Section {
    padding-top: $ha-spacing-large;

    &-Reduction {
      .Tier-Name-WithIcon {
        display: inline-flex;
        text-transform: uppercase;

        .HaButton-Small.HaButton-IconOnly {
          margin-left: $ha-spacing-tiny;
          padding: $ha-spacing-mini $ha-spacing-tiny !important;
        }
      }
    }

    &-Total {
      margin-top: $ha-spacing-large;
      padding-top: $ha-spacing-large;
      border-top: $ha-border-width-large dashed set-alpha(border, 0.5);
    }
  }

  .Total {
    &--Main,
    &--Details {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;

      &Name {
        flex: 1 1 auto;
      }

      &Price {
        flex: 0 0 auto;
        padding-left: $ha-spacing-large;
      }
    }

    &--Main {
      margin-bottom: $ha-spacing-mini;
      font-weight: $ha-font-weight-bold;
      font-size: $ha-font-size-big;
    }
  }

  .PriceListItem {
    + .CardSummary--Title {
      padding-top: $ha-spacing-large;
    }
  }
}
</style>
