<script setup>
import { computed } from 'vue'
import PayerPersonalInformations from '@/components/payers/PayerPersonalInformations.vue'

const props = defineProps({
  selectedTier: {
    type: Object
  }
})

const isActiveBlock = computed(() => !!props.selectedTier)
</script>

<template>
  <div class="Block" data-testid="personal-information-block">
    <div
      class="Block--Title"
      :class="{ 'Block--Title-Active': isActiveBlock }"
      data-testid="title-personal-information"
    >
      <fa icon="list" aria-hidden="true" />
      {{ $t('campaign.myPersonalInformations') }}
    </div>
    <div class="Block--Content">
      <PayerPersonalInformations :selected-tier="selectedTier" isAddressNeeded />
    </div>
  </div>
</template>
