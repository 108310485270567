import { functions } from '@ha/helpers'
/**
 * Create seoTag
 * @param {String} tag
 * @param {String} type
 * @param {String} aggregateUrl
 * @param {Object} infoSeo
 * @returns {Object} seoTag to redirect to Explore
 */
const createTag = (tag, type, infoSeo = '', aggregateUrl  = '') => ({
  name: `${functions.capitalizeFirstLetter(tag)}${infoSeo?.city && infoSeo?.city != tag ? ` à ${infoSeo.city}` : ''}`,
  url: `${aggregateUrl}${type}/${tag}`,
  infoSeo
})

/**
 * Aggregate tags in array
 * @param {Object} seoTags
 * @returns array of seoTags for display
 */
export const getAllTags = seoTags => {
  let allTags = []
  const excludedHaTags = ['Optéléthon2021', 'Optéléthon2022', 'Tds2021']

  // Need this for Segment and get city
  let infoSeo = {
    region: '',
    department: '',
    city: '',
    tagName: '',
    dataUx: ''
  }

  // categories and events are an array
  if (seoTags.categories) {
    seoTags.categories.forEach(tag => {
      const infoSeoWithTagName = {
        ...infoSeo,
        tagName: tag,
        dataUx: 'Category'
      }
      allTags.push(createTag(tag, 'cat', infoSeoWithTagName))
    })
  }

  if (seoTags.evenements) {
    seoTags.evenements.forEach(tag => {
      if (!excludedHaTags.includes(tag)) {
        const infoSeoWithTagName = {
          ...infoSeo,
          tagName: tag,
          dataUx: 'InternalTag'
        }
        allTags.push(createTag(tag, 'evt', infoSeoWithTagName))
      }
    })
  }

  if (
    seoTags.activities &&
    seoTags.activities !== 'Cotisation' &&
    seoTags.activities !== 'Vente'
  ) {
    const infoSeoWithTagName = {
      ...infoSeo,
      tagName: seoTags.activities,
      dataUx: 'Activity'
    }
    allTags.push(createTag(seoTags.activities, 'act', infoSeoWithTagName))
  }
  // to aggregate the url for 'city' & 'in city' pages, for exemple 'sport à Bordeaux'

  if (seoTags.region && seoTags.departement && seoTags.city) {
    let aggregateUrl = `reg/${seoTags.region}/dep/${seoTags.departement}/`

    infoSeo.region = seoTags.region
    infoSeo.department = seoTags.departement
    infoSeo.city = seoTags.city
    const infoSeoWithDataUx = { ...infoSeo, dataUx: 'Location' }

    allTags.push(
      createTag(seoTags.city, 'ville', infoSeoWithDataUx, aggregateUrl)
    )
    aggregateUrl += 'ville/' + seoTags.city + '/'

    if (seoTags.categories) {
      seoTags.categories.forEach(tag => {
        const infoSeoWithTagName = {
          ...infoSeo,
          tagName: tag,
          dataUx: 'Location_Category'
        }
        allTags.push(
          createTag(tag, 'cat', infoSeoWithTagName, aggregateUrl)
        )
      })
    }

    if (
      seoTags.activities &&
      seoTags.activities !== 'Cotisation' &&
      seoTags.activities !== 'Vente'
    ) {
      const infoSeoWithTagName = {
        ...infoSeo,
        tagName: seoTags.activities,
        dataUx: 'Location_Activity'
      }
      allTags.push(
        createTag(
          seoTags.activities,
          'act',
          infoSeoWithTagName,
          aggregateUrl
        )
      )
    }
  }
  return { allTags }
}
