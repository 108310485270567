<template>
  <client-only>
    <div class="Radial" :style="containerStyle">
      <div class="Radial--Inner" :style="innerCircleStyle">
        <slot>
          <span v-if="hasPercentage">
            {{
              goal > 0
                ? finishedPercentage.toFixed(0) + ' %'
                : convertToEuros(amountCollected) + ' €'
            }}
          </span>
        </slot>
      </div>
      <svg
        class="Radial--ProgressBar"
        :width="diameter"
        :height="diameter"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <radialGradient
            :id="'radial-gradient' + _uid"
            :fx="gradient.fx"
            :fy="gradient.fy"
            :cx="gradient.cx"
            :cy="gradient.cy"
            :r="gradient.r"
          >
            <stop offset="30%" :stop-color="startColor" />
            <stop offset="100%" :stop-color="stopColor" />
          </radialGradient>
        </defs>
        <circle
          :r="innerCircleRadius"
          :cx="radius"
          :cy="radius"
          fill="transparent"
          :stroke="backgroundCircleColor"
          :stroke-dasharray="circumference"
          stroke-dashoffset="0"
          :stroke-linecap="strokeLinecap"
          :style="strokeStyle"
        />
        <circle
          :transform="'rotate(270, ' + radius + ',' + radius + ')'"
          :r="innerCircleRadius"
          :cx="radius"
          :cy="radius"
          fill="transparent"
          :stroke="'url(#radial-gradient' + _uid + ')'"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="circumference"
          :stroke-linecap="strokeLinecap"
          :style="progressStyle"
        />
      </svg>
    </div>
  </client-only>
</template>

<script>
import { functions } from '@ha/helpers'

export default {
  props: {
    diameter: {
      type: Number,
      required: false,
      default: 200
    },
    goal: {
      type: Number,
      required: false
    },
    amountCollected: {
      type: Number,
      required: true,
      default: 0
    },
    startColor: {
      type: String,
      required: false,
      default: '#bbff42'
    },
    stopColor: {
      type: String,
      required: false,
      default: '#429321'
    },
    progressCircleWidth: {
      type: Number,
      required: false,
      default: 12
    },
    backgroundCircleWidth: {
      type: Number,
      required: false,
      default: 6
    },
    strokeLinecap: {
      type: String,
      required: false,
      default: 'round'
    },
    backgroundCircleColor: {
      type: String,
      required: false,
      default: '#f8f8f8'
    },
    animated: Boolean,
    hasPercentage: Boolean
  },
  data() {
    const innerCircleDiameter = this.diameter - this.backgroundCircleWidth * 2

    return {
      gradient: {
        fx: 0.99,
        fy: 0.5,
        cx: 0.5,
        cy: 0.5,
        r: 0.65
      },
      gradientAnimation: null,
      currentAngle: 0,
      strokeDashoffset: 0,
      radius: this.diameter / 2,
      finishedPercentage: (this.amountCollected / this.goal) * 100,
      innerCircleRadius: innerCircleDiameter / 2,
      circumference: Math.PI * innerCircleDiameter,
      containerStyle: {
        height: `${this.diameter}px`,
        width: `${this.diameter}px`
      },
      strokeStyle: {
        height: `${this.diameter}px`,
        width: `${this.diameter}px`,
        strokeWidth: `${this.backgroundCircleWidth}px`
      },
      innerCircleStyle: {
        width: `${innerCircleDiameter}px`
      }
    }
  },
  computed: {
    progressStyle() {
      return {
        height: `${this.diameter}px`,
        width: `${this.diameter}px`,
        strokeWidth: `${this.progressCircleWidth}px`,
        strokeDashoffset: this.strokeDashoffset,
        transition: `stroke-dashoffset 1000ms linear`
      }
    }
  },
  mounted() {
    if (this.animated) {
      this.animate()
    } else {
      this.strokeDashoffset = ((100 - this.finishedPercentage) / 100) * this.circumference
    }
  },
  beforeDestroy() {
    if (this.gradientAnimation) clearInterval(this.gradientAnimation)
  },
  methods: {
    convertToEuros(price) {
      return functions.formatThousands(functions.convertToEuros(price))
    },
    animate() {
      this.strokeDashoffset = this.circumference

      if (this.gradientAnimation) {
        clearInterval(this.gradientAnimation)
      }

      let i = 0
      const incrementer = this.circumference * 0.01

      this.gradientAnimation = setInterval(() => {
        if (
          i >= this.finishedPercentage ||
          this.circumference - this.strokeDashoffset == this.circumference
        ) {
          clearInterval(this.gradientAnimation)
          return
        }
        this.strokeDashoffset = this.circumference - incrementer * i
        i++
      }, 5)
    }
  }
}
</script>

<style lang="scss">
.Radial {
  position: relative;
  margin-top: $ha-spacing-medium;
  margin-bottom: $ha-spacing-medium;

  &--Inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50%;
  }
}
</style>
