<template>
  <ha-tooltip class="HaTooltip-SocialMediaButton" theme="light">
    <template #trigger>
      <ha-button
        :class="`SocialMediaButton SocialMediaButton-${name}`"
        :data-ux="id"
        color="basic"
        variant="outline"
        :aria-label="name"
        @click="clickHandler"
      >
        <fa :icon="icon" />
      </ha-button>
    </template>
    <template #content>
      {{ iconTitle }}
    </template>
  </ha-tooltip>
</template>

<script>
import { get, invoke, toLower } from 'lodash-es'
import { HaButton, HaTooltip } from '@ha/components'
import { enum as enums, isHaMoment } from '@ha/helpers'
import useTracking from '@/composables/useTracking'
import { facebookAppId } from '@/constants'

export default {
  name: 'SocialMediaButton',
  components: {
    HaButton,
    HaTooltip
  },
  props: {
    socialMedia: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    formName: {
      type: String,
      required: true
    },
    formSlug: {
      type: String,
      required: true
    },
    formType: {
      type: String,
      required: true
    },
    formDate: {
      type: Object,
      required: false,
      validator: isHaMoment
    },
    organization: {
      type: String,
      required: true
    },
    sharingLocation: {
      type: Object,
      required: false
    }
  },
  setup() {
    const { trackingTrack } = useTracking()
    return { trackingTrack }
  },
  data() {
    return {
      appId: facebookAppId
    }
  },
  computed: {
    SocialMediaConfigs() {
      return {
        FACEBOOK: {
          getUrl(href) {
            return `https://www.facebook.com/sharer/sharer.php?u=${href}`
          },
          name: 'Facebook',
          id: `Forms_${this.formType}_SocialMediaButton_Facebook`,
          icon: ['fab', 'facebook-f'],
          label: this.$i18n.t(`share.buttonLabel.facebook.${this.campaignType(this.formType)}`)
        },
        TWITTER: {
          getUrl(href, form, formType, i18n) {
            return `https://twitter.com/intent/tweet?text=${i18n.t(
              `share.prefilledText.twitter.${toLower(formType)}`,
              [form, href]
            )}`
          },
          name: 'Twitter',
          id: `Forms_${this.formType}_SocialMediaButton_Twitter`,
          icon: ['fab', 'x-twitter'],
          label: this.$i18n.t(`share.buttonLabel.twitter.${this.campaignType(this.formType)}`)
        },
        LINKEDIN: {
          getUrl(href, form) {
            return `https://www.linkedin.com/shareArticle?mini=true&url=${href}&title=${form}&source=HelloAsso`
          },
          name: 'Linkedin',
          id: `Forms_${this.formType}_SocialMediaButton_Linkedin`,
          icon: ['fab', 'linkedin-in'],
          label: this.$i18n.t(`share.buttonLabel.linkedin.${this.campaignType(this.formType)}`)
        },
        EMAIL: {
          getUrl(href, form, formType, i18n) {
            return `mailto:?subject=${i18n.t(`share.prefilledText.email.${toLower(formType)}`, [
              form
            ])}&body=${href}`
          },
          name: 'Email',
          id: `Forms_${this.formType}_SocialMediaButton_Email`,
          icon: ['fas', 'envelope'],
          label: this.$i18n.t(`share.buttonLabel.email.${this.campaignType(this.formType)}`)
        },
        COPY_URL: {
          getClickHandler: () => () => {
            navigator.clipboard.writeText(this.href)
            this.trackClickSegment(true)
          },
          name: 'CopyUrl',
          id: `Forms_${this.formType}_SocialMediaButton_CopyUrl`,
          icon: ['fas', 'link'],
          label: this.$i18n.t('share.buttonLabel.copyUrl')
        },
        MESSENGER: {
          getUrl(href) {
            return `fb-messenger://share/?link=${href}&app_id=${this.appId}`
          },
          name: 'Messenger',
          id: `Forms_${this.formType}_SocialMediaButton_Messenger`,
          icon: ['fab', 'facebook-messenger'],
          label: this.$i18n.t(`share.buttonLabel.messenger.${this.campaignType(this.formType)}`)
        }
      }
    },
    clickHandler() {
      return this.url
        ? () => {
            const popupWidth = 560
            const popupHeight = 480
            const popupXPosition = screen.availLeft + (screen.availWidth - popupWidth) / 2
            const popupYPosition = screen.availTop + (screen.availHeight - popupHeight) / 2
            this.trackClickSegment()
            window.open(
              this.url,
              'Share',
              `width=${popupWidth},height=${popupHeight},left=${popupXPosition},top=${popupYPosition}`
            )
          }
        : invoke(
            this.SocialMediaConfigs,
            [this.socialMedia, 'getClickHandler'],
            this.formName,
            this.href
          ) || (() => {})
    },
    url() {
      return invoke(
        this.SocialMediaConfigs,
        [this.socialMedia, 'getUrl'],
        this.href,
        this.formName,
        this.formType,
        this.$i18n
      )
    },
    name() {
      return get(this.SocialMediaConfigs, [this.socialMedia, 'name'])
    },
    id() {
      return get(this.SocialMediaConfigs, [this.socialMedia, 'id'])
    },
    class() {
      return get(this.SocialMediaConfigs, [this.socialMedia, 'class'])
    },
    icon() {
      return get(this.SocialMediaConfigs, [this.socialMedia, 'icon'])
    },
    iconTitle() {
      return get(this.SocialMediaConfigs, [this.socialMedia, 'label'])
    },
    formTypes() {
      return enums.FormType
    }
  },
  methods: {
    campaignType(type) {
      switch (type) {
        case this.formTypes.CROWDFUNDING:
          return 'collect'
        case this.formTypes.EVENT:
          return 'event'
        case this.formTypes.MEMBERSHIP:
          return 'campaign'
        case this.formTypes.SHOP:
          return 'shop'
        default:
          return 'page'
      }
    },
    trackClickSegment() {
      this.trackingTrack('Campaign Shared', {
        form_slug: this.formSlug,
        form_type: this.formType,
        sharing_type: 'Social button',
        sharing_location: arguments[0] ? 'COPY_URL' : this.socialMedia,
        organization_slug: this.organization
      })
    }
  }
}
</script>

<style lang="scss">
$color-facebook: #3b5999;
$color-twitter: #55acee;
$color-linkedin: #0077b5;
$color-messenger: #006aff;

.HaTooltip-SocialMediaButton {
  .HaTooltip--Content {
    width: $ha-unit * 25; // 200px
    text-align: center;
  }
}

.SocialMediaButton {
  display: flex;
  justify-content: center;
  width: $ha-unit * 5; // 40px
  height: $ha-unit * 5; // 40px
  min-height: unset;
  padding: 0;

  &:not(.SocialMediaButton-CopyUrl) {
    color: var(--ha-color-white);
  }

  &.SocialMediaButton-Facebook {
    background-color: $color-facebook;
    border: $ha-border-width-regular solid $color-facebook;

    &:hover {
      background-color: darken($color-facebook, 7.5%);
      border-color: darken($color-facebook, 7.5%);
    }

    &:focus {
      background-color: $color-facebook;
      box-shadow: 0 0 0 ($ha-unit * 0.5) rgba($color-facebook, 0.25); // 0 0 0 4px
    }

    &:active {
      background-color: darken($color-facebook, 15%);
      border-color: darken($color-facebook, 15%);
    }
  }

  &.SocialMediaButton-Twitter {
    background-color: $color-twitter;
    border: $ha-border-width-regular solid $color-twitter;

    &:hover {
      background-color: darken($color-twitter, 7.5%);
      border-color: darken($color-twitter, 7.5%);
    }

    &:focus {
      background-color: $color-twitter;
      box-shadow: 0 0 0 ($ha-unit * 0.5) rgba($color-twitter, 0.25); // 0 0 0 4px
    }

    &:active {
      background-color: darken($color-twitter, 15%);
      border-color: darken($color-twitter, 15%);
    }
  }

  &.SocialMediaButton-Linkedin {
    background-color: $color-linkedin;
    border: $ha-border-width-regular solid $color-linkedin;

    &:hover {
      background-color: darken($color-linkedin, 7.5%);
      border-color: darken($color-linkedin, 7.5%);
    }

    &:focus {
      background-color: $color-linkedin;
      box-shadow: 0 0 0 ($ha-unit * 0.5) rgba($color-linkedin, 0.25); // 0 0 0 4px
    }

    &:active {
      background-color: darken($color-linkedin, 15%);
      border-color: darken($color-linkedin, 15%);
    }
  }

  &.SocialMediaButton-Email {
    background-color: var(--ha-color-secondary);
    border: $ha-border-width-regular solid var(--ha-color-secondary);

    &:hover {
      background-color: set-lightness('secondary', 0.875);
      border-color: set-lightness('secondary', 0.875);
    }

    &:focus {
      background-color: var(--ha-color-secondary);
      box-shadow: 0 0 0 ($ha-unit * 0.5) set-alpha('secondary', 0.25); // 0 0 0 4px
    }

    &:active {
      background-color: set-lightness('secondary', 0.75);
      border-color: set-lightness('secondary', 0.75);
    }
  }

  &.SocialMediaButton-Messenger {
    background-color: $color-messenger;
    border: $ha-border-width-regular solid $color-messenger;

    &:hover {
      background-color: darken($color-messenger, 7.5%);
      border-color: darken($color-messenger, 7.5%);
    }

    &:focus {
      background-color: $color-messenger;
      box-shadow: 0 0 0 ($ha-unit * 0.5) rgba($color-messenger, 0.25); // 0 0 0 4px
    }

    &:active {
      background-color: darken($color-messenger, 15%);
      border-color: darken($color-messenger, 15%);
    }
  }
}
</style>
