<script setup>
import { computed } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import QuantitySelector from '@/components/globals/form-fields/QuantitySelector.vue'
import TiersItemSelectorButton from '@/components/tiers/partials/selector/TiersItemSelectorButton.vue'
import useFormType from '@/composables/useFormType'
import useStoreData from '@/composables/useStoreData'
import useTiers from '@/components/tiers/useTiers'
import {
  getMaxByCart,
  getMaxByForm,
  getMaxByTier,
  getMaxByUser,
  getMaxByExtraOption,
  getTierQuantity,
  getCartQuantity
} from '@/components/tiers/helpers/index.ts'

const props = defineProps({
  tier: { type: Object, required: true },
  isFetching: Boolean
})
const emit = defineEmits(['update-selected-tiers'])

const { i18n } = useContext()
const { isCrowdfunding } = useFormType()
const { form } = useStoreData()
const { selectedTiers, updateSelectedTier, isCollectAllowed } = useTiers()

const tierQuantity = computed(() => getTierQuantity(props.tier, selectedTiers.value))
const maxByForm = computed(() => getMaxByForm(form.value))
const maxByCart = getMaxByCart()

const getRemainingQuantity = maxQuantity => {
  if (maxQuantity == null) return null
  return Math.max(0, maxQuantity - getCartQuantity(selectedTiers.value) + tierQuantity.value)
}

/* maxQuantities objects and activeMaxKey allow to specify the element which
trigger the max and soldout because of specific wording */
const maxQuantities = computed(() => ({
  cart: getRemainingQuantity(maxByCart),
  form: getRemainingQuantity(maxByForm.value),
  tier: getMaxByTier(props.tier),
  user: getMaxByUser(props.tier),
  extraOption: getMaxByExtraOption(props.tier)
}))

const activeMaxKey = computed(() =>
  Object.keys(maxQuantities.value).reduce((reasonKey, key) => {
    if (maxQuantities.value[key] == null) return reasonKey
    return maxQuantities.value[key] <= maxQuantities.value[reasonKey] ? key : reasonKey
  }, 'cart')
)

const maxQuantity = computed(() => maxQuantities.value[activeMaxKey.value])

const isSoldOut = computed(() => !maxQuantity.value && !tierQuantity.value)

const soldOutLabel = computed(() =>
  activeMaxKey.value === 'cart'
    ? i18n.t('tiers.selector.soldOutLimited')
    : i18n.t('tiers.selector.soldOut')
)

const onQuantityChange = quantity => {
  updateSelectedTier(props.tier, { quantity })
  emit('update-selected-tiers')
}
</script>

<template>
  <div class="tier-item-selector">
    <p v-if="isSoldOut" class="tier-item-selector__soldout">
      {{ soldOutLabel }}
    </p>
    <TiersItemSelectorButton
      v-else-if="isCrowdfunding"
      :tier="tier"
      :is-fetching="isFetching"
      @update-selected-tiers="emit('update-selected-tiers')"
    />
    <QuantitySelector
      v-else
      :id="tier.id"
      :value="tierQuantity"
      :max="maxQuantity"
      :disabled="!isCollectAllowed || props.isFetching"
      :label="tier.label"
      @change="onQuantityChange"
    />
  </div>
</template>

<style lang="scss" scoped>
.tier-item-selector {
  @include mediaQuery(900) {
    flex: 1 0 ($ha-unit * 20); // 160px
    max-width: $ha-unit * 20; // 160px
    text-align: right;
  }

  &__soldout {
    margin: 0;
    padding: $ha-spacing-tiny $ha-spacing-large;
    color: var(--ha-color-text-disabled);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-small;
    line-height: $ha-line-height-large;
    text-align: center;
    background-color: var(--ha-color-background-disabled);
    background-clip: padding-box;
    border-radius: $ha-radius-regular;

    @include mediaQuery(900) {
      min-width: $ha-unit * 17.5;
    }
  }
}
</style>
