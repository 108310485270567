<template>
  <div class="IFPBlock Card">
    <h3>{{ $t('campaign.aboutOrganism') }}</h3>
    <p v-if="compliance.organizationPurpose">
      {{ compliance.organizationPurpose }}
    </p>
    <p v-if="compliance.rna">RNA: {{ compliance.rna }}</p>
  </div>
</template>

<script>
export default {
  name: 'IFPBlock',
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    organization() {
      return this.$store.getters['organizations/getOrganization'](
        this.metadata.organization
      )
    },
    compliance() {
      return this.$store.getters['organizations/getOrganizationCompliance'](
        this.metadata.organization
      )
    }
  }
}
</script>

<style lang="scss">
.IFPBlock {
  font-size: $ha-font-size-small;
}
</style>
