import { render, staticRenderFns } from "./TiersItemDescriptionQuantity.vue?vue&type=template&id=9190daba&scoped=true"
import script from "./TiersItemDescriptionQuantity.vue?vue&type=script&setup=true&lang=js"
export * from "./TiersItemDescriptionQuantity.vue?vue&type=script&setup=true&lang=js"
import style0 from "./TiersItemDescriptionQuantity.vue?vue&type=style&index=0&id=9190daba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9190daba",
  null
  
)

export default component.exports