<template>
  <ha-modal
    class="ModalContributionExplicationTest"
    v-on="$listeners"
    data-testid="cv-reason-modal"
  >
    <template #header data-testid="title-reason-modal">
      <div class="Title">
        <h4>{{ title }}</h4>
        <img
          src="https://stockagehelloassoprod.blob.core.windows.net/images/forms/abtest/abcdtest_step4.svg"
        />
      </div>
    </template>
    <template #body>
      <template>
        <div class="ContributionCard">
          <span class="Text" v-html="text1" />
          <HaButton
            :href="`/associations/${organization.organizationSlug}`"
            target="_blank"
            rel="noopener noreferrer"
            variant="link"
            data-testid="link-asso-name"
          >
            {{ organization.name }}
          </HaButton>
          <span class="Text">{{ text2 }}</span>
        </div>
      </template>
    </template>
    <template #footer>
      <ha-button
        :data-ux="`Forms_${metadata.type}_Modal_ContributionExplication_GotIt`"
        variant="outline"
        data-testid="button-close"
        @click="$emit('close')"
      >
        {{ buttonLabel }}
      </ha-button>
    </template>
  </ha-modal>
</template>

<script setup>
import { HaModal, HaButton } from '@ha/components'
import { computed } from 'vue'
import { useContext } from '@nuxtjs/composition-api'
import useStoreData from '@/composables/useStoreData'

const { i18n } = useContext()
const { organization } = useStoreData()

const title = computed(() => i18n.t('abTasty.contributionExplanation.title'))
const text1 = computed(() => i18n.t('abTasty.contributionExplanation.text1'))
const text2 = computed(() => i18n.t('abTasty.contributionExplanation.text2'))
const buttonLabel = computed(() => i18n.t('abTasty.headerCV.buttonLabel'))
</script>

<style lang="scss">
.ModalContributionExplicationTest {
  text-align: left;

  .HaModal--Header {
    width: 100%;
    padding: $ha-spacing-medium;
  }

  &.HaModal {
    justify-content: center;

    @media (min-width: 600px) {
      justify-content: center;
    }

    .HaModal {
      &--Wrapper {
        width: auto;
        margin-inline: $ha-spacing-medium;
      }

      &--Content {
        align-items: center;
        background: color-mix(in srgb, var(--ha-color-iris) 5%, var(--ha-color-white));
        border-radius: $ha-unit;
      }

      &--Body {
        padding: 0 $ha-spacing-large $ha-spacing-large $ha-spacing-large;
        color: #505870;
      }

      &--Close {
        display: none;
      }

      &--Footer {
        button {
          width: fit-content;
          color: var(--ha-color-iris);
          border-color: var(--ha-color-iris);
        }

        .HaButton-Outline.HaButton-Primary {
          background: transparent;
        }
      }
    }
  }

  .Title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin: $ha-spacing-small;
      color: var(--ha-color-iris);
      font-weight: $ha-weight-bold;
    }

    img {
      @media (min-width: 600px) {
        width: 150px;
      }
    }
  }

  ul {
    padding-left: $ha-spacing-big;
    list-style: disc;

    li {
      margin-bottom: $ha-spacing-medium;
      color: var(--ha-color-text-light);
    }

    + p {
      color: var(--ha-color-text);
    }
  }
}
</style>
