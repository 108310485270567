<template>
  <validation-observer v-slot="{ invalid }">
    <ha-modal class="ModalReport" v-on="$listeners" data-test="modal-report">
      <template #header>
        {{ $t('modal.report.title') }}
      </template>
      <template #body>
        <fieldset>
          <legend>
            {{ $t('modal.report.why') }}
          </legend>
          <validation-provider
            v-slot="{ valid, pristine }"
            :rules="'REQUIRED'"
            :name="'report'"
            slim
          >
            <ha-radio
              v-for="reason in reportReasons"
              :key="reason"
              v-model="option"
              :value="reason"
              name="report"
              :data-ux="`Forms_Modal_Report_Reason_${reason}`"
            >
              {{ $t(`modal.report.reasons.${reason}`) }}
            </ha-radio>
            <ha-error v-if="!pristine && !valid">
              {{ $t('modal.report.reasonRequired') }}
            </ha-error>
          </validation-provider>
        </fieldset>
        <div class="Field Field-TextInput">
          <ha-label for-id="email"> {{ $t('modal.report.emailInput.label') }} * </ha-label>
          <validation-provider
            v-slot="{ valid, pristine }"
            :rules="'REQUIRED|EMAIL'"
            :name="'email'"
            slim
          >
            <ha-input
              id="email"
              v-model="email"
              type="email"
              name="email"
              :is-valid="pristine ? null : valid"
              :placeholder="$t('modal.report.emailInput.placeholder')"
            />
            <ha-error v-if="!pristine && !valid">
              {{ $t('modal.report.emailInput.error') }}
            </ha-error>
          </validation-provider>
        </div>
        <div class="Field Field-TextInput">
          <ha-label for-id="message"> {{ $t('modal.report.messageInput.label') }} * </ha-label>
          <validation-provider
            v-slot="{ valid, pristine }"
            :rules="'REQUIRED'"
            :name="'message'"
            slim
          >
            <ha-text-area
              id="message"
              v-model="message"
              :is-valid="pristine ? null : valid"
              name="message"
              rows="5"
              :placeholder="$t('modal.report.messageInput.placeholder')"
            />
            <ha-error v-if="!pristine && !valid">
              {{ $t('modal.report.messageInput.error') }}
            </ha-error>
          </validation-provider>
        </div>
      </template>
      <template #footer>
        <ha-button
          color="basic"
          variant="flat"
          data-ux="Forms_Modal_Report_Cancel"
          @click="$emit('close')"
        >
          {{ $t('button.cancel') }}
        </ha-button>
        <ha-button
          :disabled="invalid || isLoading"
          :loading="isLoading"
          data-ux="Forms_Modal_Report_Send"
          @click="submitReport"
        >
          {{ $t('button.send') }}
        </ha-button>
      </template>
    </ha-modal>
  </validation-observer>
</template>

<script>
import { HaButton, HaTextArea, HaLabel, HaModal, HaRadio, HaInput, HaError } from '@ha/components'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

const reportReasons = {
  SPAM: 'SPAM',
  DISLIKE: 'DISLIKE',
  OFF_TOPIC: 'OFF_TOPIC',
  INTELLECTUAL_PROPERTY: 'INTELLECTUAL_PROPERTY'
}

export default {
  components: {
    HaRadio,
    HaButton,
    HaInput,
    HaTextArea,
    HaLabel,
    HaModal,
    HaError,
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      reportReasons,
      option: '',
      email: '',
      message: '',
      isLoading: false
    }
  },

  inject: ['$notifications'],
  async mounted() {
    await this.$recaptcha.init()
  },
  methods: {
    async submitReport() {
      const token = await this.$recaptcha.execute('forms_report_form')

      if (token) {
        this.isLoading = true

        const reportData = {
          reason: this.option,
          userEmail: this.email,
          userMessage: this.message,
          url: typeof window !== 'undefined' ? window.location.href : ''
        }

        this.$store
          .dispatch('forms/postReport', reportData)
          .then(() => {
            this.$notifications.push({
              type: 'success',
              title: this.$t('modal.report.successToast.title'),
              body: this.$t('modal.report.successToast.body'),
              timeout: 5000
            })
          })
          .catch(error => {
            this.$notifications.push(
              {
                type: 'danger',
                title: this.$t('modal.report.errorToast.title'),
                body: this.$t('modal.report.errorToast.title'),
                timeout: 5000
              },
              error
            )
          })
          .finally(() => {
            this.isLoading = false
            this.$emit('close')
          })
      }
    }
  }
}
</script>

<style lang="scss">
.ModalReport {
  fieldset {
    margin-bottom: $ha-spacing-medium;
  }

  legend {
    margin-bottom: $ha-spacing-medium;
  }

  .HaError {
    margin-top: $ha-spacing-mini;
  }
}
</style>
