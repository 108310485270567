<script setup>
import { ValidationProvider } from 'vee-validate'
import { HaInput, HaFormField, HaSwitch, HaTooltip } from '@ha/components'
import { useRoute } from '@nuxtjs/composition-api'
import useAbnormalEmailControl from '@/composables/useAbnormalEmailControl'
import { computed } from 'vue'

const props = defineProps({
  testId: { type: String, required: true }, // Use to find component in unit testing
  firstname: { type: String, required: true },
  lastname: { type: String, required: true },
  email: { type: String, default: null },
  applyInfoToAllParticipant: { type: Boolean, default: null },
  useTierInfoForPayment: { type: Boolean }
})

const emit = defineEmits([
  'update:firstname',
  'update:lastname',
  'update:email',
  'update',
  'update:apply-info-to-all-participant',
  'update:use-tier-info-for-payment'
])

const route = useRoute()
const { abnormalEmailWarning, hasAbnormalValues } = useAbnormalEmailControl(
  computed(() => props.email)
)

const onUpdateField = (key, event) => {
  emit(`update:${key}`, event)
  emit('update')
}
</script>

<template>
  <div>
    <validation-provider
      v-slot="{ errors, valid, validated }"
      name="firstname"
      :rules="// eslint-disable-next-line max-len
      `REQUIRED|NAME_CHARS|NAME_NO_NUMBERS|NAME_LATIN|NAME_CONSECUTIVE_CHARS|NAME_VOWELS|NAME_LENGTH|NAME_IS_FORBIDDEN|NAME_CONTAINS_FORBIDDEN|max_length:50`"
      slim
    >
      <HaFormField :is-valid="validated ? valid : null">
        <template #label> {{ $t('campaign.field.firstName') }} * </template>
        <HaInput
          :value="props.firstname"
          name="firstname"
          :max-length="50"
          :is-valid="validated ? valid : null"
          :errors="errors"
          data-test="input-firstname"
          @input="onUpdateField('firstname', $event)"
        />
        <template #error>
          {{ errors[0] }}
        </template>
      </HaFormField>
    </validation-provider>

    <validation-provider
      v-slot="{ errors, valid, validated }"
      :rules="// eslint-disable-next-line max-len
      `REQUIRED|NAME_CHARS|NAME_NO_NUMBERS|NAME_LATIN|NAME_CONSECUTIVE_CHARS|NAME_VOWELS|NAME_LENGTH|NAME_IS_FORBIDDEN|NAME_CONTAINS_FORBIDDEN|max_length:50`"
      name="lastname"
      slim
    >
      <HaFormField :is-valid="validated ? valid : null">
        <template #label> {{ $t('campaign.field.lastName') }} * </template>
        <HaInput
          :value="props.lastname"
          name="lastname"
          :max-length="50"
          :is-valid="validated ? valid : null"
          :errors="errors"
          data-test="input-lastname"
          @input="onUpdateField('lastname', $event)"
        />
        <template #error>
          {{ errors[0] }}
        </template>
      </HaFormField>
    </validation-provider>

    <HaSwitch
      v-if="props.applyInfoToAllParticipant !== null"
      :value="props.applyInfoToAllParticipant"
      data-testid="toggle-copy-info-members"
      @change="emit('update:apply-info-to-all-participant', $event)"
    >
      {{ $t(`forms.${route.params.formType}CopyParticipantInformations`) }}
    </HaSwitch>

    <HaSwitch
      v-if="props.useTierInfoForPayment !== null"
      :value="props.useTierInfoForPayment"
      data-testid="toggle-copy-info-payer"
      @change="emit('update:use-tier-info-for-payment', $event)"
    >
      {{ $t('campaign.field.payer') }}
    </HaSwitch>

    <validation-provider
      v-if="props.useTierInfoForPayment"
      v-slot="{ errors, valid, validated }"
      class="email-field"
      rules="EMAIL|REQUIRED"
      name="email"
      slim
    >
      <HaFormField :is-valid="validated ? valid : null">
        <template #label>
          <span>
            <HaTooltip position="right" class="tooltip">
              <template #trigger>
                <span class="tooltip__trigger">
                  {{ $t('campaign.field.email') }} *
                  <fa class="tooltip__trigger__icon" icon="info-circle" />
                </span>
              </template>
              <template #content>
                <div class="tooltip__content">
                  {{ $t('tooltip.emailConfirmation') }}
                </div>
              </template>
            </HaTooltip>
          </span>
        </template>

        <HaInput
          name="email"
          :value="props.email"
          :max-length="250"
          :is-valid="validated ? valid : null"
          :errors="errors"
          data-test="input-email"
          @input="emit('update:email', $event)"
        />

        <template #error>
          <template v-if="errors?.length"> {{ errors[0] }} </template>
          <template v-else-if="hasAbnormalValues">
            {{ abnormalEmailWarning }}
          </template>
        </template>
      </HaFormField>
    </validation-provider>
  </div>
</template>

<style lang="scss" scoped>
:deep(.HaFormField) {
  margin-bottom: $ha-spacing-medium;
}

.email-field {
  margin-top: $ha-spacing-medium;
}

.tooltip {
  &__trigger {
    &__icon {
      color: var(--ha-color-primary);
    }
  }

  &__content {
    width: $ha-unit * 20; // 160px
    font-weight: $ha-font-weight-regular;

    @include mediaQuery(600) {
      width: $ha-unit * 30; // 240px
    }
  }
}
</style>
