<script setup>
import { inject } from 'vue'
import LangSwitch from '@/components/forms/donations/partials/LangSwitch.vue'
import FormHeader from '@/components/forms/informations/FormHeader.vue'
import OrganizationLogo from '@/components/globals/logo/OrganizationLogo.vue'

const isWidget = inject('isWidget')
</script>

<template>
  <div class="donation-header">
    <LangSwitch
      class="donation-header__lang"
      :class="{ 'donation-header__lang--widget': isWidget }"
    />
    <div v-if="!isWidget" class="donation-header__title" data-testid="campaign-header">
      <OrganizationLogo />
      <FormHeader :step="1" />
    </div>
  </div>
</template>

<style lang="scss">
.donation-header {
  margin-top: $ha-spacing-medium;

  @include mediaQuery(900) {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-top: $ha-spacing-large;
  }

  .OrganizationLogo {
    margin: $ha-spacing-large auto $ha-spacing-medium auto;
    @include mediaQuery(900) {
      flex: 0 0 auto;
      margin: 0 $ha-spacing-medium 0 0;
    }
  }

  &__lang {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin: 0;

    @include mediaQuery(900) {
      flex: 0 0 auto;
      order: 3;
    }

    &--widget {
      justify-content: center;

      @include mediaQuery(900) {
        flex: 1 1 auto;
        justify-content: flex-end;
      }
    }
  }

  &__title {
    @include mediaQuery(900) {
      display: flex;
      flex: 1 1 auto;
      flex-flow: row nowrap;
      align-items: center;
      order: 2;
    }
  }

  .CampaignHeader {
    margin: 0;
    padding: 0;
    text-align: center;

    @include mediaQuery(900) {
      text-align: left;
    }

    &--Title {
      padding-right: 0;
    }
  }
}
</style>
