<script setup>
import { computed } from 'vue'
import { HaButton } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import useRouteParams from '@/composables/useRouteParams'
import useTiers from '@/components/tiers/useTiers'
import useTracking from '@/composables/useTracking'
import { isTierCustomAmount } from '@/components/tiers/helpers/index.ts'

const props = defineProps({
  tier: { type: Object, required: true },
  isFetching: Boolean
})

const emit = defineEmits(['update-selected-tiers'])

const { form } = useStoreData()
const { selectedTiers, updateSelectedTier, resetOtherSelectedTiers, isCollectAllowed } = useTiers()
const { formType } = useRouteParams()
const { trackingMethods } = useTracking()

const customAmount = computed(() => selectedTiers.value[props.tier.id]?.customAmount)
const isTierCustomAmountMinimalPriceRespected = computed(() => {
  if (!isTierCustomAmount(props.tier)) return true

  return customAmount.value >= props.tier.minAmount
})
const disabled = computed(() => !isCollectAllowed.value || props.isFetching)

const highlightSelectButton = computed(() =>
  isTierCustomAmount(props.tier)
    ? isTierCustomAmountMinimalPriceRespected.value
    : !!customAmount.value
)
const variantSelectButton = computed(() => (highlightSelectButton.value ? 'fill' : 'outline'))
const colorSelectButton = computed(() => (highlightSelectButton.value ? 'primary' : 'basic'))

const trackTierSelection = () => {
  const trackingData = {
    form_id: form.value.formId,
    form_slug: form.value.formSlug,
    form_type: form.value.formType,
    form_name: form.value.title,
    step: 0,
    step_name: 'Dons',
    organization_slug: form.value.organizationSlug
  }
  trackingMethods.onFirstClick('First Interaction Campaign', trackingData)
}

const onTierSelection = () => {
  resetOtherSelectedTiers(props.tier)
  const quantity = isTierCustomAmount(props.tier) && !customAmount.value ? 0 : 1
  updateSelectedTier(props.tier, { quantity })
  if (!isTierCustomAmount(props.tier) || isTierCustomAmountMinimalPriceRespected.value) {
    emit('update-selected-tiers')
    trackTierSelection()
  }
}
</script>

<template>
  <HaButton
    :variant="variantSelectButton"
    :color="colorSelectButton"
    :disabled="disabled"
    :loading="props.isFetching"
    :data-ux="`Forms_${formType.toUpperCase()}_ContributionSelector_Add`"
    @click="onTierSelection"
  >
    {{ $t('button.choose') }}
  </HaButton>
</template>
